import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {SettlementResultInterface} from '../interfaces/settlement-result.interface'
import {TotalSettlementResult} from '../interfaces/total_settlement_result.interface'
import {GeneralResponse} from '../../../../helpers/general-response'
import {StatementExportResult} from '../interfaces/settlement-export-result.interface'
import {TransactionStatementDetailShopeeResult} from '../interfaces/settlement-shopee-result.interface'

export interface GetAllSettlementParam {
  page?: number
  size?: number
  filters?: string
  sort?: string
  start_date?: number
  end_date?: number
  start_date_statement?: number
  end_date_statement?: number
  statement_id?: string
  type?: string
  order_id?: string
  status?: string
}

export const GetAllSettlementExport = async (
  params: GetAllSettlementParam
): Promise<StatementExportResult> => {
  try {
    const response = await Api.get('/settlement-exports', {params: params})
    return response.data as StatementExportResult
  } catch (e) {
    const a = e as AxiosError
    const response: StatementExportResult = a.response?.data
    return response
  }
}

export const GetAllSettlementExportShopee = async (
  params: GetAllSettlementParam
): Promise<StatementExportResult> => {
  try {
    const response = await Api.get('/settlement-exports-shopee', {params: params})
    return response.data as StatementExportResult
  } catch (e) {
    const a = e as AxiosError
    const response: StatementExportResult = a.response?.data
    return response
  }
}

export const GetAllSettlement = async (
  params: GetAllSettlementParam
): Promise<SettlementResultInterface> => {
  try {
    const response = await Api.get('/trx-statement-details', {params: params})
    return response.data as SettlementResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: SettlementResultInterface = a.response?.data
    return response
  }
}

export const GetAllSettlementShopee = async (
  params: GetAllSettlementParam
): Promise<TransactionStatementDetailShopeeResult> => {
  try {
    const response = await Api.get('/trx-statement-details-shopee', {params: params})
    return response.data as TransactionStatementDetailShopeeResult
  } catch (e) {
    const a = e as AxiosError
    const response: TransactionStatementDetailShopeeResult = a.response?.data
    return response
  }
}

export const GetTotalSettlement = async (
  params: GetAllSettlementParam
): Promise<TotalSettlementResult> => {
  try {
    const response = await Api.get('/trx-statement-details-total', {params: params})
    return response.data as TotalSettlementResult
  } catch (e) {
    const a = e as AxiosError
    const response: TotalSettlementResult = a.response?.data
    return response
  }
}

export const GetTotalSettlementShopee = async (
  params: GetAllSettlementParam
): Promise<TotalSettlementResult> => {
  try {
    const response = await Api.get('/trx-statement-details-total-shopee', {params: params})
    return response.data as TotalSettlementResult
  } catch (e) {
    const a = e as AxiosError
    const response: TotalSettlementResult = a.response?.data
    return response
  }
}

export const GetGenerateSettlementExport = async (
  params: GetAllSettlementParam
): Promise<GeneralResponse> => {
  try {
    const response = await Api.get('/trx-statement-details-export', {params: params})
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}

export const GetGenerateSettlementExportShopee = async (
  params: GetAllSettlementParam
): Promise<GeneralResponse> => {
  try {
    const response = await Api.get('/trx-statement-details-export-shopee', {params: params})
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}
