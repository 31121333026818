import {create} from 'zustand'
import {VendorsData, VendorsResultInterface} from '../interfaces/vendors-result.interface'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../helpers/form-action-interfaces'
import {
  DeleteVendor,
  GetAllVendors,
  GetAllVendorsParam,
  SubmitVendor,
  UpdateVendor,
} from '../providers/vendors-provider'
import {toast} from 'react-toastify'
import {generateFormLogic} from '../../../../helpers/generate-form-logic'

interface VendorState {
  vendorLoading: boolean
  vendors: VendorsData[]
  vendorSelectDatas: SelectData[]
  vendorModel: VendorsResultInterface
  getVendors: (params: GetAllVendorsParam) => void
  onDelete: (id: string, params: GetAllVendorsParam) => void
}

export const useVendorStore = create<VendorState>((set, get) => ({
  vendorLoading: false,
  vendorOneLoading: false,
  vendors: [],
  vendorSelectDatas: [],
  vendor: undefined,
  vendorModel: null,
  getVendors: async (params: GetAllVendorsParam) => {
    set({vendorLoading: true, vendors: []})
    const response = await GetAllVendors(params)
    set({vendorModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.vendor_name,
            value: item.id,
          })
        }
        set({vendors: response.data.items, vendorSelectDatas: transformDatas})
      }
    }
    set({vendorLoading: false})
  },
  onDelete: async (id: string, params: GetAllVendorsParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({vendorLoading: true})
      const response = await DeleteVendor(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getVendors(params)
      set({vendorLoading: false})
    }
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface VendorFormState {
  field?: VendorsData
  setField?: (item: VendorsData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const useVendorFormStore = create<VendorFormState>((set, get) => ({
  field: undefined,
  setField: (item: VendorsData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'vendor_name',
        title: 'Nama Vendor',
        placeholder: 'Nama Vendor...',
        type: 'text',
        name: 'vendor_name',
        value: get().field?.vendor_name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'vendor_address',
        title: 'Alamat Vendor',
        placeholder: 'Alamat Vendor...',
        type: 'text',
        name: 'vendor_address',
        value: get().field?.vendor_address ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'vendor_phone',
        title: 'Telepon Vendor',
        placeholder: 'Telepon Vendor...',
        type: 'text',
        name: 'vendor_phone',
        value: get().field?.vendor_phone ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'vendor_email',
        title: 'Email Vendor',
        placeholder: 'Email Vendor...',
        type: 'email',
        name: 'vendor_email',
        value: get().field?.vendor_email ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'vendor_country',
        title: 'Negara',
        placeholder: 'Negara...',
        type: 'select-with-text',
        name: 'vendor_country',
        value: {
          label: get().field?.vendor_country,
          value: get().field?.vendor_country,
        },
        selectData: [
          {value: 'Indonesia', label: 'Indonesia'},
          {value: 'China', label: 'China'},
        ],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }
    console.log(form)

    let res: VendorsResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitVendor(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateVendor(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
