import {useEffect, useState} from 'react'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import ReactPaginate from 'react-paginate'
import {KTCard, KTSVG} from '../../../../../_metronic/helpers'
import {FormAction} from '../../../../helpers/form-action-interfaces'
import {useVendorFormStore, useVendorStore} from '../states/vendors.state'
import VendorsModalComponent from '../components/vendors-modal.component'
import {VendorsData} from '../interfaces/vendors-result.interface'

const VendorsComponent = () => {
  const {getVendors, vendorLoading, vendorModel, vendors, onDelete} = useVendorStore()
  const {setField, field, generateForms, submitDone, formLoading, reset} = useVendorFormStore()
  const [currentPage, setCurrentPage] = useState<number>(0)

  useEffect(() => {
    getVendors({
      page: 0,
      size: 25,
    })
  }, [])

  useEffect(() => {
    if (submitDone === true && formLoading === false) {
      getVendors({page: 0, size: 25})
      reset()
    }
  }, [submitDone, formLoading])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    setCurrentPage(e.selected)
    getVendors({
      page: selectedPage,
      size: 25,
    })
  }

  const openEditModal = (item: VendorsData) => {
    setField(item)
    generateForms({
      title: 'Vendors',
      subtitle: `Update Vendors ${item.vendor_name}`,
      action: FormAction.UPDATE,
      data: item,
      id: item.id.toString(),
    })
  }

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Warehouse</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Vendor</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                setField(null)
                generateForms({
                  title: 'Add Vendor',
                  subtitle: 'Add Vendor',
                  action: FormAction.CREATE,
                })
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Add Vendor
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {vendorLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>No</th>
                  <th>Nama Vendor</th>
                  <th>Alamat Vendor</th>
                  <th>Email Vendor</th>
                  <th>Telepon Vendor</th>
                  <th>Negara Vendor</th>
                  <th className='text-end min-w-100px'>Action</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {vendors?.map((item, index) => {
                  const overallIndex = currentPage * 25 + index + 1
                  return (
                    <tr key={index}>
                      <td>{overallIndex}</td>
                      <td>{item.vendor_name}</td>
                      <td>{item.vendor_address}</td>
                      <td>{item.vendor_email}</td>
                      <td>{item.vendor_phone}</td>
                      <td>{item.vendor_country}</td>
                      <td className='text-end min-w-100px'>
                        <div className='d-flex justify-content-end'>
                          <button
                            className='btn btn-warning btn-sm me-2' // Warning color for Edit button
                            onClick={() => openEditModal(item)}
                          >
                            Edit
                          </button>
                          <button
                            className='btn btn-danger btn-sm' // Error color for Delete button
                            onClick={(e) => {
                              e.preventDefault()
                              onDelete(item.id, {
                                page: 0,
                                size: 25,
                              })
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={vendorModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
      {field !== undefined && <VendorsModalComponent />}
    </>
  )
}

const VendorsWrapper = () => {
  return (
    <>
      <VendorsComponent />
    </>
  )
}

export default VendorsWrapper
