import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import UserPage from '../modules/apps/users/screens/user.route'
import ProductPage from '../modules/apps/products/screens/product.route'
import OrderPage from '../modules/apps/orders/screens/order.route'
import StockTransactionPage from '../modules/apps/stock-transactions/screens/stock-transaction.route'
import RolePage from '../modules/apps/roles/screens/roles.route'
import ProfilePage from '../modules/profile/ProfilePage'
import WizardsPage from '../modules/wizards/WizardsPage'
import WidgetsPage from '../modules/widgets/WidgetsPage'
import AccountPage from '../modules/accounts/AccountPage'
import ChatPage from '../modules/apps/chat/ChatPage'
import ShippingDocPage from '../modules/apps/shipping-docs/screens/shipping-doc.route'
import TransactionPage from '../modules/apps/transactions/screens/transaction.route'
import SettlementPage from '../modules/apps/settlements/screens/settlement.route'
import ProductCodePage from '../modules/apps/products/screens/product-code.route'
import WarehousePage from '../modules/apps/warehouse/screens/warehouse.route'
import OrderShopeePage from '../modules/apps/orders/screens/order-shopee.route'
import ShippingDocShopeePage from '../modules/apps/shipping-docs/screens/shipping-doc-shopee.route'
import TransactionShopeePage from '../modules/apps/transactions/screens/transaction-shopee.route'
import SettlementShopeePage from '../modules/apps/settlements/screens/settlement-shopee.route'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/product-management/*'
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/order-management/*'
          element={
            <SuspensedView>
              <OrderPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/order-management-shopee/*'
          element={
            <SuspensedView>
              <OrderShopeePage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/stock-transactions-management/*'
          element={
            <SuspensedView>
              <StockTransactionPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/role-management/*'
          element={
            <SuspensedView>
              <RolePage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/shipping-docs-management/*'
          element={
            <SuspensedView>
              <ShippingDocPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/shipping-docs-management-shopee/*'
          element={
            <SuspensedView>
              <ShippingDocShopeePage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/transactions-management/*'
          element={
            <SuspensedView>
              <TransactionPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/transactions-management-shopee/*'
          element={
            <SuspensedView>
              <TransactionShopeePage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/settlements-management/*'
          element={
            <SuspensedView>
              <SettlementPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/settlements-management-shopee/*'
          element={
            <SuspensedView>
              <SettlementShopeePage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/product-code-management/*'
          element={
            <SuspensedView>
              <ProductCodePage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/warehouse-management/*'
          element={
            <SuspensedView>
              <WarehousePage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes, SuspensedView}
