import {useEffect, useState} from 'react'
import {useMasterStockStore} from '../states/master-stock.state'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import ReactPaginate from 'react-paginate'
import {KTCard} from '../../../../../_metronic/helpers'
import moment from 'moment'
import {useTrxCardboardsStore} from '../states/trx-cardboards.state'
import TrxCardboardDetailModalComponent from '../components/trx-cardboard-detail.modal.component'

const TrxCardboardsComponent = () => {
  const {getTrxCardboards, trxCardboards, trxCardboardsLoading, trxCardboardsModel} =
    useTrxCardboardsStore()

  useEffect(() => {
    getTrxCardboards({
      page: 0,
      size: 25,
    })
  }, [])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getTrxCardboards({
      page: selectedPage,
      size: 25,
    })
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [detailId, setDetailId] = useState<string>('')

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Warehouse</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Kardus Barang</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                getTrxCardboards({page: 0, size: 25})
              }}
            >
              Refresh
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {trxCardboardsLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Kode Produk</th>
                  <th>Kode Kardus</th>
                  <th>Quantity</th>
                  <th>Tanggal</th>
                  <th>Detail</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {trxCardboards?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.product_code_id}</td>
                      <td>{item.cardboard_code}</td>
                      <td>{item.volume}</td>
                      <td>{moment(item.action_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                      <td>
                        <button
                          className='btn btn-success'
                          onClick={(e) => {
                            e.preventDefault()
                            openModal()
                            setDetailId(item.id)
                          }}
                        >
                          Detail
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={trxCardboardsModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
      <TrxCardboardDetailModalComponent isOpen={isModalOpen} onClose={closeModal} id={detailId} />
    </>
  )
}

const TrxCardboardsWrapper = () => {
  return (
    <>
      <TrxCardboardsComponent />
    </>
  )
}

export default TrxCardboardsWrapper
