import {toAbsoluteUrl} from '../../_metronic/helpers'

export const generateImageUrl = (imageId: string) => {
  return process.env.REACT_APP_ASSETS_URL + '/' + imageId + '?w=1024&fit=crop&auto=format,compress'
}

export const generateThumbnailUrl = (imagePath: string) => {
  return process.env.REACT_APP_ASSETS_STORAGE_URL + '/' + imagePath
}

export const generateCompanyLogoUrl = (imageId: string) => {
  if (imageId === '' && imageId === null && imageId === undefined) {
    return toAbsoluteUrl('/media/logos/logo-universa-white.png')
  }
  return process.env.REACT_APP_ASSETS_URL + '/' + imageId
}
