import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {TrxStockReportResultInterface} from '../interfaces/trx-stock-report-result.interface'
import {GeneralResponse} from '../../../../helpers/general-response'

export interface GetAllTrxStockReportsParam {
  page?: number
  size?: number
  start_time?: number
  end_time?: number
  trx_stock_type?: string
  filters?: string
  sort?: string
}

export const GetAllTrxStockReports = async (
  params: GetAllTrxStockReportsParam
): Promise<TrxStockReportResultInterface> => {
  try {
    const response = await Api.get('/trx-stock-reports', {params: params})

    return response.data as TrxStockReportResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TrxStockReportResultInterface = a.response.data
    return response
  }
}

export const GenerateTrxStockReportExcel = async (
  params: GetAllTrxStockReportsParam
): Promise<GeneralResponse> => {
  try {
    const response = await Api.get('/trx-stocks-generate-excel', {params: params})

    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response.data
    return response
  }
}

export const GenerateTrxStockReportPdf = async (
  params: GetAllTrxStockReportsParam
): Promise<GeneralResponse> => {
  try {
    const response = await Api.get('/trx-stocks-generate-pdf', {params: params})

    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response.data
    return response
  }
}

export const DeleteTrxStockReport = async (id: string): Promise<GeneralResponse> => {
  try {
    const response = await Api.delete('/trx-stock-reports/' + id)
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}
