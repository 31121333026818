import {useEffect, useRef} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import FormsComponent from '../../../../helpers/forms.component'
import {useTrxStocksInStore} from '../states/trx-stocks.state'
import {useProductStore} from '../../products/states/product.state'
import {useAuth} from '../../../auth'
import moment from 'moment'
import {useUserStore} from '../../users/states/user.state'
import {useVendorStore} from '../states/vendors.state'
import {FormAction} from '../../../../helpers/form-action-interfaces'

const TrxStocksInModalComponent = () => {
  const {getProductCodes, productCodeSelectDatas} = useProductStore()
  const {getUsers, userSelectDatas} = useUserStore()
  const {getVendors, vendorSelectDatas} = useVendorStore()
  const {setFormSelectData} = useTrxStocksInStore()

  useEffect(() => {
    document.body.classList.add('modal-open')
    getProductCodes({
      page: 0,
      size: 1000,
      is_stock: true,
    })
    getVendors({
      page: 0,
      size: 100,
    })
    getUsers({
      page: 0,
      size: 100,
      filters: `["role_id", "in", ["1d67e018-c99b-4d2f-a1f2-bf009ad73ae7","40bcc02e-e1cd-43c0-8835-dbc58956107d"]]`,
    })
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    if (vendorSelectDatas?.length > 0) {
      setFormSelectData(0, vendorSelectDatas)
    }
  }, [vendorSelectDatas])

  useEffect(() => {
    if (productCodeSelectDatas?.length > 0) {
      setFormSelectData(1, productCodeSelectDatas)
    }
  }, [productCodeSelectDatas])

  useEffect(() => {
    if (userSelectDatas?.length > 0) {
      setFormSelectData(2, userSelectDatas)
    }
  }, [userSelectDatas])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_properties'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <TrxStockInModalHeader />

            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <TrxStockInModalFormWrapper />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

const TrxStockInModalHeader = () => {
  const {setField, formParam} = useTrxStocksInStore()

  return (
    <div className='modal-header'>
      <h2 className='fw-bold'>{formParam?.title}</h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setField(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

const TrxStockInModalFormWrapper = () => {
  const {
    forms,
    setForms,
    setField,
    onSubmit,
    setProductForms,
    productForms,
    initProductForms,
    addProductForms,
    setUpdateProductForms,
    changeRequired,
    reset,
    formParam,
  } = useTrxStocksInStore()
  const {getProductCodes, productCodeSelectDatas, getFreeProductCodes} = useProductStore()

  useEffect(() => {
    getProductCodes({
      page: 0,
      size: 1000,
      is_stock: true,
    })
    getFreeProductCodes({
      page: 0,
      size: 1000,
      is_stock: true,
    })
  }, [])

  useEffect(() => {
    if (productCodeSelectDatas?.length > 0) {
      if (formParam.action === FormAction.CREATE) {
        initProductForms(productCodeSelectDatas)
      }
    }
  }, [productCodeSelectDatas])

  useEffect(() => {
    // console.log(forms)
    if (forms[3].value === 'Tiktok' || forms[3].value === 'Shopee') {
      changeRequired(4, true)
    } else {
      changeRequired(4, false)
    }
  }, [forms[3].value])

  useEffect(() => {
    setForms(1, moment().format('YYYY-MM-DD HH:mm:00'))
  }, [])

  return (
    <>
      <form>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <FormsComponent
              forms={[forms[0]]}
              setForms={setForms}
              uploadFileCb={(e) => console.log(e)}
              selectWithTextCb={(index, name, value) => {
                if (value !== undefined && value !== null) {
                  if (index === 0 && name === 'product_bundle') {
                    setProductForms(value, productCodeSelectDatas)
                  }
                } else {
                  console.error('Value is null or undefined:', value)
                  setProductForms(value, productCodeSelectDatas)
                  initProductForms(productCodeSelectDatas)
                }
              }}
            />
            {productForms?.map((item, index) => {
              return (
                <FormsComponent
                  key={index}
                  forms={item}
                  setForms={(formIndex, value) => {
                    setUpdateProductForms(index, formIndex, value)
                  }}
                  uploadFileCb={(e) => console.log(e)}
                />
              )
            })}
            <div className='text-center pt--15'>
              <button
                type='button'
                onClick={() => {
                  addProductForms(productCodeSelectDatas)
                }}
                className='btn btn-success me-3'
              >
                Add Product
              </button>
            </div>
            <FormsComponent
              forms={[forms[1]]}
              setForms={(index, value) => setForms(1, value)}
              uploadFileCb={(e) => console.log(e)}
            />
            <FormsComponent
              forms={[forms[2]]}
              setForms={(index, value) => setForms(2, value)}
              uploadFileCb={(e) => console.log(e)}
            />
            <FormsComponent
              forms={[forms[3]]}
              setForms={(index, value) => setForms(3, value)}
              uploadFileCb={(e) => console.log(e)}
            />
          </div>
        </div>

        <div className='text-center pt--15'>
          <button
            type='reset'
            onClick={() => {
              reset()
              setField(undefined)
            }}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            onClick={(e) => {
              e.preventDefault()
              onSubmit()
            }}
          >
            <span className='indicator-label'>Submit</span>
          </button>
        </div>
      </form>
    </>
  )
}

export default TrxStocksInModalComponent
