import {create} from 'zustand'
import {
  DashboardStockTransactionResult,
  StockTransactionData,
} from '../interfaces/dashboard-stock-transactions-result.interface'
import {
  DashboardStockTransaction,
  StockTransactionParam,
} from '../providers/stock-transaction.provider'

interface StockTransactionState {
  stockTransactionSummaryLoading: boolean
  stockTransactionSummaryModel: DashboardStockTransactionResult
  getStockTransactionsSummary: (params: StockTransactionParam) => void
}

export const useStockTransactionStore = create<StockTransactionState>((set, get) => ({
  stockTransactionSummaryLoading: false,
  stockTransactionSummaryModel: null,
  getStockTransactionsSummary: async (params: StockTransactionParam) => {
    set({
      stockTransactionSummaryLoading: true,
      stockTransactionSummaryModel: null,
    })
    const response = await DashboardStockTransaction(params)
    set({
      stockTransactionSummaryModel: response,
      stockTransactionSummaryLoading: false,
    })
  },
}))
