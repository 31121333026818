const generateCurrencyFormat = (value: number): string => {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  })
  return formatter.format(value)
}

const generateNumberFormat = (value: number): string => {
  const formatter = new Intl.NumberFormat('id-ID', {
    minimumFractionDigits: 0,
  })
  return formatter.format(value)
}

export {generateCurrencyFormat, generateNumberFormat}
