import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {MasterStockResultInterface} from '../interfaces/master-stock-result.interface'
import {MasterStockSingleResultInterface} from '../interfaces/master-stock-single-result.interface'
import {GeneralResponse} from '../../../../helpers/general-response'

export interface GetAllMasterStockParam {
  page?: number
  size?: number
  sort?: string
  time_query?: number
}

export const GetAllMasterStock = async (
  params: GetAllMasterStockParam
): Promise<MasterStockResultInterface> => {
  try {
    const response = await Api.get('/master-stocks', {params: params})

    return response.data as MasterStockResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: MasterStockResultInterface = a.response.data
    return response
  }
}

export const GetMasterStock = async (id: string): Promise<MasterStockSingleResultInterface> => {
  try {
    const response = await Api.get('/master-stocks/' + id)
    return response.data as MasterStockSingleResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: MasterStockSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitMasterStock = async (form: any): Promise<MasterStockResultInterface> => {
  try {
    const response = await Api.post('/master-stocks', form)
    return response.data as MasterStockResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: MasterStockResultInterface = a.response?.data
    return response
  }
}

export const UpdateMasterStock = async (
  id: string,
  form: any
): Promise<MasterStockResultInterface> => {
  try {
    const response = await Api.put('/master-stocks/' + id, form)
    return response.data as MasterStockResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: MasterStockResultInterface = a.response?.data
    return response
  }
}

export const DeleteMasterStock = async (id: string): Promise<GeneralResponse> => {
  try {
    const response = await Api.delete('/master-stocks/' + id)
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}
