import {create} from 'zustand'
import {
  ShippingDocItem,
  ShippingDocResultInterface,
} from '../interfaces/shipping-doc-result.interface'
import {
  CountAllShippingDoc,
  CountAllShippingDocShopee,
  GetAllQty,
  GetAllQtyShopee,
  GetAllShippingDoc,
  GetAllShippingDocExport,
  GetAllShippingDocParam,
  GetAllShippingDocShopee,
  GetAllShippingDocShopeeExport,
  GetAllSku,
  GetAllSkuFree,
  GetAllSkuFreeShopee,
  GetAllSkuShopee,
  GetGenerateResi,
  GetGenerateResiShopee,
  ShippingDocChangeStatus,
  ShippingDocShopeeChangeStatus,
} from '../providers/shipping-doc.provider'
import {
  ShippingDocSkuData,
  ShippingDocSkuFreeData,
} from '../interfaces/shipping-doc-sku-result.interface'
import {GeneralResponse} from '../../../../helpers/general-response'
import {toast} from 'react-toastify'
import {
  ShippingDocExportItem,
  ShippingDocExportResultInterface,
} from '../interfaces/shipping-doc-export-result.interface'
import {ShippingDocQtyData} from '../interfaces/shipping-doc-qty-result.interface'

interface ShippingDocShopeeState {
  shippingDocsItems: ShippingDocItem[]
  shippingDocsModel: ShippingDocResultInterface
  getShippingDocs: (params: GetAllShippingDocParam) => void
  shippingDocLoading: boolean

  countShippingDocs: (params: GetAllShippingDocParam) => void
  loadingCountShippingDoc: boolean
  totalShippingDocs: number

  getShippingDocSkus: (params: GetAllShippingDocParam) => void
  shippingDocSkuLoading: boolean
  shippingDocSkusItems: ShippingDocSkuData[]

  getShippingDocQty: (params: GetAllShippingDocParam) => void
  shippingDocQtyLoading: boolean
  shippingDocQtyItems: ShippingDocQtyData[]

  getShippingDocFree: (parans: GetAllShippingDocParam) => void
  shippingDocFreeLoading: boolean
  shippingDocFreeItems: ShippingDocSkuFreeData[]

  generateResiResult: GeneralResponse
  getGenerateResi: (params: GetAllShippingDocParam) => void
  generateResiLoading: boolean

  shippingDocExportsItems: ShippingDocExportItem[]
  shippingDocExportModel: ShippingDocExportResultInterface
  shippingDocExportLoading: boolean
  getShippingDocExport: (params: GetAllShippingDocParam) => void

  shippingDocDownload: (id: string) => void
}

export const useShippingDocShopeeStore = create<ShippingDocShopeeState>((set, get) => ({
  shippingDocLoading: false,
  shippingDocsItems: [],
  shippingDocsModel: null,

  countShippingDocs: async (params: GetAllShippingDocParam) => {
    set({
      loadingCountShippingDoc: true,
      totalShippingDocs: 0,
    })

    const response = await CountAllShippingDocShopee(params)

    if (response.status === true) {
      set({totalShippingDocs: response.data})
    }

    set({
      loadingCountShippingDoc: false,
    })
  },
  totalShippingDocs: 0,
  loadingCountShippingDoc: false,

  getShippingDocs: async (params: GetAllShippingDocParam) => {
    set({
      shippingDocLoading: true,
      shippingDocsModel: null,
      shippingDocsItems: [],
    })

    const response = await GetAllShippingDocShopee(params)
    set({shippingDocsModel: response})

    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        let shippingDocs: ShippingDocItem[] = []
        for (const data of response.data?.items) {
          // check is exist
          const existIndex = shippingDocs.findIndex((el) => el.order_id === data.order_id)
          if (existIndex === -1) {
            shippingDocs.push(data)
          }
        }

        set({shippingDocsItems: shippingDocs})
      }
    }

    set({
      shippingDocLoading: false,
    })
  },
  shippingDocSkuLoading: false,
  shippingDocSkusItems: [],
  getShippingDocSkus: async (params: GetAllShippingDocParam) => {
    set({
      shippingDocSkuLoading: true,
      shippingDocSkusItems: [],
    })

    const response = await GetAllSkuShopee(params)

    if (response.status === true) {
      if (response.data?.length > 0) {
        set({shippingDocSkusItems: response.data})
      }
    }

    set({
      shippingDocSkuLoading: false,
    })
  },

  generateResiLoading: false,
  generateResiResult: undefined,
  getGenerateResi: async (params: GetAllShippingDocParam) => {
    set({
      generateResiLoading: true,
      generateResiResult: undefined,
    })

    const response = await GetGenerateResiShopee(params)
    set({generateResiResult: response})

    toast.success(response.message ?? 'Success')

    set({
      generateResiLoading: false,
    })
  },

  shippingDocExportsItems: [],
  shippingDocExportModel: undefined,
  shippingDocExportLoading: false,
  getShippingDocExport: async (params: GetAllShippingDocParam) => {
    set({
      shippingDocExportLoading: true,
      shippingDocExportModel: null,
      shippingDocExportsItems: [],
    })

    const response = await GetAllShippingDocShopeeExport(params)
    set({shippingDocExportModel: response})

    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        set({shippingDocExportsItems: response.data?.items})
      }
    }

    set({
      shippingDocExportLoading: false,
    })
  },

  shippingDocQtyLoading: false,
  shippingDocQtyItems: [],
  getShippingDocQty: async (params: GetAllShippingDocParam) => {
    set({
      shippingDocQtyLoading: true,
      shippingDocQtyItems: [],
    })

    const response = await GetAllQtyShopee(params)

    if (response.status === true) {
      if (response.data?.length > 0) {
        set({shippingDocQtyItems: response.data})
      }
    }

    set({
      shippingDocQtyLoading: false,
    })
  },

  shippingDocFreeLoading: false,
  shippingDocFreeItems: [],
  getShippingDocFree: async (params: GetAllShippingDocParam) => {
    set({
      shippingDocFreeLoading: true,
      shippingDocFreeItems: [],
    })

    const response = await GetAllSkuFreeShopee(params)

    if (response.status === true) {
      if (response.data?.length > 0) {
        set({shippingDocFreeItems: response.data})
      }
    }

    set({
      shippingDocFreeLoading: false,
    })
  },
  shippingDocDownload: async (id: string) => {
    const response = await ShippingDocShopeeChangeStatus(id)
    if (response) {
      if (response?.status === true) {
        get().getShippingDocExport({
          page: 0,
          sort: '-created_at',
        })
      }
    }
  },
}))
