import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {GeneralResponse} from '../../../../helpers/general-response'
import {MasterStockReportResultInterface} from '../interfaces/master-stock-report-result.interface'

export interface GetAllMasterStockReportsParam {
  page?: number
  size?: number
  time_query?: number
  start_time?: number
  end_time?: number
  filters?: string
  sort?: string
}

export const GetAllMasterStockReports = async (
  params: GetAllMasterStockReportsParam
): Promise<MasterStockReportResultInterface> => {
  try {
    const response = await Api.get('/master-stock-reports', {params: params})

    return response.data as MasterStockReportResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: MasterStockReportResultInterface = a.response.data
    return response
  }
}

export const GenerateMasterStockReportExcel = async (
  params: GetAllMasterStockReportsParam
): Promise<GeneralResponse> => {
  try {
    const response = await Api.get('/master-stocks-generate-excel', {params: params})

    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response.data
    return response
  }
}

export const GenerateMasterStockReportPdf = async (
  params: GetAllMasterStockReportsParam
): Promise<GeneralResponse> => {
  try {
    const response = await Api.get('/master-stocks-generate-pdf', {params: params})

    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response.data
    return response
  }
}

export const DeleteMasterStockReport = async (id: string): Promise<GeneralResponse> => {
  try {
    const response = await Api.delete('/master-stock-reports/' + id)
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}
