import {create} from 'zustand'
import {TrxResiItem, TrxResiResultInterface} from '../interfaces/trx-resi-result.interface'
import {
  DeleteTrxResi,
  GetAllTrxResi,
  GetAllTrxResiParam,
  SubmitTrxResi,
  UpdateTrxResi,
} from '../providers/trx-resi.provider'
import {toast} from 'react-toastify'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../helpers/form-action-interfaces'
import {generateFormLogic} from '../../../../helpers/generate-form-logic'

interface TrxResiState {
  trxResisLoading: boolean
  trxResis: TrxResiItem[]
  trxResisModel: TrxResiResultInterface
  getTrxResi: (params: GetAllTrxResiParam) => Promise<void>
  onDeleteTrxResi: (id: string, params: GetAllTrxResiParam) => Promise<void>
}

export const useTrxResiStore = create<TrxResiState>((set, get) => ({
  trxResisLoading: false,
  trxResis: [],
  trxResisModel: undefined,
  getTrxResi: async (params: GetAllTrxResiParam) => {
    set({trxResisLoading: true, trxResis: []})
    const response = await GetAllTrxResi(params)
    set({trxResisModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        set({trxResis: response.data.items})
      }
    }
    set({trxResisLoading: false})
  },
  onDeleteTrxResi: async (id: string, params: GetAllTrxResiParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({trxResisLoading: true})
      const response = await DeleteTrxResi(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getTrxResi(params)
      set({trxResisLoading: false})
    }
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface TrxResiFormState {
  field?: TrxResiItem
  setField?: (item: TrxResiItem) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const useTrxResiFormStore = create<TrxResiFormState>((set, get) => ({
  field: undefined,
  setField: (item: TrxResiItem) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'total_resi',
        title: 'Total Resi',
        placeholder: 'Total Resi...',
        type: 'number',
        name: 'total_resi',
        value: get().field?.total_resi ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'acknowledge_by_id',
        title: 'Penerima',
        placeholder: 'Penerima...',
        type: 'select-with-text',
        name: 'acknowledge_by_id',
        value: {
          label: get().field?.acknowledge_by?.full_name ?? 'Penerima...',
          value: get().field?.acknowledge_by_id ?? '',
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'action_date',
        title: 'Tanggal',
        placeholder: 'Tanggal...',
        type: 'datetime',
        name: 'action_date',
        value: get().field?.action_date ?? new Date(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'notes',
        title: 'Notes',
        placeholder: 'Notes...',
        type: 'text',
        name: 'notes',
        value: get().field?.notes ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'marketplace',
        title: 'Marketplace',
        placeholder: 'Marketplace...',
        type: 'select-with-text',
        name: 'marketplace',
        value: {
          label: get().field?.marketplace ?? '',
          value: get().field?.marketplace ?? '',
        },
        selectData: [
          {
            label: 'tiktok',
            value: 'tiktok',
          },
          {
            label: 'shopee',
            value: 'shopee',
          },
        ],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: TrxResiResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitTrxResi(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateTrxResi(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
