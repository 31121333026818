import {useEffect} from 'react'
import {useProductStore} from '../states/product.state'
import {KTCard} from '../../../../../_metronic/helpers'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import {generateCurrencyFormat} from '../../../../helpers/generate-currency-format'
import moment from 'moment'
import ReactPaginate from 'react-paginate'

const ProductListComponent = () => {
  const {getProducts, products, productLoading, productModel, refreshProducts} = useProductStore()

  useEffect(() => {
    getProducts({
      page: 0,
      size: 25,
    })
  }, [])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getProducts({
      page: selectedPage,
      size: 25,
    })
  }

  return (
    <>
      {/* <div className='py-5'>
        <div className='rounded border p-5' style={{backgroundColor: 'white'}}></div>
      </div> */}
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Products</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Product List</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                refreshProducts({page: 0, size: 25})
              }}
            >
              Refresh
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {productLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Product Name</th>
                  <th>Price</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {products?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>
                        {item.skus?.length > 0
                          ? `${generateCurrencyFormat(+item.skus[0]?.price?.original_price ?? 0)}`
                          : 0}
                      </td>
                      <td>{moment(item.create_time * 1000).format('YYYY-MM-DD HH:mm:ss')}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={productModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
    </>
  )
}

const ProductListWrapper = () => {
  return (
    <>
      <ProductListComponent />
    </>
  )
}

export default ProductListWrapper
