import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {
  ShippingDocResultCountInterface,
  ShippingDocResultInterface,
} from '../interfaces/shipping-doc-result.interface'
import {
  ShippingDocSkuFreeResultInterface,
  ShippingDocSkuResultInterface,
} from '../interfaces/shipping-doc-sku-result.interface'
import {GeneralResponse} from '../../../../helpers/general-response'
import {ShippingDocExportResultInterface} from '../interfaces/shipping-doc-export-result.interface'
import {
  ShippingDocQtyData,
  ShippingDocQtyResultInterface,
} from '../interfaces/shipping-doc-qty-result.interface'

export interface GetAllShippingDocParam {
  page?: number
  size?: number
  filters?: string
  sort?: string
  start_time?: number
  end_time?: number
  sku_name?: string
  qty?: number
  shipping_provider_id?: string
  shipping_provider?: string
  sku_type?: string
  status_label?: string
  search?: string
  order_type?: string
  free_item?: string
}

export const GetAllShippingDoc = async (
  params: GetAllShippingDocParam
): Promise<ShippingDocResultInterface> => {
  try {
    const response = await Api.get('/shipping-docs', {params: params})
    return response.data as ShippingDocResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocResultInterface = a.response?.data
    return response
  }
}

export const GetAllShippingDocRetry = async (
  params: GetAllShippingDocParam
): Promise<ShippingDocResultInterface> => {
  try {
    const response = await Api.get('/shipping-docs-retry', {params: params})
    return response.data as ShippingDocResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocResultInterface = a.response?.data
    return response
  }
}

export const GetAllShippingDocShopee = async (
  params: GetAllShippingDocParam
): Promise<ShippingDocResultInterface> => {
  try {
    const response = await Api.get('/shipping-docs-shopee', {params: params})
    return response.data as ShippingDocResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocResultInterface = a.response?.data
    return response
  }
}

export const CountAllShippingDoc = async (
  params: GetAllShippingDocParam
): Promise<ShippingDocResultCountInterface> => {
  try {
    const response = await Api.get('/shipping-docs-count', {params: params})
    return response.data as ShippingDocResultCountInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocResultCountInterface = a.response?.data
    return response
  }
}

export const CountAllShippingDocShopee = async (
  params: GetAllShippingDocParam
): Promise<ShippingDocResultCountInterface> => {
  try {
    const response = await Api.get('/shipping-docs-count-shopee', {params: params})
    return response.data as ShippingDocResultCountInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocResultCountInterface = a.response?.data
    return response
  }
}

export const GetAllShippingDocExport = async (
  params: GetAllShippingDocParam
): Promise<ShippingDocExportResultInterface> => {
  try {
    const response = await Api.get('/shipping-doc-exports', {params: params})
    return response.data as ShippingDocExportResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocExportResultInterface = a.response?.data
    return response
  }
}

export const GetAllShippingDocShopeeExport = async (
  params: GetAllShippingDocParam
): Promise<ShippingDocExportResultInterface> => {
  try {
    const response = await Api.get('/shipping-doc-shopee-exports', {params: params})
    return response.data as ShippingDocExportResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocExportResultInterface = a.response?.data
    return response
  }
}

export const ShippingDocChangeStatus = async (id: string): Promise<GeneralResponse> => {
  try {
    const response = await Api.get(`/shipping-doc-exports-change-status-download/${id}`)
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}

export const ShippingDocShopeeChangeStatus = async (id: string): Promise<GeneralResponse> => {
  try {
    const response = await Api.get(`/shipping-doc-shopee-exports-change-status-download/${id}`)
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}

export const GetAllSku = async (
  params: GetAllShippingDocParam
): Promise<ShippingDocSkuResultInterface> => {
  try {
    const response = await Api.get('/shipping-docs-sku', {params: params})
    return response.data as ShippingDocSkuResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocSkuResultInterface = a.response?.data
    return response
  }
}

export const GetAllSkuShopee = async (
  params: GetAllShippingDocParam
): Promise<ShippingDocSkuResultInterface> => {
  try {
    const response = await Api.get('/shipping-docs-sku-shopee', {params: params})
    return response.data as ShippingDocSkuResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocSkuResultInterface = a.response?.data
    return response
  }
}

export const GetAllSkuFree = async (
  params: GetAllShippingDocParam
): Promise<ShippingDocSkuFreeResultInterface> => {
  try {
    const response = await Api.get('/shipping-docs-free', {params: params})
    return response.data as ShippingDocSkuFreeResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocSkuFreeResultInterface = a.response?.data
    return response
  }
}

export const GetAllSkuFreeShopee = async (
  params: GetAllShippingDocParam
): Promise<ShippingDocSkuFreeResultInterface> => {
  try {
    const response = await Api.get('/shipping-docs-free-shopee', {params: params})
    return response.data as ShippingDocSkuFreeResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocSkuFreeResultInterface = a.response?.data
    return response
  }
}

export const GetAllQty = async (
  params: GetAllShippingDocParam
): Promise<ShippingDocQtyResultInterface> => {
  try {
    const response = await Api.get('/shipping-docs-qty', {params: params})
    return response.data as ShippingDocQtyResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocQtyResultInterface = a.response?.data
    return response
  }
}

export const GetAllQtyShopee = async (
  params: GetAllShippingDocParam
): Promise<ShippingDocQtyResultInterface> => {
  try {
    const response = await Api.get('/shipping-docs-qty-shopee', {params: params})
    return response.data as ShippingDocQtyResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocQtyResultInterface = a.response?.data
    return response
  }
}

export const GetGenerateResi = async (params: GetAllShippingDocParam): Promise<GeneralResponse> => {
  try {
    const response = await Api.get('/shipping-docs-generate', {params: params})
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}

export const GetGenerateResiShopee = async (
  params: GetAllShippingDocParam
): Promise<GeneralResponse> => {
  try {
    const response = await Api.get('/shipping-docs-generate-shopee', {params: params})
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}
