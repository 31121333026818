import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {SuspensedView} from '../../../../routing/PrivateRoutes'
import TransactionScreenWrapper from './transaction.screen'
import TransactionDetailComponent from './transaction-detail.screen'
import TransactionShopeeScreenWrapper from './transaction-shopee.screen'

const transactionBreadcrumbs: Array<PageLink> = [
  {
    title: 'Penarikan Dana',
    path: '/apps/transactions-management/transaction',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const transactionDetailBreadcrumbs: Array<PageLink> = [
  {
    title: 'Penarikan Dana',
    path: '/apps/transactions-management/transaction',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Settlement',
    path: '/apps/transactions-management/transaction/:id',
    isSeparator: false,
    isActive: false,
  },
]

const TransactionShopeePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='transaction-shopee'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={transactionBreadcrumbs}>Penarikan Dana</PageTitle>
              <TransactionShopeeScreenWrapper />
            </SuspensedView>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/transactions-management-shopee/transaction' />} />
    </Routes>
  )
}

export default TransactionShopeePage
