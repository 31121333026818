import {create} from 'zustand'
import {TotalSalesResultInterface} from '../interfaces/total-sales-yesterday-result.interface'
import {GetTotalSalesToday, GetTotalSalesYesterday} from '../providers/dashboard.provider'

interface DashboardState {
  totalSalesYesterday: TotalSalesResultInterface
  totalSalesToday: TotalSalesResultInterface
  getTotalSalesYesterday: () => void
  getTotalSalesToday: () => void
  loadingTotalSalesYesterday: boolean
  loadingTotalSalesToday: boolean
}

export const useDashboardStore = create<DashboardState>((set, get) => ({
  totalSalesYesterday: null,
  totalSalesToday: null,
  loadingTotalSalesToday: false,
  loadingTotalSalesYesterday: false,
  getTotalSalesToday: async () => {
    set({totalSalesToday: null, loadingTotalSalesToday: true})
    const response = await GetTotalSalesToday()
    set({totalSalesToday: response, loadingTotalSalesToday: false})
  },
  getTotalSalesYesterday: async () => {
    set({totalSalesYesterday: null, loadingTotalSalesYesterday: true})
    const response = await GetTotalSalesYesterday()
    set({totalSalesYesterday: response, loadingTotalSalesYesterday: false})
  },
}))
