import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {lazy} from 'react'
import {SuspensedView} from '../../../../routing/PrivateRoutes'
import SettlementListWrapper from './settlement.screen'
import SettlementExportScreenWrapper from './settlement-export.screen'
import SettlementShopeeListWrapper from './settlement-shopee.screen'
import SettlementExportShopeeScreenWrapper from './settlement-export-shopee.screen'

const settlementBreadcrumbs: Array<PageLink> = [
  {
    title: 'Settlement',
    path: '/apps/settlements-management/settlement',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SettlementPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='settlement'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={settlementBreadcrumbs}>Settlements list</PageTitle>
              <SettlementListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='settlement-shopee'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={settlementBreadcrumbs}>Settlements list</PageTitle>
              <SettlementShopeeListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='settlement-export'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={settlementBreadcrumbs}>Settlements export</PageTitle>
              <SettlementExportScreenWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='settlement-shopee-export'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={settlementBreadcrumbs}>Settlements export</PageTitle>
              <SettlementExportShopeeScreenWrapper />
            </SuspensedView>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/settlement-management/settlements' />} />
    </Routes>
  )
}

export default SettlementPage
