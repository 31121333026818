/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, loginOtp} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const loginOtpSchema = Yup.object().shape({
  otp: Yup.string()
    .min(3, 'Minimum 6 symbols')
    .max(50, 'Maximum 6 symbols')
    .required('OTP is required'),
})

const initialValues = {
  email: '',
  password: '',
}

const initialValuesOtp = {
  otp: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [isOtpSend, setIsOtpSend] = useState<boolean>(false)
  const [message, setMessage] = useState({
    message: '',
    status: false,
  })

  const formikLogin = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        setLoading(false)
        setMessage({
          message: auth.message ?? '',
          status: true,
        })
        setIsOtpSend(true)
      } catch (error) {
        setMessage({
          message: error?.response?.data?.message ?? 'The login details are incorrect',
          status: false,
        })
        setSubmitting(false)
        setLoading(false)
        setIsOtpSend(false)
      }
    },
  })

  const formikOtp = useFormik({
    initialValues: initialValuesOtp,
    validationSchema: loginOtpSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await loginOtp(values.otp)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth?.data?.accessToken ?? '')
        setCurrentUser(user)
      } catch (error) {
        console.error(error?.response?.data)
        saveAuth(undefined)
        setStatus(error?.response?.data?.message ?? 'The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Dabe Beaute</div>
      </div>
      {/* begin::Heading */}
      {message.message.length > 0 ? (
        <div className={`alert ${message.status ? 'alert-success' : 'alert-danger'}`}>
          <div className='alert-text font-weight-bold'>{message.message}</div>
        </div>
      ) : null}

      {isOtpSend ? (
        <form
          className='form w-100'
          onSubmit={formikOtp.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>OTP</label>
            <input
              placeholder='OTP'
              {...formikOtp.getFieldProps('otp')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formikOtp.touched.otp && formikOtp.errors.otp},
                {
                  'is-valid': formikOtp.touched.otp && !formikOtp.errors.otp,
                }
              )}
              type='text'
              name='otp'
              autoComplete='off'
            />
            {formikOtp.touched.otp && formikOtp.errors.otp && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formikOtp.errors.otp}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='d-grid mb-10'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={formikOtp.isSubmitting || !formikOtp.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
      ) : (
        <form
          className='form w-100'
          onSubmit={formikLogin.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
              placeholder='Email'
              {...formikLogin.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formikLogin.touched.email && formikLogin.errors.email},
                {
                  'is-valid': formikLogin.touched.email && !formikLogin.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
            />
            {formikLogin.touched.email && formikLogin.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formikLogin.errors.email}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            <input
              type='password'
              autoComplete='off'
              {...formikLogin.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formikLogin.touched.password && formikLogin.errors.password,
                },
                {
                  'is-valid': formikLogin.touched.password && !formikLogin.errors.password,
                }
              )}
            />
            {formikLogin.touched.password && formikLogin.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formikLogin.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Wrapper */}
          <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
            <div />

            {/* begin::Link */}
            <Link to='/auth/forgot-password' className='link-primary'>
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
          {/* end::Wrapper */}

          {/* begin::Action */}
          <div className='d-grid mb-10'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={formikLogin.isSubmitting || !formikLogin.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
      )}
    </>
  )
}
