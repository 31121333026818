import {useEffect, useState} from 'react'
import {KTCard, KTIcon} from '../../../../../_metronic/helpers'
import {statusLabel} from '../../../../helpers/generate-status'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import {MdInfoOutline, MdOutlineCheckCircle} from 'react-icons/md'
import {useOrderStore} from '../states/order.state'
import {StatementExportItem} from '../../settlements/interfaces/settlement-export-result.interface'
import {useOrderShopeeStore} from '../states/order-shopee.state'

const OrderExportShopeeScreen = () => {
  const [selectedExport, setSelectedExport] = useState<StatementExportItem>(undefined)
  const [selectedIndex, setSelectedIndex] = useState<number>()
  const [currentPage, setCurrentPage] = useState<number>(0)

  const {getOrderExport, orderExportLoading, orderExportModel, orderExportItems} =
    useOrderShopeeStore()

  useEffect(() => {
    getOrderExport({
      page: 0,
      sort: '-created_at',
    })
  }, [])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    setCurrentPage(e.selected)
    getOrderExport({
      page: selectedPage,
      sort: '-created_at',
    })
  }

  return (
    <>
      <KTCard>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {orderExportLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>No</th>
                  <th>Keterangan</th>
                  <th>Dibuat</th>
                  <th>File</th>
                  {/* <th className='text-end'>Action</th> */}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {orderExportItems?.map((item, index) => {
                  const overallIndex = currentPage * 25 + index + 1
                  return (
                    <tr key={index}>
                      <td>{overallIndex}</td>
                      <td>
                        <span>
                          Order tanggal{' '}
                          {moment(+item?.extra_attribute?.start_date_create * 1000).format(
                            'DD/MM/YYYY HH:mm'
                          )}{' '}
                          -{' '}
                          {moment(+item?.extra_attribute?.end_date_create * 1000).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </span>
                      </td>
                      <td>
                        <span>{moment(item?.created_at).format('DD/MM/YYYY HH:mm:ss')}</span>
                      </td>
                      <td>
                        <span>
                          <a
                            href={`#`}
                            onClick={(e) => {
                              e.preventDefault()
                              // OrderDownload(item?.id)
                              window.open(
                                `${process.env.REACT_APP_ASSETS_URL}/${item.asset}`,
                                '_blank'
                              )
                            }}
                          >
                            Download File
                          </a>
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='card-footer'>
          <ReactPaginate
            previousLabel={'«'}
            nextLabel={'»'}
            breakLabel={'...'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            pageCount={orderExportModel?.data?.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={'pagination pagination-sm m-0 float-right'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </div>
      </KTCard>
    </>
  )
}

const OrderExportShopeeScreenWrapper = () => {
  return (
    <>
      <OrderExportShopeeScreen />
    </>
  )
}

export default OrderExportShopeeScreenWrapper
