import {useEffect, useRef, useState} from 'react'
import {useStockTransactionStore} from '../states/stock-transaction.state'
import {KTCard} from '../../../../../_metronic/helpers'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import Flatpickr from 'react-flatpickr'
import {generateNumberFormat} from '../../../../helpers/generate-number-format'

const StockTransactionSummaryPage = () => {
  const tableRef = useRef<HTMLDivElement>(null)
  const flatpickrRef = useRef<Flatpickr>(null)

  const {
    getStockTransactionsSummary,
    stockTransactionSummaryLoading,
    stockTransactionSummaryModel,
  } = useStockTransactionStore()

  const [startTime, setStartTime] = useState<Date>(new Date())
  const [endTime, setEndTime] = useState<Date>(new Date())

  useEffect(() => {
    if (startTime !== null && endTime !== null) {
      startTime?.setHours(0, 0, 0, 0)
      endTime?.setHours(23, 59, 59, 0)
      getStockTransactionsSummary({
        start_time: startTime?.getTime() / 1000,
        end_time: endTime?.getTime() / 1000,
      })
    }
  }, [startTime, endTime])

  useEffect(() => {
    setStartTime(new Date())
    setEndTime(new Date())
  }, [])

  const onChange = (dates: [Date, Date], event: React.SyntheticEvent<any, Event>) => {
    const [start, end] = dates
    setStartTime(start)
    setEndTime(end)
  }

  const handleScroll = () => {
    const table = tableRef.current
    if (table) {
      const {scrollTop, scrollLeft} = table
      console.log(scrollLeft)
      const frozenColumns = table.querySelectorAll('.frozen-column')
      frozenColumns.forEach((column) => {
        if (scrollLeft > 0) {
          column.classList.add('frozen-column-shadow')
        } else {
          column.classList.remove('frozen-column-shadow')
        }
      })
    }
  }

  return (
    <>
      <KTCard className='p-3 mb-5'>
        <div className='row'>
          <div className='col-sm-12'>
            <label className={`form-label`}>Date Range</label>
            <Flatpickr
              ref={flatpickrRef}
              value={[startTime, endTime]}
              onChange={([date1, date2]) => {
                setStartTime(date1)
                setEndTime(date2)
              }}
              options={{
                mode: 'range',
              }}
              className='form-control'
              placeholder='Pick date'
            />
          </div>
          {/* <div className='col-sm-6'>
            <label className={`form-label`}>Tanggal Mulai</label>
            <Flatpickr
              value={startTime}
              onChange={([date1]) => {
                setStartTime(date1)
              }}
              className='form-control'
              placeholder='Pick date'
            />
          </div>
          <div className='col-sm-6'>
            <label className={`form-label`}>Tanggal Akhir</label>
            <Flatpickr
              value={endTime}
              onChange={([date1]) => {
                setEndTime(date1)
              }}
              className='form-control'
              placeholder='Pick date'
            />
          </div> */}
        </div>
      </KTCard>
      <KTCard>
        <div className='card-body py-3'>
          <div className='table-responsive' ref={tableRef} onScroll={handleScroll}>
            {stockTransactionSummaryLoading && <UsersListLoading />}
            <table className='table table-row-dashed table-row-gray-300 gy-5'>
              <thead>
                <tr className='text-start fw-bold fs-7 text-uppercase gs-0'>
                  <th className='frozen-column'>Product</th>
                  <th className='text-center'>
                    Menunggu Diproses{' '}
                    <b>
                      {` `}(
                      {generateNumberFormat(
                        stockTransactionSummaryModel?.data?.status_qty_awaiting_collection
                      )}
                      )
                    </b>
                  </th>
                  <th className='text-center'>
                    Menunggu Dipickup
                    <b>
                      {` `}(
                      {generateNumberFormat(
                        stockTransactionSummaryModel?.data?.status_qty_awaiting_shipment
                      )}
                      )
                    </b>
                  </th>
                  <th className='text-center'>
                    Belum dibayar
                    <b>
                      {` `}(
                      {generateNumberFormat(stockTransactionSummaryModel?.data?.status_qty_unpaid)})
                    </b>
                  </th>
                  <th className='text-center'>
                    Dikirim
                    <b>
                      {` `}(
                      {generateNumberFormat(
                        stockTransactionSummaryModel?.data?.status_qty_in_transit
                      )}
                      )
                    </b>
                  </th>
                  <th className='text-center'>
                    Terkirim
                    <b>
                      {` `}(
                      {generateNumberFormat(
                        stockTransactionSummaryModel?.data?.status_qty_delivered
                      )}
                      )
                    </b>
                  </th>
                  <th className='text-center'>
                    Selesai
                    <b>
                      {` `}(
                      {generateNumberFormat(
                        stockTransactionSummaryModel?.data?.status_qty_completed
                      )}
                      )
                    </b>
                  </th>
                  <th className='text-center'>
                    Dibatalkan
                    <b>
                      {` `}(
                      {generateNumberFormat(
                        stockTransactionSummaryModel?.data?.status_qty_cancelled
                      )}
                      )
                    </b>
                  </th>
                  <th className='text-center'>
                    Sedang Dibatalkan
                    <b>
                      {` `}(
                      {generateNumberFormat(stockTransactionSummaryModel?.data?.status_qty_cancel)})
                    </b>
                  </th>
                  <th className='text-center'>
                    Total
                    <b>
                      {` `}(
                      {generateNumberFormat(stockTransactionSummaryModel?.data?.status_qty_total)})
                    </b>
                  </th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {stockTransactionSummaryModel?.data?.transactions?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className='frozen-column'>
                        <b>{item.product_name}</b>
                      </td>
                      <td className='text-center'>
                        {generateNumberFormat(item.status_qty_awaiting_collection ?? 0)}
                      </td>
                      <td className='text-center'>
                        {generateNumberFormat(item.status_qty_awaiting_shipment ?? 0)}
                      </td>
                      <td className='text-center'>
                        {generateNumberFormat(item.status_qty_unpaid ?? 0)}
                      </td>
                      <td className='text-center'>
                        {generateNumberFormat(item.status_qty_in_transit ?? 0)}
                      </td>
                      <td className='text-center'>
                        {generateNumberFormat(item.status_qty_delivered ?? 0)}
                      </td>
                      <td className='text-center'>
                        {generateNumberFormat(item.status_qty_completed ?? 0)}
                      </td>
                      <td className='text-center'>
                        {generateNumberFormat(item.status_qty_cancelled ?? 0)}
                      </td>
                      <td className='text-center'>
                        {generateNumberFormat(item.status_qty_cancel ?? 0)}
                      </td>
                      <td className='text-center'>
                        {generateNumberFormat(item.status_qty_total ?? 0)}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </KTCard>
    </>
  )
}

const StockTransactionSummaryWrapper = () => {
  return (
    <>
      <StockTransactionSummaryPage />
    </>
  )
}

export default StockTransactionSummaryWrapper
