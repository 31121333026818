import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {TrxCardboardsResultInterface} from '../interfaces/trx-cardboards-result.interface'
import {TrxStocksSingleResultInterface} from '../interfaces/trx-stocks-single-result.interface'

export interface GetAllTrxCardboardsParam {
  page?: number
  size?: number
}

export const GetAllTrxCardboards = async (
  params: GetAllTrxCardboardsParam
): Promise<TrxCardboardsResultInterface> => {
  try {
    const response = await Api.get('/trx-cardboards', {params: params})

    return response.data as TrxCardboardsResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TrxCardboardsResultInterface = a.response.data
    return response
  }
}

export const GetTrxCardboard = async (id: string): Promise<TrxStocksSingleResultInterface> => {
  try {
    const response = await Api.get('/trx-cardboards/' + id)
    return response.data as TrxStocksSingleResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TrxStocksSingleResultInterface = a.response?.data
    return response
  }
}
