import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {AxiosInterceptorSetup} from '../../api/api'
import {useAuth} from './core/Auth'

const AxiosInterceptorNavigate = () => {
  const navigate = useNavigate()
  const {logout} = useAuth()
  useEffect(() => {
    AxiosInterceptorSetup(navigate, logout)
  }, [])
  return <></>
}

export default AxiosInterceptorNavigate
