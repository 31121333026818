import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {ProductBundleResultInterface} from '../interfaces/product-bundle-result.interface'
import {ProductBundleSingleResultInterface} from '../interfaces/product-bundle-single-result.interface'
import {GeneralResponse} from '../../../../helpers/general-response'

export interface GetAllProductBundleParam {
  page?: number
  size?: number
}

export const GetAllProductBundle = async (
  params: GetAllProductBundleParam
): Promise<ProductBundleResultInterface> => {
  try {
    const response = await Api.get('/product-bundles', {params: params})

    return response.data as ProductBundleResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ProductBundleResultInterface = a.response.data
    return response
  }
}

export const GetProductBundle = async (id: string): Promise<ProductBundleSingleResultInterface> => {
  try {
    const response = await Api.get('/product-bundles/' + id)
    return response.data as ProductBundleSingleResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ProductBundleSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitProductBundle = async (form: any): Promise<ProductBundleResultInterface> => {
  try {
    const response = await Api.post('/product-bundles', form)
    return response.data as ProductBundleResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ProductBundleResultInterface = a.response?.data
    return response
  }
}

export const UpdateProductBundle = async (
  id: string,
  form: any
): Promise<ProductBundleResultInterface> => {
  try {
    const response = await Api.put('/product-bundles/' + id, form)
    return response.data as ProductBundleResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ProductBundleResultInterface = a.response?.data
    return response
  }
}

export const DeleteProductBundle = async (id: string): Promise<GeneralResponse> => {
  try {
    const response = await Api.delete('/product-bundles/' + id)
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}
