import {useEffect} from 'react'
import {KTCard, KTSVG} from '../../../../../_metronic/helpers'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import {FormAction} from '../../../../helpers/form-action-interfaces'
import {ProductCode} from '../interfaces/product-codes-result.interface'
import {useProductCodeFormStore, useProductStore} from '../states/product.state'
import ProductCodeModalComponent from '../components/product-code-modal.component'

const ProductCodeListComponent = () => {
  const {getProductCodes, productCodeLoading, productCodes, onDelete} = useProductStore()
  const {setField, field, generateForms, submitDone, formLoading, reset} = useProductCodeFormStore()

  useEffect(() => {
    getProductCodes({
      page: 0,
      size: 10,
    })
  }, [])

  useEffect(() => {
    if (submitDone === true && formLoading === false) {
      getProductCodes({page: 0, size: 10})
      reset()
    }
  }, [submitDone, formLoading])

  const openEditModal = (item: ProductCode) => {
    setField(item)
    generateForms({
      title: 'Role',
      subtitle: `Update Role ${item.product_name}`,
      action: FormAction.UPDATE,
      data: item,
      id: item.id.toString(),
    })
  }

  return (
    <>
      {/* <div className='py-5'>
        <div className='rounded border p-5' style={{backgroundColor: 'white'}}></div>
      </div> */}
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Product Codes</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Product code List</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                setField(null)
                generateForms({
                  title: 'Add New Product',
                  subtitle: 'Add New Product',
                  action: FormAction.CREATE,
                })
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Add New Product Code
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {productCodeLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th className='text-end min-w-100px'>Action</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {productCodes?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.product_name}</td>
                      <td>{item.product_code}</td>
                      <td className='text-end min-w-100px'>
                        <button
                          className='btn btn-warning btn-sm me-2' // Warning color for Edit
                          onClick={() => openEditModal(item)}
                        >
                          Edit
                        </button>
                        <button
                          className='btn btn-danger btn-sm' // Error color for Delete
                          onClick={(e) => {
                            e.preventDefault()
                            onDelete(item.id, {
                              page: 0,
                              size: 10,
                            })
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className='card-footer'></div>
        </div>
      </KTCard>
      {field !== undefined && <ProductCodeModalComponent />}
    </>
  )
}

const ProductCodeListWrapper = () => {
  return (
    <>
      <ProductCodeListComponent />
    </>
  )
}

export default ProductCodeListWrapper
