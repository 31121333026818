import {useEffect, useState} from 'react'
import {KTCard, KTSVG} from '../../../../../_metronic/helpers'
import {useProductBundleStore} from '../states/product-bundle.state'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import {useMasterStockStore} from '../states/master-stock.state'
import {useTrxStocksStore} from '../states/trx-stocks.state'

interface ModalDetailProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

const TrxStockDetailModalComponent: React.FC<ModalDetailProps> = ({isOpen, onClose, id}) => {
  const {getTrxStockDetail, trxStockOneLoading, trxStock} = useTrxStocksStore()
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open')
      getTrxStockDetail(id)
    } else {
      document.body.classList.remove('modal-open')
    }
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_properties'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bold'>Detail</h2>

              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={onClose}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              {trxStockOneLoading && <UsersListLoading />}
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  {trxStock?.trx_stock_type === 'in' ? (
                    <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                      <thead>
                        <tr>
                          <td>
                            <div className='card-header border-0 pt-6'>
                              <h4>No</h4>
                            </div>
                          </td>
                          <th>
                            <div className='card-header border-0 pt-6'>
                              <h4>Kode Transaksi</h4>
                            </div>
                          </th>
                          <th>
                            <div className='card-header border-0 pt-6'>
                              <h4>Nama Produk</h4>
                            </div>
                          </th>
                          <th>
                            <div className='card-header border-0 pt-6'>
                              <h4>Quantity</h4>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {trxStock?.trx_stock_details?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{trxStock?.trx_code}</td>
                              <td>{item?.product_code?.product_name}</td>
                              <td>{item?.quantity}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <>
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                          <h4 style={{margin: 0}}>Kategori:</h4>
                          <p style={{margin: '15px 10px'}}>{trxStock?.category}</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                          <h4 style={{margin: 0}}>Notes:</h4>
                          <p style={{margin: '0 10px'}}>{trxStock?.notes}</p>
                        </div>
                      </div>
                      <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                        <thead>
                          <tr>
                            <td>
                              <div className='card-header border-0 pt-6'>
                                <h4>No</h4>
                              </div>
                            </td>
                            <th>
                              <div className='card-header border-0 pt-6'>
                                <h4>Kode Transaksi</h4>
                              </div>
                            </th>
                            <th>
                              <div className='card-header border-0 pt-6'>
                                <h4>Nama Produk</h4>
                              </div>
                            </th>
                            <th>
                              <div className='card-header border-0 pt-6'>
                                <h4>Quantity</h4>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {trxStock?.trx_stock_details?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{trxStock?.trx_code}</td>
                                <td>
                                  {item?.product_code?.product_name}
                                  <span style={{fontWeight: 'bold'}}>
                                    {item?.is_free ? ' *Free' : ''}
                                  </span>
                                </td>
                                <td>{item?.quantity}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show' onClick={onClose}></div>
    </>
  )
}

export const TrxDeliveryAssetModalComponent: React.FC<ModalDetailProps> = ({
  isOpen,
  onClose,
  id,
}) => {
  const {getTrxStockDetail, trxStockOneLoading, trxStock} = useTrxStocksStore()
  const [imageFailed, setImageFailed] = useState(false)

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open')
      getTrxStockDetail(id)
    } else {
      document.body.classList.remove('modal-open')
    }
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [isOpen])

  if (!isOpen) return null

  const FALLBACK_IMAGE = require('../../../../assets/image-not-found/image-not-found.jpg')
  const imageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = FALLBACK_IMAGE
    setImageFailed(true)
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_properties'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bold'>Surat Jalan</h2>

              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={onClose}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              {trxStockOneLoading && <UsersListLoading />}
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  {trxStock?.trx_stock_type === 'in' ? (
                    <>
                      <img
                        src={`${process.env.REACT_APP_ASSETS_URL}/${trxStock?.delivery_order_asset}`}
                        onError={imageOnErrorHandler}
                        alt={trxStock?.id}
                        style={{
                          borderRadius: 10,
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                        }}
                      />
                      {!imageFailed && (
                        <div style={{marginTop: '10px', textAlign: 'center'}}>
                          <a
                            href={`${process.env.REACT_APP_ASSETS_URL}/${trxStock?.delivery_order_asset}`}
                            download
                            className='btn btn-primary'
                          >
                            Download Image
                          </a>
                        </div>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show' onClick={onClose}></div>
    </>
  )
}

export default TrxStockDetailModalComponent
