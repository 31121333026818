import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {lazy} from 'react'
import {SuspensedView} from '../../../../routing/PrivateRoutes'
import RoleListWrapper from './roles.screen'

const roleBreadcrumbs: Array<PageLink> = [
  {
    title: 'Role',
    path: '/apps/role-management/roles',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const RolePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='roles'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={roleBreadcrumbs}>Roles list</PageTitle>
              <RoleListWrapper />
            </SuspensedView>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/role-management/roles' />} />
    </Routes>
  )
}

export default RolePage
