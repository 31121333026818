import {create} from 'zustand'
import {
  TransactionItem,
  TransactionResultInterface,
} from '../interfaces/transaction-result.interface'
import {
  GetTransactions,
  GetTransactionsWithdraw,
  TransactionParam,
} from '../providers/transaction.provider'

interface TransactionState {
  transactionLoading: boolean
  transactionModel: TransactionResultInterface
  transactionItems: TransactionItem[]
  getTransactions: (params: TransactionParam) => void

  transactionWithdraw: number
  getTransactionWithdraw: () => void
}

export const useTransactionStore = create<TransactionState>((set, get) => ({
  transactionLoading: false,
  transactionModel: null,
  transactionItems: [],
  getTransactions: async (params: TransactionParam) => {
    set({
      transactionLoading: true,
      transactionModel: null,
    })
    const response = await GetTransactions(params)
    if (response?.status) {
      if (response.data?.items?.length > 0) {
        set({transactionItems: response.data?.items})
      }
    }
    set({
      transactionModel: response,
      transactionLoading: false,
    })
  },

  transactionWithdraw: 0,
  getTransactionWithdraw: async () => {
    const response = await GetTransactionsWithdraw()
    set({transactionWithdraw: response.data})
  },
}))
