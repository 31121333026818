import {useEffect, useRef, useState} from 'react'
import {useOrderStore} from '../states/order.state'
import {KTCard, KTIcon} from '../../../../../_metronic/helpers'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import {generateCurrencyFormat} from '../../../../helpers/generate-currency-format'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import {useNavigate} from 'react-router-dom'
import {generateStatus} from '../../../../helpers/generate-status'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {InputDatePicker} from '../../../../helpers/input.component'
import Flatpickr from 'react-flatpickr'
import {useProductStore} from '../../products/states/product.state'
import {StatisticsWidget5} from '../../../../../_metronic/partials/widgets'
import {generateNumberFormat} from '../../../../helpers/generate-number-format'

const OrderListComponent = () => {
  const {
    getOrders,
    orders,
    orderLoading,
    orderModel,
    countOrderLoading,
    countOrders,
    getCountOrders,
    setFilters,
    getGenerateExport,
    generateExportLoading,
  } = useOrderStore()
  const {getProductCodes, productCodes, productCodeLoading} = useProductStore()
  const [search, setSearch] = useState<string>('')
  const navigate = useNavigate()
  const [orderStatus, setOrderStatus] = useState<string | undefined>()
  const [startTime, setStartTime] = useState<Date>()
  const [endTime, setEndTime] = useState<Date>()
  const [startTimeCreate, setStartTimeCreate] = useState<Date>(
    new Date(new Date().setHours(0, 0, 0, 0))
  )
  const [endTimeCreate, setEndTimeCreate] = useState<Date>(
    new Date(new Date().setHours(23, 59, 59, 0))
  )
  const flatpickrRef = useRef<Flatpickr>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(0)

  useEffect(() => {
    getProductCodes({page: 0})
  }, [])

  useEffect(() => {
    startTimeCreate?.setHours(0, 0, 0, 0)
    endTimeCreate?.setHours(23, 59, 59, 0)
    const sDate = Math.floor(startTimeCreate?.getTime() / 1000)
    const eDate = Math.floor(endTimeCreate?.getTime() / 1000)

    getOrders({
      page: currentPage,
      size: 25,
      start_date_create: isNaN(sDate) ? undefined : sDate,
      end_date_create: isNaN(eDate) ? undefined : eDate,
      sort: '-update_time',
    })
    getCountOrders({
      sort: '-update_time',
      start_date_create: isNaN(sDate) ? undefined : sDate,
      end_date_create: isNaN(eDate) ? undefined : eDate,
    })
  }, [])

  const handlePageClick = (e: any) => {
    startTime?.setHours(0, 0, 0, 0)
    endTime?.setHours(23, 59, 59, 0)
    const sDate = Math.floor(startTime?.getTime() / 1000)
    const eDate = Math.floor(endTime?.getTime() / 1000)

    startTimeCreate?.setHours(0, 0, 0, 0)
    endTimeCreate?.setHours(23, 59, 59, 0)
    const sDateCreate = Math.floor(startTimeCreate?.getTime() / 1000)
    const eDateCreate = Math.floor(endTimeCreate?.getTime() / 1000)

    const selectedPage = e.selected
    setCurrentPage(e.selected)
    getOrders({
      page: selectedPage,
      size: 25,
      start_date: isNaN(sDate) ? undefined : sDate,
      end_date: isNaN(eDate) ? undefined : eDate,
      start_date_create: isNaN(sDateCreate) ? undefined : sDateCreate,
      end_date_create: isNaN(eDateCreate) ? undefined : eDateCreate,
      order_id: search ?? undefined,
      status: orderStatus ?? undefined,
      sort: '-update_time',
    })
    getCountOrders({
      page: selectedPage,
      size: 25,
      start_date: isNaN(sDate) ? undefined : sDate,
      end_date: isNaN(eDate) ? undefined : eDate,
      start_date_create: isNaN(sDateCreate) ? undefined : sDateCreate,
      end_date_create: isNaN(eDateCreate) ? undefined : eDateCreate,
      order_id: search ?? undefined,
      status: orderStatus ?? undefined,
      sort: '-update_time',
    })
  }

  const resetData = () => {
    setOrderStatus('')
    setStartTime(null)
    setEndTime(null)
    setStartTimeCreate(null)
    setEndTimeCreate(null)
    setFilters(null)
    setSearch('')
    getOrders({
      page: 0,
      size: 25,
      sort: '-update_time',
    })
  }

  const filterData = () => {
    setCurrentPage(0)
    startTime?.setHours(0, 0, 0, 0)
    endTime?.setHours(23, 59, 59, 0)
    const sDate = Math.floor(startTime?.getTime() / 1000)
    const eDate = Math.floor(endTime?.getTime() / 1000)

    startTimeCreate?.setHours(0, 0, 0, 0)
    endTimeCreate?.setHours(23, 59, 59, 0)
    const sDateCreate = Math.floor(startTimeCreate?.getTime() / 1000)
    const eDateCreate = Math.floor(endTimeCreate?.getTime() / 1000)

    setCurrentPage(0)
    getOrders({
      page: 0,
      size: 25,
      start_date: isNaN(sDate) ? undefined : sDate,
      end_date: isNaN(eDate) ? undefined : eDate,
      start_date_create: isNaN(sDateCreate) ? undefined : sDateCreate,
      end_date_create: isNaN(eDateCreate) ? undefined : eDateCreate,
      order_id: search ?? undefined,
      status: orderStatus ?? undefined,
      sort: '-create_time',
    })
    getCountOrders({
      page: 0,
      size: 25,
      start_date: isNaN(sDate) ? undefined : sDate,
      end_date: isNaN(eDate) ? undefined : eDate,
      start_date_create: isNaN(sDateCreate) ? undefined : sDateCreate,
      end_date_create: isNaN(eDateCreate) ? undefined : eDateCreate,
      order_id: search ?? undefined,
      status: orderStatus ?? undefined,
      sort: '-create_time',
    })
  }

  return (
    <>
      <KTCard className='p-3 mb-5'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-4'>
              <label className='form-label'>Order ID</label>
              <input
                onChange={(e) => setSearch(e.target.value)}
                placeholder='Search Order ID'
                value={search}
                className={'form-control'}
                type='text'
              />
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Status Pesanan:</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setOrderStatus(e.target.value)}
                value={orderStatus}
              >
                <option value=''></option>
                <option value='AWAITING_COLLECTION'>{generateStatus('AWAITING_COLLECTION')}</option>
                <option value='AWAITING_SHIPMENT'>{generateStatus('AWAITING_SHIPMENT')}</option>
                <option value='CANCELLED'>{generateStatus('CANCELLED')}</option>
                <option value='UNPAID'>{generateStatus('UNPAID')}</option>
                <option value='IN_TRANSIT'>{generateStatus('IN_TRANSIT')}</option>
                <option value='COMPLETED'>{generateStatus('COMPLETED')}</option>
                <option value='DELIVERED'>{generateStatus('DELIVERED')}</option>
              </select>
            </div>
            <div className='col-sm-4'>
              <label className={`form-label`}>Date Range (Create Time)</label>
              <Flatpickr
                ref={flatpickrRef}
                value={[startTimeCreate, endTimeCreate]}
                onChange={([date1, date2]) => {
                  setStartTimeCreate(date1)
                  setEndTimeCreate(date2)
                }}
                options={{
                  mode: 'range',
                }}
                className='form-control'
                placeholder='Pick date'
              />
            </div>
            <div className='col-sm-4'>
              <label className={`form-label`}>Date Range (Update Time)</label>
              <Flatpickr
                ref={flatpickrRef}
                value={[startTime, endTime]}
                onChange={([date1, date2]) => {
                  setStartTime(date1)
                  setEndTime(date2)
                }}
                options={{
                  mode: 'range',
                }}
                className='form-control'
                placeholder='Pick date'
              />
            </div>
          </div>
        </div>
        <div className='card-footer'>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={generateExportLoading}
              onClick={(e) => {
                startTime?.setHours(0, 0, 0, 0)
                endTime?.setHours(23, 59, 59, 0)
                const sDate = Math.floor(startTime?.getTime() / 1000)
                const eDate = Math.floor(endTime?.getTime() / 1000)

                startTimeCreate?.setHours(0, 0, 0, 0)
                endTimeCreate?.setHours(23, 59, 59, 0)
                const sDateCreate = Math.floor(startTimeCreate?.getTime() / 1000)
                const eDateCreate = Math.floor(endTimeCreate?.getTime() / 1000)

                getGenerateExport({
                  page: 0,
                  size: 25,
                  start_date: isNaN(sDate) ? undefined : sDate,
                  end_date: isNaN(eDate) ? undefined : eDate,
                  start_date_create: isNaN(sDateCreate) ? undefined : sDateCreate,
                  end_date_create: isNaN(eDateCreate) ? undefined : eDateCreate,
                  order_id: search ?? undefined,
                  status: orderStatus ?? undefined,
                  sort: '-create_time',
                })
              }}
              className='btn btn-success btn-active-success-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Export
            </button>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
        </div>
      </KTCard>
      {/* <div className='py-5'>
        <div className='rounded border p-5' style={{backgroundColor: 'white'}}></div>
      </div> */}

      <div className='row g-5 g-xl-4'>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-6'
            svgIcon='chart-simple'
            color='white'
            iconColor='primary'
            title={`${generateNumberFormat(countOrders ?? 0)}`}
            description={countOrderLoading ? `Loading...` : `Total Data`}
          />
        </div>
      </div>

      <KTCard>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {orderLoading && productCodeLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>No</th>
                  <th className='frozen-column'>Order ID Pesanan</th>
                  <th>Status Pesanan</th>
                  <th>Status Settlement</th>
                  <th>Pembeli</th>
                  <th>Waktu Pesanan</th>
                  <th>Produk</th>
                  <th>Variation</th>
                  <th>Quantity</th>
                  {productCodes.map((item, index) => {
                    return <th key={index}>{item.product_code}</th>
                  })}
                  <th className='text-end'>Action</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {orders?.map((item, index) => {
                  const overallIndex = currentPage * 25 + index + 1
                  const productQtyMap: Record<string, number> = {}
                  item.stock_transactions?.forEach((transaction) => {
                    productQtyMap[transaction.product_code] = transaction.total_qty
                  })
                  return (
                    <tr key={index}>
                      <td>
                        <span>{overallIndex}</span>
                      </td>
                      <td className='frozen-column'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex flex-column'>
                            <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                              <b>{item.order_id}</b>
                            </a>
                            <span
                              style={{
                                fontSize: 10,
                              }}
                            >
                              {moment(item.create_time * 1000).format('DD/MM/YYYY HH:mm:ss')}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <b>{generateStatus(item.status ?? 'UNPAID')}</b>
                      </td>
                      <td>
                        <b>
                          {item?.settlement?.statement_detail_id !== ''
                            ? `Sudah Settle ${generateCurrencyFormat(
                                item?.settlement?.settlement_amount ?? 0
                              )}`
                            : 'Belum Settle'}
                        </b>
                      </td>
                      <td>
                        <span>
                          {item?.recipient_address?.name ?? item?.recipient_address?.phone_number}
                        </span>
                      </td>
                      <td>{moment(item?.create_time * 1000).format('DD/MM/YYYY HH:mm:ss')}</td>
                      <td>
                        <span>{item.line_items_each_product_name}</span>
                      </td>
                      <td>{item.line_items_each_sku_name}</td>
                      <td>{item.qty}</td>
                      {productCodes.map((product, productIndex) => (
                        <td key={productIndex}>{productQtyMap[product.product_code] ?? 0}</td>
                      ))}
                      <td className='text-end'>
                        <button
                          className='btn btn-success'
                          onClick={(e) => {
                            e.preventDefault()
                            navigate(`/apps/order-management/orders/${item.order_id}`)
                          }}
                        >
                          Detail
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={orderModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              initialPage={currentPage}
              forcePage={currentPage}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
    </>
  )
}

const OrderListFilter = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [orderStatus, setOrderStatus] = useState<string | undefined>()
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())
  const {getOrders, orders, orderLoading, orderModel, refreshOrders, setFilters} = useOrderStore()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setOrderStatus(undefined)
    setStartDate(new Date())
    setEndDate(new Date())
    setFilters(undefined)
    getOrders({
      page: 0,
      size: 25,
      sort: '-update_time',
    })
  }

  const filterData = () => {
    const sDate = Math.floor(startDate.getTime() / 1000)
    const eDate = Math.floor(endDate.getTime() / 1000)
    if (orderStatus?.length > 0 && sDate > 0 && eDate > 0) {
      let filters = ''
      filters = `[["status", "${orderStatus}"], ["AND"], ["create_time", "between", [${sDate}, ${eDate}]]]`
      setFilters(filters)
      getOrders({
        page: 0,
        size: 25,
        filters: filters,
        sort: '-update_time',
      })
    } else if (sDate > 0 && eDate > 0) {
      let filters = ''
      filters = `["create_time", "between", [${sDate}, ${eDate}]]`
      setFilters(filters)
      getOrders({
        page: 0,
        size: 25,
        filters: filters,
        sort: '-update_time',
      })
    } else {
      setFilters(undefined)
      getOrders({
        page: 0,
        size: 25,
        sort: '-update_time',
      })
    }
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Status Pesanan:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setOrderStatus(e.target.value)}
              value={orderStatus}
            >
              <option value=''></option>
              <option value='AWAITING_COLLECTION'>Menunggu dikirim</option>
              <option value='AWAITING_SHIPMENT'>Perlu dikirim</option>
              <option value='CANCELLED'>Dibatalkan</option>
              <option value='UNPAID'>Belum dibayar</option>
              <option value='IN_TRANSIT'>Dikirim</option>
              <option value='COMPLETED'>Selesai</option>
              <option value='DELIVERED'>Terkirim</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Tanggal Mulai:</label>
            <div
              style={{
                marginBottom: -10,
              }}
            />
            <InputDatePicker
              value={startDate}
              onChangeDate={(date) => {
                setStartDate(date)
              }}
              id={'start_date'}
              title={''}
              name={'start_date'}
              type={'date'}
              required={false}
            />
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Tanggal Akhir:</label>
            <div
              style={{
                marginBottom: -10,
              }}
            />
            <InputDatePicker
              value={endDate}
              onChangeDate={(date) => {
                setEndDate(date)
              }}
              id={'end_date'}
              title={''}
              name={'end_date'}
              type={'date'}
              required={false}
            />
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

const OrderListWrapper = () => {
  return (
    <>
      <OrderListComponent />
    </>
  )
}

export default OrderListWrapper
