import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {TransactionResultInterface} from '../interfaces/transaction-result.interface'
import {TransactionWithdrawResultInterface} from '../interfaces/transaction-withdraw-result.interface'
import {WalletTransactionResult} from '../interfaces/wallet-trx-result.interface'

export interface TransactionParam {
  page?: number
  size?: number
  filters?: string
  sort?: string
  start_time?: number
  end_time?: number
  transaction_type?: string
}

export const GetTransactions = async (
  params: TransactionParam
): Promise<TransactionResultInterface> => {
  try {
    const response = await Api.get('/trx', {params: params})
    return response.data as TransactionResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TransactionResultInterface = a.response?.data
    return response
  }
}

export const GetTransactionsShopee = async (
  params: TransactionParam
): Promise<WalletTransactionResult> => {
  try {
    const response = await Api.get('/trx-shopee', {params: params})
    return response.data as WalletTransactionResult
  } catch (e) {
    const a = e as AxiosError
    const response: WalletTransactionResult = a.response?.data
    return response
  }
}

export const GetTransactionsWithdraw = async (): Promise<TransactionWithdrawResultInterface> => {
  try {
    const response = await Api.get('/trx-withdraw')
    return response.data as TransactionWithdrawResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TransactionWithdrawResultInterface = a.response?.data
    return response
  }
}
