import {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useOrderStore} from '../states/order.state'
import moment from 'moment'
import {KTCard} from '../../../../../_metronic/helpers'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import {generateStatus} from '../../../../helpers/generate-status'

const OrderDetailComponent = () => {
  const params = useParams()
  const {getOrder, order, resetOrder, getShippingDocument, shippingDoc, shippingDocLoading} =
    useOrderStore()

  useEffect(() => {
    if (params.id) {
      getOrder(params.id)
    }
  }, [params])

  useEffect(() => {
    return () => {
      resetOrder()
    }
  }, [])

  return (
    <>
      <div className='py-5'>
        <div className='rounded border p-5' style={{backgroundColor: 'white'}}>
          <h4>Order Id</h4>
          <p>{order?.order_id}</p>
          <h4>Created at</h4>
          <p>{moment(order?.create_time * 1000).format('YYYY-MM-DD HH:mm:ss')}</p>
          <h4>Payment Method</h4>
          <p>{order?.payment_method_name}</p>
          <h4>Shipping Provider</h4>
          <p>{order?.shipping_provider}</p>
          <h4>Shipping Type</h4>
          <p>{order?.shipping_type}</p>
          <h4>Status</h4>
          <p>{generateStatus(order?.status)}</p>
          <h4>Package ID</h4>
          <p>{order?.packages[0]?.id ?? ''}</p>
        </div>
      </div>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h4>Products</h4>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr>
                  <th>Thumbnail</th>
                  <th>Product Name</th>
                  <th>Sku Name</th>
                </tr>
              </thead>
              <tbody>
                {order?.line_items?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <img
                          src={item.sku_image}
                          alt={item.id}
                          style={{
                            borderRadius: 10,
                            width: 150,
                            height: 75,
                            objectFit: 'cover',
                          }}
                        />
                      </td>
                      <td>{item.product_name}</td>
                      <td>{item.sku_name}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </KTCard>
    </>
  )
}

export default OrderDetailComponent
