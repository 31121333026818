import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {SuspensedView} from '../../../../routing/PrivateRoutes'
import MasterStockWrapper from './master-stock.screen'
import TrxCardboardsWrapper from './trx-cardboards.screen'
import TrxStocksInWrapper from './trx-stocks-in.screen'
import TrxStocksOutWrapper from './trx-stocks-out.screen'
import ProductBundleWrapper from './product-bundle.screen'
import VendorsWrapper from './vendors.screen'
import TrxStockReportScreenWrapper from './trx-stock-report.screen'
import MasterStockReportScreenWrapper from './master-stock-report.screen'
import TrxResiWrapper from './trx-resi.screen'
import TrxResiReportScreenWrapper from './trx-resi-report.screen'

const warehouseBreadcrumbs: Array<PageLink> = [
  {
    title: 'Warehouse',
    path: '/apps/warehouse-management/master-stock',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const WarehousePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='vendors'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={warehouseBreadcrumbs}>Vendor</PageTitle>
              <VendorsWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='master-stock'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={warehouseBreadcrumbs}>Master Stock</PageTitle>
              <MasterStockWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='kardus-barang'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={warehouseBreadcrumbs}>Kardus Barang</PageTitle>
              <TrxCardboardsWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='barang-masuk'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={warehouseBreadcrumbs}>Barang Masuk</PageTitle>
              <TrxStocksInWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='barang-keluar'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={warehouseBreadcrumbs}>Barang Keluar</PageTitle>
              <TrxStocksOutWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='product-bundle'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={warehouseBreadcrumbs}>Product Bundle</PageTitle>
              <ProductBundleWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='report-stock-in-out'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={warehouseBreadcrumbs}>Report Stock In/Out</PageTitle>
              <TrxStockReportScreenWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='report-master-stock'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={warehouseBreadcrumbs}>Report Master Stock</PageTitle>
              <MasterStockReportScreenWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='trx-resi'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={warehouseBreadcrumbs}>Resi</PageTitle>
              <TrxResiWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='trx-resi-report'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={warehouseBreadcrumbs}>Resi Report</PageTitle>
              <TrxResiReportScreenWrapper />
            </SuspensedView>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/warehouse-management/master-stock' />} />
    </Routes>
  )
}

export default WarehousePage
