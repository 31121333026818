import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {SuspensedView} from '../../../../routing/PrivateRoutes'
import ShippingDocShopeeScreenWrapper from './shipping-doc-shopee.screen'
import ShippingDocShopeeExportScreenWrapper from './shipping-doc-export-shopee.screen'

const shippingDocBreadcrumbs: Array<PageLink> = [
  {
    title: 'Generate Resi',
    path: '/apps/shipping-docs-management/shipping-doc',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ShippingDocShopeePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='shipping-doc-shopee'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={shippingDocBreadcrumbs}>Generate Resi</PageTitle>
              <ShippingDocShopeeScreenWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='shipping-doc-export-shopee'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={shippingDocBreadcrumbs}>Cetak Resi</PageTitle>
              <ShippingDocShopeeExportScreenWrapper />
            </SuspensedView>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/shipping-docs-management-shopee/shipping-doc' />} />
    </Routes>
  )
}

export default ShippingDocShopeePage
