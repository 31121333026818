import {useEffect, useRef, useState} from 'react'
// import {useProductStore} from '../states/product.state'
import {KTCard} from '../../../../../_metronic/helpers'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import {generateCurrencyFormat} from '../../../../helpers/generate-currency-format'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import {useProductStore} from '../../products/states/product.state'
import {useSettlementStore} from '../states/settlement.state'
import {generateStatus, generateType} from '../../../../helpers/generate-status'
import Flatpickr from 'react-flatpickr'
import {StatisticsWidget5} from '../../../../../_metronic/partials/widgets'
import {generateNumberFormat} from '../../../../helpers/generate-number-format'

const SettlementListComponent = () => {
  const {
    getSettlements,
    settlementLoading,
    settlements,
    settlementModel,
    getTotalSettlement,
    totalSettlementLoading,
    totalSettlements,
    generateExportLoading,
    getGenerateExport,
  } = useSettlementStore()

  const flatpickrRef = useRef<Flatpickr>(null)
  // const [filters, setFilters] = useState<any[]>([])
  // const [statementId, setStatementId] = useState<string>()
  const [search, setSearch] = useState<string>()
  const [statementId, setStatementId] = useState<string>()
  const [statusLabel, setStatusLabel] = useState<string>()
  const [typeLabel, setTypeLabel] = useState<string>()
  const [startTime, setStartTime] = useState<Date>(null)
  const [endTime, setEndTime] = useState<Date>(null)
  const [sort, setSort] = useState<string>('statement_time')
  const [order, setOrder] = useState<string>('-')
  const [orderTime, setOrderTime] = useState<Date>(null)
  const [orderEndTime, setOrderEndTime] = useState<Date>(null)

  const resetData = () => {
    setStartTime(null)
    setEndTime(null)
    setOrderTime(null)
    setOrderEndTime(null)
    setSearch('')
    setStatusLabel('')
    setTypeLabel('')
    setOrder('statement_time')
    setSort('-')
  }

  useEffect(() => {
    getSettlements({
      sort: '-statement_time',
      page: 0,
      size: 10,
    })
    getTotalSettlement({})
  }, [])

  const filterData = () => {
    startTime?.setHours(0, 0, 0, 0)
    endTime?.setHours(23, 59, 59, 0)
    const sDate = Math.floor(startTime?.getTime() / 1000)
    const eDate = Math.floor(endTime?.getTime() / 1000)

    orderTime?.setHours(0, 0, 0, 0)
    orderEndTime?.setHours(23, 59, 59, 0)
    const sOrderTime = Math.floor(orderTime?.getTime() / 1000)
    const sOrderEndTime = Math.floor(orderEndTime?.getTime() / 1000)

    getSettlements({
      type: typeLabel ?? undefined,
      sort: `${order}${sort}`,
      start_date_statement: isNaN(sDate) ? undefined : sDate,
      end_date_statement: isNaN(eDate) ? undefined : eDate,
      start_date: isNaN(sOrderTime) ? undefined : sOrderTime,
      end_date: isNaN(sOrderEndTime) ? undefined : sOrderEndTime,
      order_id: search ?? undefined,
      status: statusLabel ?? undefined,
      size: 10,
      page: 0,
    })
    getTotalSettlement({
      type: typeLabel ?? undefined,
      sort: `${order}${sort}`,
      start_date_statement: isNaN(sDate) ? undefined : sDate,
      end_date_statement: isNaN(eDate) ? undefined : eDate,
      start_date: isNaN(sOrderTime) ? undefined : sOrderTime,
      end_date: isNaN(sOrderEndTime) ? undefined : sOrderEndTime,
      order_id: search ?? undefined,
      status: statusLabel ?? undefined,
      size: 10,
      page: 0,
    })
  }

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected

    startTime?.setHours(0, 0, 0, 0)
    endTime?.setHours(23, 59, 59, 0)
    const sDate = Math.floor(startTime?.getTime() / 1000)
    const eDate = Math.floor(endTime?.getTime() / 1000)

    orderTime?.setHours(0, 0, 0, 0)
    orderEndTime?.setHours(23, 59, 59, 0)
    const sOrderTime = Math.floor(orderTime?.getTime() / 1000)
    const sOrderEndTime = Math.floor(orderEndTime?.getTime() / 1000)

    getSettlements({
      type: typeLabel ?? undefined,
      sort: `${order}${sort}`,
      start_date_statement: isNaN(sDate) ? undefined : sDate,
      end_date_statement: isNaN(eDate) ? undefined : eDate,
      start_date: isNaN(sOrderTime) ? undefined : sOrderTime,
      end_date: isNaN(sOrderEndTime) ? undefined : sOrderEndTime,
      order_id: search ?? undefined,
      status: statusLabel ?? undefined,
      size: 10,
      page: selectedPage,
    })
  }

  return (
    <>
      <KTCard className='p-3 mb-5'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-4'>
              <label className='form-label'>Order ID</label>
              <input
                onChange={(e) => setSearch(e.target.value)}
                placeholder='ID Pesanan'
                value={search}
                className={'form-control'}
                type='text'
              />
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Status Label:</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setStatusLabel(e.target.value)}
                value={statusLabel}
              >
                <option value=''></option>
                <option value='AWAITING_COLLECTION'>{generateStatus('AWAITING_COLLECTION')}</option>
                <option value='AWAITING_SHIPMENT'>{generateStatus('AWAITING_SHIPMENT')}</option>
                <option value='CANCELLED'>{generateStatus('CANCELLED')}</option>
                <option value='UNPAID'>{generateStatus('UNPAID')}</option>
                <option value='IN_TRANSIT'>{generateStatus('IN_TRANSIT')}</option>
                <option value='COMPLETED'>{generateStatus('COMPLETED')}</option>
                <option value='DELIVERED'>{generateStatus('DELIVERED')}</option>
              </select>
            </div>
            <div className='col-sm-4'>
              <label className={`form-label`}>Settlement Range</label>
              <Flatpickr
                ref={flatpickrRef}
                value={[startTime, endTime]}
                onChange={([date1, date2]) => {
                  setStartTime(date1)
                  setEndTime(date2)
                }}
                options={{
                  mode: 'range',
                  altInput: true,
                }}
                className='form-control'
                placeholder='Pick date'
              />
            </div>
            <div className='col-sm-4'>
              <label className={`form-label`}>Order Range</label>
              <Flatpickr
                ref={flatpickrRef}
                value={[orderTime, orderEndTime]}
                onChange={([date1, date2]) => {
                  setOrderTime(date1)
                  setOrderEndTime(date2)
                }}
                options={{
                  mode: 'range',
                  altInput: true,
                }}
                className='form-control'
                placeholder='Pick date'
              />
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Type Label:</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setTypeLabel(e.target.value)}
                value={typeLabel}
              >
                <option value=''></option>
                <option value='ORDER'>{generateType('ORDER')}</option>
                <option value='PLATFORM_REIMBURSEMENT'>
                  {generateType('PLATFORM_REIMBURSEMENT')}
                </option>
                <option value='LOGISTICS_REIMBURSEMENT'>
                  {generateType('LOGISTICS_REIMBURSEMENT')}
                </option>
                <option value='DEDUCTIONS_INCURRED_BY_SELLER'>
                  {generateType('DEDUCTIONS_INCURRED_BY_SELLER')}
                </option>
              </select>
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Sort By:</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setSort(e.target.value)}
                value={sort}
              >
                <option value='statement_time'>{'Waktu Statement'}</option>
                <option value='order_create_time'>{'Waktu Order'}</option>
                <option value='settlement_amount'>{'Settlement Amount'}</option>
              </select>
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Order:</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setOrder(e.target.value)}
                value={order}
              >
                <option value='-'>{'DESC'}</option>
                <option value=''>{'ASC'}</option>
              </select>
            </div>
          </div>
        </div>
        <div className='card-footer'>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={generateExportLoading}
              onClick={(e) => {
                startTime?.setHours(0, 0, 0, 0)
                endTime?.setHours(23, 59, 59, 0)
                const sDate = Math.floor(startTime?.getTime() / 1000)
                const eDate = Math.floor(endTime?.getTime() / 1000)

                getGenerateExport({
                  type: typeLabel ?? undefined,
                  sort: `${order}${sort}`,
                  start_date_statement: isNaN(sDate) ? undefined : sDate,
                  end_date_statement: isNaN(eDate) ? undefined : eDate,
                  order_id: search ?? undefined,
                  status: statusLabel ?? undefined,
                })
              }}
              className='btn btn-success btn-active-success-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Export
            </button>
            <button
              type='button'
              disabled={settlementLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={settlementLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
        </div>
      </KTCard>

      <div className='row g-5 g-xl-4'>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-6'
            svgIcon='chart-simple'
            color='white'
            iconColor='primary'
            title={`${generateNumberFormat(totalSettlements ?? 0)}`}
            description={totalSettlementLoading ? `Loading...` : `Total Settlement`}
          />
        </div>
      </div>

      <KTCard>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {settlementLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Order ID</th>
                  <th>Status Pesanan</th>
                  <th>Pembeli</th>
                  <th>Waktu Pesanan</th>
                  <th>Waktu Settlement</th>
                  <th>Produk</th>
                  <th>Order Amount</th>
                  <th>Admin Fee</th>
                  <th>Settlement Amount</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {settlements?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex flex-column'>
                            <b>{item.order_id?.length > 0 ? item.order_id : item.type}</b>
                          </div>
                        </div>
                      </td>
                      <td>
                        <b>{generateStatus(item.order_status ?? 'UNPAID')}</b>
                      </td>
                      <td>
                        <span>
                          {item?.recipient_address?.name ?? item?.recipient_address?.phone_number}
                        </span>
                      </td>
                      <td>{moment(item.order_create_time * 1000).format('DD/MM/YYYY HH:mm:ss')}</td>
                      <td>{moment(item?.statement_time * 1000).format('DD/MM/YYYY HH:mm:ss')}</td>
                      <td>
                        <div className='d-flex flex-column'>
                          <span>
                            <b>{item.line_items?.length}</b>{' '}
                            {item.line_items?.length > 0 ? 'produk' : ''}
                          </span>
                          {item.line_items?.length > 0 ? (
                            <div className='symbol symbol-35px'>
                              <img
                                src={item.line_items[0]?.sku_image}
                                alt={item.line_items[0]?.product_id}
                              />
                            </div>
                          ) : null}
                        </div>
                      </td>
                      <td>{generateCurrencyFormat(item.revenue_amount ?? 0)}</td>
                      <td>{generateCurrencyFormat(item.fee_amount ?? 0)}</td>
                      <td>{generateCurrencyFormat(item.settlement_amount)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={settlementModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
    </>
  )
}

const SettlementListWrapper = () => {
  return (
    <>
      <SettlementListComponent />
    </>
  )
}

export default SettlementListWrapper
