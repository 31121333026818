import {useEffect, useState} from 'react'
import {KTCard, KTIcon} from '../../../../../_metronic/helpers'
import {useShippingDocStore} from '../states/shipping-docs.state'
import {statusLabel} from '../../../../helpers/generate-status'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import {ShippingDocExportItem} from '../interfaces/shipping-doc-export-result.interface'
import {MdInfoOutline, MdOutlineCheckCircle} from 'react-icons/md'

const ShippingDocExportScreen = () => {
  const [selectedExport, setSelectedExport] = useState<ShippingDocExportItem>(undefined)
  const [selectedIndex, setSelectedIndex] = useState<number>()
  const [currentPage, setCurrentPage] = useState<number>(0)

  const {
    getShippingDocExport,
    shippingDocExportLoading,
    shippingDocExportModel,
    shippingDocExportsItems,
    shippingDocDownload,
  } = useShippingDocStore()

  useEffect(() => {
    getShippingDocExport({
      page: 0,
      sort: '-created_at',
    })
  }, [])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    setCurrentPage(e.selected)
    getShippingDocExport({
      page: selectedPage,
      sort: '-created_at',
    })
  }

  return (
    <>
      <KTCard>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {shippingDocExportLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>No</th>
                  <th>Keterangan</th>
                  <th>Dibuat</th>
                  <th>File</th>
                  {/* <th className='text-end'>Action</th> */}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {shippingDocExportsItems?.map((item, index) => {
                  const overallIndex = currentPage * 25 + index + 1
                  return (
                    <tr key={index}>
                      <td>{overallIndex}</td>
                      <td>
                        <span>
                          Resi tanggal{' '}
                          {moment(+item?.extra_attributes?.start_time * 1000).format(
                            'DD/MM/YYYY HH:mm'
                          )}{' '}
                          -{' '}
                          {moment(+item?.extra_attributes?.end_time * 1000).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </span>
                        <MdInfoOutline
                          size={20}
                          style={{marginLeft: 10, cursor: 'pointer'}}
                          onClick={(e) => {
                            setSelectedIndex(overallIndex)
                            setSelectedExport(item)
                          }}
                        />
                        {item?.is_download === true ? (
                          <MdOutlineCheckCircle
                            size={20}
                            style={{marginLeft: 10, cursor: 'pointer'}}
                            color='green'
                          />
                        ) : null}
                      </td>
                      <td>
                        <span>{moment(item?.created_at).format('DD/MM/YYYY HH:mm:ss')}</span>
                      </td>
                      <td>
                        <span>
                          <a
                            href={`#`}
                            onClick={(e) => {
                              e.preventDefault()
                              shippingDocDownload(item?.id)
                              window.open(
                                `${process.env.REACT_APP_ASSETS_URL}/${item.asset}`,
                                '_blank'
                              )
                            }}
                          >
                            Download File
                          </a>
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='card-footer'>
          <ReactPaginate
            previousLabel={'«'}
            nextLabel={'»'}
            breakLabel={'...'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            pageCount={shippingDocExportModel?.data?.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={'pagination pagination-sm m-0 float-right'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </div>
      </KTCard>
      {selectedExport !== undefined ? (
        <SelectedExportEditModal
          selectedExport={selectedExport}
          setSelectedExport={setSelectedExport}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      ) : null}
    </>
  )
}

interface SelectedExportEditModalProps {
  selectedExport: ShippingDocExportItem
  setSelectedExport: React.Dispatch<React.SetStateAction<ShippingDocExportItem>>
  selectedIndex: number
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
}

const SelectedExportEditModal = ({
  selectedExport,
  setSelectedExport,
  selectedIndex,
  setSelectedIndex,
}: SelectedExportEditModalProps) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>{selectedIndex} - Detail Cetak Resi</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => {
                  setSelectedExport(undefined)
                  setSelectedIndex(undefined)
                }}
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              {/* <UserEditModalFormWrapper /> */}
              <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                <thead>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <td>Resi tanggal</td>
                    <td>
                      {moment(+selectedExport?.extra_attributes?.start_time * 1000).format(
                        'DD/MM/YYYY HH:mm'
                      )}{' '}
                      -{' '}
                      {moment(+selectedExport?.extra_attributes?.end_time * 1000).format(
                        'DD/MM/YYYY HH:mm'
                      )}
                    </td>
                  </tr>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <td>SKU</td>
                    <td>{selectedExport?.extra_attributes?.sku_name}</td>
                  </tr>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <td>QTY</td>
                    <td>{selectedExport?.extra_attributes?.qty}</td>
                  </tr>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <td>SKU Type</td>
                    <td>{selectedExport?.extra_attributes?.sku_type}</td>
                  </tr>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <td>Pengiriman</td>
                    <td>{selectedExport?.extra_attributes?.shipping_provider}</td>
                  </tr>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <td>Label</td>
                    <td>{statusLabel(selectedExport?.extra_attributes?.status_label)}</td>
                  </tr>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <td>OrderID</td>
                    <td>{selectedExport?.extra_attributes?.search}</td>
                  </tr>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <td>Free Item</td>
                    <td>{selectedExport?.extra_attributes?.free_item}</td>
                  </tr>
                </thead>
              </table>
              <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                <thead>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <th>No</th>
                    <th>Produk</th>
                    <th>Total QTY</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedExport?.summary?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {item?.is_free === true ? `FREE ${item.product_name}` : item.product_name}
                        </td>
                        <td>{item.total_qty}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

const ShippingDocExportScreenWrapper = () => {
  return (
    <>
      <ShippingDocExportScreen />
    </>
  )
}

export default ShippingDocExportScreenWrapper
