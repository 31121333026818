import {useEffect, useState} from 'react'
import {useTrxStockReportStore} from '../states/trx-stock-report.state'
import {KTCard} from '../../../../../_metronic/helpers'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import clsx from 'clsx'
import {MdDeleteForever} from 'react-icons/md'

const TrxStockReportScreen = () => {
  const [currentPage, setCurrentPage] = useState<number>(0)

  const {
    getTrxStockReports,
    trxStockReportItems,
    trxStockReportLoading,
    trxStockReportModel,
    onDelete,
  } = useTrxStockReportStore()

  useEffect(() => {
    getTrxStockReports({
      page: 0,
      sort: '-created_at_unix',
    })
  }, [])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    setCurrentPage(e.selected)
    getTrxStockReports({
      page: selectedPage,
      sort: '-created_at_unix',
    })
  }

  return (
    <>
      <KTCard>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {trxStockReportLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>No</th>
                  <th>Keterangan</th>
                  <th>Report</th>
                  <th>Jenis File</th>
                  <th>Dibuat</th>
                  <th>File</th>
                  <th>Action</th>
                  {/* <th className='text-end'>Action</th> */}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {trxStockReportItems?.map((item, index) => {
                  const overallIndex = currentPage * 10 + index + 1
                  return (
                    <tr key={index}>
                      <td>{overallIndex}</td>
                      <td>
                        <span>
                          Tanggal {moment(+item?.start_time_unix * 1000).format('DD/MM/YYYY HH:mm')}{' '}
                          - {moment(+item?.end_time_unix * 1000).format('DD/MM/YYYY HH:mm')}
                        </span>
                      </td>
                      <td>
                        <span
                          className={clsx(
                            item?.trx_stock_type === 'out'
                              ? 'badge badge-danger'
                              : 'badge badge-primary'
                          )}
                        >
                          {item?.trx_stock_type === 'in' ? 'Barang Masuk' : 'Barang Keluar'}
                        </span>
                      </td>
                      <td>
                        <span
                          className={clsx(
                            'badge',
                            item?.file_type === 'pdf' ? 'badge-danger' : 'badge-success'
                          )}
                        >
                          {item?.file_type === 'pdf' ? 'PDF' : 'Excel'}
                        </span>
                      </td>
                      <td>
                        <span>{moment(item?.created_at).format('DD/MM/YYYY HH:mm:ss')}</span>
                      </td>
                      <td>
                        <span>
                          <a
                            href={`#`}
                            onClick={(e) => {
                              e.preventDefault()
                              // SettlementDownload(item?.id)
                              window.open(
                                `${process.env.REACT_APP_ASSETS_URL}/${item.asset}`,
                                '_blank'
                              )
                            }}
                          >
                            Download File
                          </a>
                        </span>
                      </td>
                      <td>
                        <button
                          className='btn btn-danger'
                          onClick={(e) => {
                            e.preventDefault()
                            onDelete(item.id, {
                              page: 0,
                              sort: '-created_at_unix',
                            })
                          }}
                        >
                          <MdDeleteForever size={18} />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='card-footer'>
          <ReactPaginate
            previousLabel={'«'}
            nextLabel={'»'}
            breakLabel={'...'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            pageCount={trxStockReportModel?.data?.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={'pagination pagination-sm m-0 float-right'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </div>
      </KTCard>
    </>
  )
}

const TrxStockReportScreenWrapper = () => {
  return (
    <>
      <TrxStockReportScreen />
    </>
  )
}

export default TrxStockReportScreenWrapper
