import {create} from 'zustand'
import {
  StatementTransactionDetailItem,
  TransactionStatementDetailResultInterface,
} from '../interfaces/transaction-detail-result.interface'
import {
  GetTransactionDetails,
  TransactionDetailParam,
} from '../providers/transaction-detail.provider'

interface TransactionDetailState {
  transactionDetailLoading: boolean
  transactionDetailModel: TransactionStatementDetailResultInterface
  transactionDetailItems: StatementTransactionDetailItem[]
  getTransactionDetails: (params: TransactionDetailParam) => void
}

export const useTransactionDetailStore = create<TransactionDetailState>((set, get) => ({
  transactionDetailLoading: false,
  transactionDetailModel: undefined,
  transactionDetailItems: [],
  getTransactionDetails: async (params: TransactionDetailParam) => {
    set({
      transactionDetailLoading: true,
      transactionDetailModel: undefined,
      transactionDetailItems: [],
    })
    const response = await GetTransactionDetails(params)
    set({
      transactionDetailModel: response,
    })
    if (response.status === true) {
      if (response?.data?.items?.length > 0) {
        set({transactionDetailItems: response?.data?.items})
      }
    }
    set({
      transactionDetailLoading: false,
    })
  },
}))
