import {useEffect, useRef, useState} from 'react'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import ReactPaginate from 'react-paginate'
import {KTCard, KTSVG} from '../../../../../_metronic/helpers'
import moment from 'moment'
import {useTrxStocksInStore, useTrxStocksStore} from '../states/trx-stocks.state'
import {FormAction} from '../../../../helpers/form-action-interfaces'
import TrxStocksInModalComponent from '../components/trx-stocks-in-modal.component'
import TrxStockDetailModalComponent, {
  TrxDeliveryAssetModalComponent,
} from '../components/trx-stock-detail-modal.component'
import Flatpickr from 'react-flatpickr'
import {useTrxStockReportStore} from '../states/trx-stock-report.state'
import {toast} from 'react-toastify'
import {MdDeleteForever} from 'react-icons/md'

const TrxStocksInComponent = () => {
  const {getTrxStocks, trxStocks, trxStocksLoading, trxStocksModel, setFilters, onDeleteTrxStock} =
    useTrxStocksStore()
  const {setField, field, generateForms, submitDone, formLoading, reset} = useTrxStocksInStore()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const flatpickrRef = useRef<Flatpickr>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [startTime, setStartTime] = useState<Date>(null)
  const [endTime, setEndTime] = useState<Date>(null)
  const {getGenerateReportExcel, getGenerateReportPdf, generateExportLoading} =
    useTrxStockReportStore()

  useEffect(() => {
    getTrxStocks({
      page: 0,
      size: 25,
      filters: `["trx_stock_type", "in"]`,
      sort: '-action_date',
    })
  }, [])

  useEffect(() => {
    if (submitDone === true && formLoading === false) {
      getTrxStocks({page: 0, size: 25, filters: `["trx_stock_type", "in"]`, sort: '-action_date'})
      reset()
    }
  }, [submitDone, formLoading])

  const handlePageClick = (e: any) => {
    const sDate = moment(startTime).format('YYYY-MM-DD HH:mm:00')
    const eDate = moment(endTime).format('YYYY-MM-DD HH:mm:00')

    const filters =
      startTime && endTime
        ? `[["trx_stock_type", "in"], ["and"], ["action_date", "between", ["${sDate}", "${eDate}"]]]`
        : `["trx_stock_type", "in"]`

    const selectedPage = e.selected
    setCurrentPage(e.selected)
    getTrxStocks({
      page: selectedPage,
      size: 25,
      filters: filters,
      sort: '-action_date',
    })
  }

  const filterData = (e: any) => {
    setCurrentPage(0)
    const sDate = moment(startTime).format('YYYY-MM-DD HH:mm:00')
    const eDate = moment(endTime).format('YYYY-MM-DD HH:mm:00')
    getTrxStocks({
      page: 0,
      size: 25,
      filters: `[["trx_stock_type", "in"], ["and"], ["action_date", "between", ["${sDate}", "${eDate}"]]]`,
      sort: '-action_date',
    })
  }

  const resetData = () => {
    setStartTime(null)
    setEndTime(null)
    setFilters(null)
    getTrxStocks({
      page: 0,
      size: 25,
      filters: `["trx_stock_type", "in"]`,
      sort: '-action_date',
    })
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [detailId, setDetailId] = useState<string>('')
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false)
  const [photoDetailId, setPhotoDetailId] = useState<string>('')

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openPhotoModal = () => {
    setIsPhotoModalOpen(true)
  }

  const closePhotoModal = () => {
    setIsPhotoModalOpen(false)
  }

  return (
    <>
      <KTCard className='p-3 mb-5'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-4'>
              <label className={`form-label`}>Date Range</label>
              <Flatpickr
                ref={flatpickrRef}
                value={[startTime, endTime]}
                onChange={([date1, date2]) => {
                  setStartTime(date1)
                  setEndTime(date2)
                }}
                options={{
                  mode: 'range',
                  enableTime: true,
                  altInput: true,
                }}
                className='form-control'
                placeholder='Pick date'
              />
            </div>
          </div>
        </div>
        <div className='card-footer'>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={generateExportLoading}
              onClick={(e) => {
                e.preventDefault()
                if (
                  isNaN(Date.parse(startTime?.toString()) / 1000) &&
                  isNaN(Date.parse(endTime?.toString()) / 1000)
                ) {
                  toast.error('Please pick date range')
                  return
                }
                getGenerateReportExcel({
                  trx_stock_type: 'in',
                  start_time: isNaN(Date.parse(startTime?.toString()) / 1000)
                    ? undefined
                    : Date.parse(startTime?.toString()) / 1000,
                  end_time: isNaN(Date.parse(endTime?.toString()) / 1000)
                    ? undefined
                    : Date.parse(endTime?.toString()) / 1000,
                })
              }}
              className='btn btn-success btn-active-success-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Export Excel
            </button>
            <button
              type='button'
              disabled={generateExportLoading}
              onClick={(e) => {
                e.preventDefault()
                if (
                  isNaN(Date.parse(startTime?.toString()) / 1000) &&
                  isNaN(Date.parse(endTime?.toString()) / 1000)
                ) {
                  toast.error('Please pick date range')
                  return
                }
                getGenerateReportPdf({
                  trx_stock_type: 'in',
                  start_time: isNaN(Date.parse(startTime?.toString()) / 1000)
                    ? undefined
                    : Date.parse(startTime?.toString()) / 1000,
                  end_time: isNaN(Date.parse(endTime?.toString()) / 1000)
                    ? undefined
                    : Date.parse(endTime?.toString()) / 1000,
                })
              }}
              className='btn btn-danger btn-active-danger-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Export PDF
            </button>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
        </div>
      </KTCard>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Warehouse</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Barang Masuk</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                setField(null)
                generateForms({
                  title: 'Add Barang Masuk',
                  subtitle: 'Add Barang Masuk',
                  action: FormAction.CREATE,
                })
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Add Barang Masuk
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {trxStocksLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>No</th>
                  <th>Kode Transaksi</th>
                  <th>Nama Produk</th>
                  <th>Total Produk</th>
                  <th>Total Qty</th>
                  <th>Tanggal</th>
                  <th>Detail</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {trxStocks?.map((item, index) => {
                  const overallIndex = currentPage * 25 + index + 1
                  let totalQtyEachTrx = 0
                  for (const detail of item?.trx_stock_details) {
                    totalQtyEachTrx += detail?.quantity
                  }
                  return (
                    <tr key={index}>
                      <td>{overallIndex}</td>
                      <td>{item?.trx_code}</td>
                      <td>{item?.trx_stock_details[0]?.product_code?.product_name}</td>
                      <td>{item?.trx_stock_details.length}</td>
                      <td>{totalQtyEachTrx}</td>
                      <td>{moment(item?.action_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                      <td>
                        <button
                          className='btn btn-success'
                          onClick={(e) => {
                            e.preventDefault()
                            openModal()
                            setDetailId(item?.id)
                          }}
                        >
                          Detail
                        </button>
                        <button
                          className='btn btn-success ms-1'
                          onClick={(e) => {
                            e.preventDefault()
                            openPhotoModal()
                            setPhotoDetailId(item?.id)
                          }}
                        >
                          Surat Jalan
                        </button>
                        <button
                          className='btn btn-danger ms-1'
                          onClick={(e) => {
                            e.preventDefault()
                            setField(item)
                            console.log(item)
                            generateForms({
                              title: 'Update Barang Masuk',
                              subtitle: 'Update Barang Masuk',
                              action: FormAction.UPDATE,
                              data: item,
                              id: item.id,
                            })
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className='btn btn-danger ms-1'
                          onClick={(e) => {
                            e.preventDefault()
                            onDeleteTrxStock(item.id, {
                              page: 0,
                              size: 25,
                              filters: `["trx_stock_type", "in"]`,
                              sort: '-action_date',
                            })
                          }}
                        >
                          <MdDeleteForever size={18} />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={trxStocksModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
      {field !== undefined && <TrxStocksInModalComponent />}
      <TrxStockDetailModalComponent isOpen={isModalOpen} onClose={closeModal} id={detailId} />
      <TrxDeliveryAssetModalComponent
        isOpen={isPhotoModalOpen}
        onClose={closePhotoModal}
        id={photoDetailId}
      />
    </>
  )
}

const TrxStocksInWrapper = () => {
  return (
    <>
      <TrxStocksInComponent />
    </>
  )
}

export default TrxStocksInWrapper
