import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {ProductResultInterface} from '../interfaces/product-result.interface'
import {
  ProductCodesResult,
  ProductCodesSingleResult,
} from '../interfaces/product-codes-result.interface'
import {GeneralResponse} from '../../../../helpers/general-response'

export interface GetAllProductParam {
  page?: number
  size?: number
  is_stock?: boolean
  is_free?: boolean
}

export interface ProductCodeFormProps {
  product_name?: string
  product_code?: string
  marketplace?: number
}

export const GetAllProduct = async (
  params: GetAllProductParam
): Promise<ProductResultInterface> => {
  try {
    const response = await Api.get('/products', {params: params})
    return response.data as ProductResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ProductResultInterface = a.response?.data
    return response
  }
}

export const SubmitProductCode = async (form: any): Promise<ProductCodesSingleResult> => {
  try {
    const response = await Api.post('/product-codes', form)
    return response.data as ProductCodesSingleResult
  } catch (e) {
    const a = e as AxiosError
    const response: ProductCodesSingleResult = a.response?.data
    return response
  }
}

export const UpdateProductCode = async (
  id: number,
  form: any
): Promise<ProductCodesSingleResult> => {
  try {
    const response = await Api.put('/product-codes/' + id, form)
    return response.data as ProductCodesSingleResult
  } catch (e) {
    const a = e as AxiosError
    const response: ProductCodesSingleResult = a.response?.data
    return response
  }
}

export const DeleteProductCode = async (id: number): Promise<GeneralResponse> => {
  try {
    const response = await Api.delete('/product-codes/' + id)
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}

export const GetProductCodes = async (params: GetAllProductParam): Promise<ProductCodesResult> => {
  try {
    const response = await Api.get('/product-codes', {params: params})
    return response.data as ProductCodesResult
  } catch (e) {
    const a = e as AxiosError
    const response: ProductCodesResult = a.response?.data
    return response
  }
}

export const RefreshProduct = async (
  params: GetAllProductParam
): Promise<ProductResultInterface> => {
  try {
    const response = await Api.get('/products-feed', {params: params})
    return response.data as ProductResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ProductResultInterface = a.response?.data
    return response
  }
}
