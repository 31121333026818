import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {OrderResultInterface} from '../interfaces/order-result.interface'
import {OrderSingleResultInterface} from '../interfaces/order-single-result.interface'
import {ShippingDocumentResult} from '../interfaces/shipping-doc-result.interface'
import {ShippingDocResultCountInterface} from '../../shipping-docs/interfaces/shipping-doc-result.interface'
import {GeneralResponse} from '../../../../helpers/general-response'
import {StatementExportResult} from '../../settlements/interfaces/settlement-export-result.interface'
import {OrderShopeeResultInterface} from '../interfaces/order-shopee-result.interface'

export interface GetAllOrderParam {
  page?: number
  size?: number
  filters?: string
  sort?: string
  order_id?: string
  start_date?: number
  end_date?: number
  start_date_create?: number
  end_date_create?: number
  status?: string
}

export const GetAllOrder = async (params: GetAllOrderParam): Promise<OrderResultInterface> => {
  try {
    const response = await Api.get('/orders-mongo', {params: params})
    return response.data as OrderResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: OrderResultInterface = a.response?.data
    return response
  }
}

export const GetAllOrderShopee = async (
  params: GetAllOrderParam
): Promise<OrderShopeeResultInterface> => {
  try {
    const response = await Api.get('/orders-shopee-mongo', {params: params})
    return response.data as OrderShopeeResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: OrderShopeeResultInterface = a.response?.data
    return response
  }
}

export const ShowOrder = async (id: string): Promise<OrderSingleResultInterface> => {
  try {
    const response = await Api.get('/orders-mongo/' + id)
    return response.data as OrderSingleResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: OrderSingleResultInterface = a.response?.data
    return response
  }
}

export const GetShippingDocument = async (id: string): Promise<ShippingDocumentResult> => {
  try {
    const response = await Api.get('/shipping-doc/' + id)
    return response.data as ShippingDocumentResult
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocumentResult = a.response?.data
    return response
  }
}

export const RefreshOrder = async (params: GetAllOrderParam): Promise<OrderResultInterface> => {
  try {
    const response = await Api.get('/orders-feed', {params: params})
    return response.data as OrderResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: OrderResultInterface = a.response?.data
    return response
  }
}

export const CountTotalDataOrder = async (
  params: GetAllOrderParam
): Promise<ShippingDocResultCountInterface> => {
  try {
    const response = await Api.get('/orders-mongo-total', {params: params})
    return response.data as ShippingDocResultCountInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocResultCountInterface = a.response?.data
    return response
  }
}

export const CountTotalDataOrderShopee = async (
  params: GetAllOrderParam
): Promise<ShippingDocResultCountInterface> => {
  try {
    const response = await Api.get('/orders-shopee-mongo-total', {params: params})
    return response.data as ShippingDocResultCountInterface
  } catch (e) {
    const a = e as AxiosError
    const response: ShippingDocResultCountInterface = a.response?.data
    return response
  }
}

export const GetGenerateOrderExport = async (
  params: GetAllOrderParam
): Promise<GeneralResponse> => {
  try {
    const response = await Api.get('/orders-mongo-report', {params: params})
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}

export const GetGenerateOrderExportShopee = async (
  params: GetAllOrderParam
): Promise<GeneralResponse> => {
  try {
    const response = await Api.get('/orders-mongo-report-shopee', {params: params})
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}

export const GetAllOrderExport = async (
  params: GetAllOrderParam
): Promise<StatementExportResult> => {
  try {
    const response = await Api.get('/order-exports', {params: params})
    return response.data as StatementExportResult
  } catch (e) {
    const a = e as AxiosError
    const response: StatementExportResult = a.response?.data
    return response
  }
}

export const GetAllOrderExportShopee = async (
  params: GetAllOrderParam
): Promise<StatementExportResult> => {
  try {
    const response = await Api.get('/order-exports-shopee', {params: params})
    return response.data as StatementExportResult
  } catch (e) {
    const a = e as AxiosError
    const response: StatementExportResult = a.response?.data
    return response
  }
}
