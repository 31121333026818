import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {TotalSalesResultInterface} from '../interfaces/total-sales-yesterday-result.interface'

export const GetTotalSalesYesterday = async (): Promise<TotalSalesResultInterface> => {
  try {
    let response = await Api.get('/dashboard-total-sales-yesterday')
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: TotalSalesResultInterface = a.response?.data
    return response
  }
}

export const GetTotalSalesToday = async (): Promise<TotalSalesResultInterface> => {
  try {
    let response = await Api.get('/dashboard-total-sales-today')
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: TotalSalesResultInterface = a.response?.data
    return response
  }
}
