import {create} from 'zustand'
import {
  GetTransactionsShopee,
  GetTransactionsWithdraw,
  TransactionParam,
} from '../providers/transaction.provider'
import {
  WalletTransactionItem,
  WalletTransactionResult,
} from '../interfaces/wallet-trx-result.interface'

interface TransactionShopeeState {
  transactionLoading: boolean
  transactionModel: WalletTransactionResult
  transactionItems: WalletTransactionItem[]
  getTransactions: (params: TransactionParam) => void

  transactionWithdraw: number
  getTransactionWithdraw: () => void
}

export const useTransactionShopeeStore = create<TransactionShopeeState>((set, get) => ({
  transactionLoading: false,
  transactionModel: null,
  transactionItems: [],
  getTransactions: async (params: TransactionParam) => {
    set({
      transactionLoading: true,
      transactionModel: null,
    })
    const response = await GetTransactionsShopee(params)
    if (response?.status) {
      if (response.data?.items?.length > 0) {
        set({transactionItems: response.data?.items})
      }
    }
    set({
      transactionModel: response,
      transactionLoading: false,
    })
  },

  transactionWithdraw: 0,
  getTransactionWithdraw: async () => {
    const response = await GetTransactionsShopee({sort: '-create_time'})
    if (response.data?.items?.length > 0) {
      set({transactionWithdraw: response?.data?.items[0]?.current_balance ?? 0})
    } else {
      set({transactionWithdraw: 0})
    }
  },
}))
