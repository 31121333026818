import {create} from 'zustand'
import {
  TrxCardboardsData,
  TrxCardboardsResultInterface,
} from '../interfaces/trx-cardboards-result.interface'
import {
  GetAllTrxCardboards,
  GetAllTrxCardboardsParam,
  GetTrxCardboard,
} from '../providers/trx-cardboards.provider'
import {TrxCardboardsSingleResultData} from '../interfaces/trx-cardboards-single-result.interface'

interface TrxCardboardsState {
  trxCardboardsLoading: boolean
  trxCardboards: TrxCardboardsData[]
  trxCardboardsModel: TrxCardboardsResultInterface
  getTrxCardboards: (params: GetAllTrxCardboardsParam) => void
  trxCardboardOneLoading: boolean
  trxCardboard: TrxCardboardsSingleResultData
  getTrxCardboardDetail: (id: string) => void
}

export const useTrxCardboardsStore = create<TrxCardboardsState>((set, get) => ({
  trxCardboardsLoading: false,
  trxCardboardOneLoading: false,
  trxCardboards: [],
  trxCardboard: undefined,
  trxCardboardsModel: null,
  getTrxCardboards: async (params: GetAllTrxCardboardsParam) => {
    set({trxCardboardsLoading: true, trxCardboards: []})
    const response = await GetAllTrxCardboards(params)
    set({trxCardboardsModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        set({trxCardboards: response.data.items})
      }
    }
    set({trxCardboardsLoading: false})
  },
  getTrxCardboardDetail: async (id: string) => {
    set({
      trxCardboardOneLoading: true,
      trxCardboard: null,
    })
    const response = await GetTrxCardboard(id)
    if (response.status === true) {
      set({trxCardboard: response.data})
    }
    set({
      trxCardboardOneLoading: false,
    })
  },
}))
