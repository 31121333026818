import {Moment} from 'moment'
import React from 'react'
import {Tag} from 'react-tag-input'
import {NumberFormatValues, SourceInfo} from 'react-number-format'

export interface FormProps {
  action?: FormAction
  id?: string
  title?: string
  subtitle?: string
  data?: any
  leagueId?: string
  eventId?: string
  programId?: string
  companyId?: string
  affiliate_code?: string
  referenceTable?: string
  pipelineId?: string
  reportType?: 'ebook' | 'video'
  contactApiId?: number
  studentApiId?: number
  index?: number
}

export interface SelectData {
  label?: string
  value?: any
}

export interface FormInput {
  id: string
  title: string
  name: string
  placeholder?: string
  required?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeSelect?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onChangeSelectWithText?: (e: SelectData) => void
  onInputChange?: (e: string) => void
  type:
    | 'text'
    | 'select'
    | 'radio'
    | 'checkbox'
    | 'password'
    | 'email'
    | 'toggle'
    | 'file-upload'
    | 'rich-text'
    | 'tags'
    | 'select-with-text'
    | 'async-select-with-text'
    | 'select-with-text-multi'
    | 'datetime'
    | 'datetime-blast'
    | 'time'
    | 'number'
    | 'file-upload-v2'
    | 'file-upload-video-v2'
    | 'file-upload-multi'
    | 'text-area'
    | 'date'
    | 'select-number'
    | 'currency'
    | 'multiple-text'
    | 'file-upload-multi-video'
    | 'code-editor'
    | 'image'
  value?: any
  selectData?: SelectData[]
  disabled?: boolean
  hasInputGroup?: boolean
  inputGroupAction?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  inputGroupTitle?: string
  hidden?: boolean
  isMulti?: boolean
  size?: number // using col size col-2, col-4, dst
  isImage?: boolean
  isVideo?: boolean

  // text-area
  onChangeTextArea?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void

  // for tags only
  tags?: Tag[]
  suggestions?: Tag[]
  handleDelete?: (i: number) => void
  handleAddition?: (tag: Tag) => void
  handleDrag?: (tag: Tag, currPos: number, newPos: number) => void

  // datetime
  onChangeDateTime?: (e: string | Moment) => void
  onChangeDate?: (date: Date, event: React.SyntheticEvent<any, Event>) => void

  // time
  onChangeTime?: (e: string) => void

  // currency
  onValueChange?: (values: NumberFormatValues, sourceInfo: SourceInfo) => void

  // file
  accept?: string

  // multiple text
  values?: string[]
  setValues?: (values: string[]) => void
  onChangeValues?: (values: string[]) => void

  // ace editor
  onChangeCodeEditor?: (value: string, event?: any) => void

  // limit file size
  limitFileSize?: number // in kb

  // async select
  loadOptions?: (inputValue: string, callback: (options: SelectData[]) => void) => void
}

export enum FormAction {
  CREATE = 'Create',
  UPDATE = 'Update',
  VIEW = 'View',
}
