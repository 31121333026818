import {useEffect, useRef, useState} from 'react'
import {KTCard, KTSVG} from '../../../../../_metronic/helpers'
import Flatpickr from 'react-flatpickr'
import {useShippingDocStore} from '../states/shipping-docs.state'
import {generateStatus} from '../../../../helpers/generate-status'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import {useShippingDocShopeeStore} from '../states/shipping-docs-shopee.state'

const shippingProviders = [
  {
    shipping_provider_id: 'SPX Hemat',
    shipping_provider_name: 'SPX Hemat',
  },
  {
    shipping_provider_id: 'SPX Standard',
    shipping_provider_name: 'SPX Standard',
  },
]

const ShippingDocShopeeScreen = () => {
  const [startTime, setStartTime] = useState<Date>()
  const [endTime, setEndTime] = useState<Date>()
  const [skuName, setSkuName] = useState<string>()
  const [qty, setQty] = useState<number>()
  const [courier, setCourier] = useState<string>()
  const [courierName, setCourierName] = useState<string>()
  const [orderLabel, setOrderLabel] = useState<string>()
  const [statusLabel, setStatusLabel] = useState<string>('UNPRINTED')
  const [search, setSearch] = useState<string>()
  const [orderType, setOrderType] = useState<string>()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [freeItem, setFreeItem] = useState<string>()
  const flatpickrRef = useRef<Flatpickr>(null)

  const {
    getShippingDocs,
    shippingDocLoading,
    shippingDocsModel,
    shippingDocsItems,
    getShippingDocSkus,
    shippingDocSkusItems,
    getGenerateResi,
    getShippingDocQty,
    shippingDocQtyItems,

    shippingDocSkuLoading,
    shippingDocQtyLoading,
    shippingDocFreeLoading,

    getShippingDocFree,
    shippingDocFreeItems,

    countShippingDocs,
    totalShippingDocs,
    loadingCountShippingDoc,
  } = useShippingDocShopeeStore()

  useEffect(() => {
    // startTime?.setHours(0, 0, 0, 0)
    // endTime?.setHours(23, 59, 59, 0)
    if (
      !isNaN(Math.floor(startTime?.getTime() / 1000)) &&
      !isNaN(Math.floor(endTime?.getTime() / 1000))
    ) {
      setSkuName('')
      setQty(0)
      getShippingDocSkus({
        start_time: Math.floor(startTime?.getTime() / 1000),
        end_time: Math.floor(endTime?.getTime() / 1000),
        status_label: statusLabel,
      })
    }
  }, [startTime, endTime])

  useEffect(() => {
    if (
      skuName &&
      !isNaN(Math.floor(startTime?.getTime() / 1000)) &&
      !isNaN(Math.floor(endTime?.getTime() / 1000))
    ) {
      setQty(0)
      getShippingDocQty({
        start_time: Math.floor(startTime?.getTime() / 1000),
        end_time: Math.floor(endTime?.getTime() / 1000),
        sku_name: skuName,
        status_label: statusLabel,
      })
    }
  }, [skuName])

  useEffect(() => {
    if (
      qty !== null &&
      qty !== undefined &&
      qty > 0 &&
      skuName &&
      !isNaN(Math.floor(startTime?.getTime() / 1000)) &&
      !isNaN(Math.floor(endTime?.getTime() / 1000))
    ) {
      setFreeItem('')
      getShippingDocFree({
        start_time: Math.floor(startTime?.getTime() / 1000),
        end_time: Math.floor(endTime?.getTime() / 1000),
        sku_name: skuName,
        qty: qty,
      })
    }
  }, [qty])

  useEffect(() => {
    if (
      !isNaN(Math.floor(startTime?.getTime() / 1000)) &&
      !isNaN(Math.floor(endTime?.getTime() / 1000)) &&
      statusLabel !== ''
    ) {
      setSkuName('')
      setQty(0)
      getShippingDocSkus({
        start_time: Math.floor(startTime?.getTime() / 1000),
        end_time: Math.floor(endTime?.getTime() / 1000),
        status_label: statusLabel,
      })
    }
  }, [statusLabel])

  const resetData = () => {
    setStartTime(null)
    setEndTime(null)
    setSkuName('')
    setQty(0)
    setOrderLabel('')
    setCourier('')
    setOrderLabel('')
    setStatusLabel('UNPRINTED')
    setSearch('')
    setOrderType('')
    setCourierName('')
    setFreeItem('')
  }

  const filterData = () => {
    setCurrentPage(0)
    getShippingDocs({
      start_time: isNaN(Math.floor(startTime?.getTime() / 1000))
        ? undefined
        : Math.floor(startTime?.getTime() / 1000),
      end_time: isNaN(Math.floor(endTime?.getTime() / 1000))
        ? undefined
        : Math.floor(endTime?.getTime() / 1000),
      sku_name: skuName,
      page: 0,
      qty: qty === 0 ? undefined : qty,
      shipping_provider_id: courier,
      shipping_provider: courierName,
      sku_type: orderLabel,
      status_label: statusLabel,
      search: search,
      order_type: orderType,
      free_item: freeItem,
    })

    countShippingDocs({
      start_time: isNaN(Math.floor(startTime?.getTime() / 1000))
        ? undefined
        : Math.floor(startTime?.getTime() / 1000),
      end_time: isNaN(Math.floor(endTime?.getTime() / 1000))
        ? undefined
        : Math.floor(endTime?.getTime() / 1000),
      sku_name: skuName,
      qty: qty === 0 ? undefined : qty,
      shipping_provider_id: courier,
      shipping_provider: courierName,
      sku_type: orderLabel,
      status_label: statusLabel,
      search: search,
      order_type: orderType,
      free_item: freeItem,
    })
  }

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1)
    getShippingDocs({
      start_time: isNaN(Math.floor(startTime?.getTime() / 1000))
        ? undefined
        : Math.floor(startTime?.getTime() / 1000),
      end_time: isNaN(Math.floor(endTime?.getTime() / 1000))
        ? undefined
        : Math.floor(endTime?.getTime() / 1000),
      sku_name: skuName,
      page: currentPage - 1,
      qty: qty === 0 ? undefined : qty,
      shipping_provider_id: courier,
      sku_type: orderLabel,
      status_label: statusLabel,
      search: search,
      order_type: orderType,
      shipping_provider: courierName,
    })
  }

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1)
    getShippingDocs({
      start_time: isNaN(Math.floor(startTime?.getTime() / 1000))
        ? undefined
        : Math.floor(startTime?.getTime() / 1000),
      end_time: isNaN(Math.floor(endTime?.getTime() / 1000))
        ? undefined
        : Math.floor(endTime?.getTime() / 1000),
      sku_name: skuName,
      page: currentPage + 1,
      qty: qty === 0 ? undefined : qty,
      shipping_provider_id: courier,
      sku_type: orderLabel,
      status_label: statusLabel,
      search: search,
      order_type: orderType,
      shipping_provider: courierName,
    })
  }

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    setCurrentPage(e.selected)
    getShippingDocs({
      start_time: isNaN(Math.floor(startTime?.getTime() / 1000))
        ? undefined
        : Math.floor(startTime?.getTime() / 1000),
      end_time: isNaN(Math.floor(endTime?.getTime() / 1000))
        ? undefined
        : Math.floor(endTime?.getTime() / 1000),
      sku_name: skuName,
      page: selectedPage,
      qty: qty === 0 ? undefined : qty,
      shipping_provider_id: courier,
      sku_type: orderLabel,
      status_label: statusLabel,
      search: search,
      order_type: orderType,
      shipping_provider: courierName,
    })
  }

  return (
    <>
      <KTCard className='p-3 mb-5'>
        <div className='card-body'>
          {shippingDocSkuLoading && <UsersListLoading />}
          {shippingDocQtyLoading && <UsersListLoading />}
          {shippingDocFreeLoading && <UsersListLoading />}
          <div className='row'>
            <div className='col-sm-4'>
              <label className={`form-label`}>{`Date Range (Waktu cetak resi Shopee)`}</label>
              <Flatpickr
                ref={flatpickrRef}
                value={[startTime, endTime]}
                onChange={([date1, date2]) => {
                  console.log(date1, date2)
                  setStartTime(date1)
                  setEndTime(date2)
                }}
                options={{
                  mode: 'range',
                  enableTime: true,
                  altInput: true,
                }}
                className='form-control'
                placeholder='Pick date'
              />
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>SKU:</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setSkuName(e.target.value)}
                value={skuName}
              >
                <option value=''></option>
                {shippingDocSkusItems?.map((item, index) => {
                  return (
                    <option key={index} value={item.sku_name}>
                      {item.sku_name}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Quantity</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setQty(+e.target.value)}
                value={qty}
              >
                <option value=''></option>
                {shippingDocQtyItems?.map((item, index) => {
                  return (
                    <option key={index} value={item.qty}>
                      {item.qty}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Free Item</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setFreeItem(e.target.value)}
                value={freeItem}
              >
                <option value=''></option>
                {shippingDocFreeItems?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.id}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Kurir:</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => {
                  setCourier(e.target.value)
                  const itemIndex = shippingProviders.findIndex(
                    (el) => el.shipping_provider_id === e.target.validationMessage
                  )
                  if (itemIndex > -1) {
                    setCourierName(shippingProviders[itemIndex].shipping_provider_name)
                  }
                }}
                value={courier}
              >
                <option value=''></option>
                {shippingProviders?.map((item, index) => {
                  return (
                    <option key={index} value={item.shipping_provider_id}>
                      {item.shipping_provider_name}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Label Pesanan:</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setOrderLabel(e.target.value)}
                value={orderLabel}
              >
                <option value=''></option>
                <option value='NORMAL'>Bukan Preorder</option>
                <option value='PRE_ORDER'>Preorder</option>
              </select>
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Status Label:</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setStatusLabel(e.target.value)}
                value={statusLabel}
              >
                <option value='UNPRINTED'>Belum Dicetak</option>
                <option value='PRINTED'>Dicetak</option>
              </select>
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Tipe Paket Pesanan:</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setOrderType(e.target.value)}
                value={orderType}
              >
                <option value=''></option>
                <option value='one_sku_in_one_order'>1 SKU dalam 1 paket pesanan</option>
                <option value='multiple_sku_in_one_order'>
                  Beberapa SKU dalam 1 paket pesanan
                </option>
              </select>
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Cari</label>
              <input
                onChange={(e) => setSearch(e.target.value)}
                placeholder='ID Pesanan, atau nama produk'
                value={search}
                className={'form-control'}
                type='text'
              />
            </div>
          </div>
        </div>
        <div className='card-footer'>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={shippingDocLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={shippingDocLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
        </div>
      </KTCard>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Resi ({loadingCountShippingDoc ? 'Loading...' : totalShippingDocs})
            </span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>User List</span> */}
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                let text = 'Apakah anda ingin generate resi?'
                if (window.confirm(text) === true) {
                  getGenerateResi({
                    start_time: isNaN(Math.floor(startTime?.getTime() / 1000))
                      ? undefined
                      : Math.floor(startTime?.getTime() / 1000),
                    end_time: isNaN(Math.floor(endTime?.getTime() / 1000))
                      ? undefined
                      : Math.floor(endTime?.getTime() / 1000),
                    sku_name: skuName,
                    qty: qty === 0 ? undefined : qty,
                    shipping_provider_id: courier,
                    shipping_provider: courierName,
                    sku_type: orderLabel,
                    status_label: statusLabel,
                    search: search,
                    order_type: orderType,
                    free_item: freeItem,
                  })
                }
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Generate Resi
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {shippingDocLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>No</th>
                  <th>Pesanan</th>
                  <th>Pembeli</th>
                  <th>Status Pesanan</th>
                  <th>Metode Pengiriman</th>
                  {/* <th className='text-end'>Action</th> */}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {shippingDocsItems?.map((item, index) => {
                  const overallIndex = currentPage * 25 + index + 1
                  return (
                    <tr key={index}>
                      <td>
                        <span>{overallIndex}</span>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex flex-column'>
                            <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                              <b>{item?.order_id}</b>
                            </a>
                            <span
                              style={{
                                fontSize: 10,
                              }}
                            >
                              {moment(item?.update_time * 1000).format('DD/MM/YYYY HH:mm:ss')}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span>{item?.buyer_username}</span>
                      </td>
                      <td>
                        <span>{generateStatus(item?.status)}</span>
                      </td>
                      <td>
                        <span>{item?.shipping_carrier}</span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='card-footer'>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button
              className='btn btn-primary fw-bold px-6 mx-6'
              onClick={(e) => {
                e.preventDefault()
                handlePrevClick()
              }}
            >
              « Prev
            </button>
            <button
              className='btn btn-primary fw-bold px-6'
              onClick={(e) => {
                e.preventDefault()
                handleNextClick()
              }}
            >
              Next »
            </button>
          </div>
          {/* <ReactPaginate
            previousLabel={'«'}
            nextLabel={'»'}
            breakLabel={'...'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            pageCount={shippingDocsModel?.data?.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={'pagination pagination-sm m-0 float-right'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            activeClassName={'active'}
          /> */}
        </div>
      </KTCard>
    </>
  )
}

const ShippingDocShopeeScreenWrapper = () => {
  return (
    <>
      <ShippingDocShopeeScreen />
    </>
  )
}

export default ShippingDocShopeeScreenWrapper
