import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {VendorsResultInterface} from '../interfaces/vendors-result.interface'
import {GeneralResponse} from '../../../../helpers/general-response'

export interface GetAllVendorsParam {
  page?: number
  size?: number
  sort?: string
}

export const GetAllVendors = async (
  params: GetAllVendorsParam
): Promise<VendorsResultInterface> => {
  try {
    const response = await Api.get('/vendors', {params: params})

    return response.data as VendorsResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: VendorsResultInterface = a.response.data
    return response
  }
}

export const SubmitVendor = async (form: any): Promise<VendorsResultInterface> => {
  try {
    const response = await Api.post('/vendors', form)
    return response.data as VendorsResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: VendorsResultInterface = a.response?.data
    return response
  }
}

export const UpdateVendor = async (id: string, form: any): Promise<VendorsResultInterface> => {
  try {
    const response = await Api.put('/vendors/' + id, form)
    return response.data as VendorsResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: VendorsResultInterface = a.response?.data
    return response
  }
}

export const DeleteVendor = async (id: string): Promise<GeneralResponse> => {
  try {
    const response = await Api.delete('/vendors/' + id)
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}
