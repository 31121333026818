import {create} from 'zustand'
import {
  TrxStockDetail,
  TrxStocksData,
  TrxStocksResultInterface,
} from '../interfaces/trx-stocks-result.interface'
import {
  DeleteTrxStock,
  GetAllTrxStocks,
  GetAllTrxStocksParam,
  GetTrxStock,
  SubmitTrxStocksIn,
  SubmitTrxStocksOut,
  UpdateTrxStocksIn,
} from '../providers/trx-stocks.provider'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../helpers/form-action-interfaces'
import {toast} from 'react-toastify'
import {generateFormLogic} from '../../../../helpers/generate-form-logic'
import moment from 'moment'
import {TrxStocksSingleResultData} from '../interfaces/trx-stocks-single-result.interface'
import {GetProductBundle} from '../providers/product-bundle.provider'
import {dateTimeISOFormatter} from '../../../../helpers/datetime-formatter'

interface TrxStocksState {
  trxStocksLoading: boolean
  trxStocks: TrxStocksData[]
  trxStocksModel: TrxStocksResultInterface
  getTrxStocks: (params: GetAllTrxStocksParam) => void
  trxStockOneLoading: boolean
  trxStock: TrxStocksSingleResultData
  getTrxStockDetail: (id: string) => void
  filters: string
  setFilters: (filter: string) => void
  onDeleteTrxStock: (id: string, params: GetAllTrxStocksParam) => Promise<void>
}

export const useTrxStocksStore = create<TrxStocksState>((set, get) => ({
  trxStocksLoading: false,
  trxStockOneLoading: false,
  trxStocks: [],
  trxStock: undefined,
  trxStocksModel: null,
  filters: '',
  setFilters: (value: string) => {
    set({filters: value})
  },
  getTrxStocks: async (params: GetAllTrxStocksParam) => {
    set({trxStocksLoading: true, trxStocks: []})
    const response = await GetAllTrxStocks(params)
    set({trxStocksModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        set({trxStocks: response.data.items})
      }
    }
    set({trxStocksLoading: false})
  },
  getTrxStockDetail: async (id: string) => {
    set({
      trxStockOneLoading: true,
      trxStock: null,
    })
    const response = await GetTrxStock(id)
    if (response.status === true) {
      set({trxStock: response.data})
    }
    set({
      trxStockOneLoading: false,
    })
  },
  onDeleteTrxStock: async (id: string, params: GetAllTrxStocksParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({trxStocksLoading: true})
      const response = await DeleteTrxStock(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getTrxStocks(params)
      set({trxStocksLoading: false})
    }
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
  productForms: [],
  freeProductForms: [],
  products: [],
}

export interface TrxStocksFormState {
  //temp
  field?: any
  setField?: (item: TrxStockDetail) => void
  productForms?: FormInput[][]
  freeProductForms?: FormInput[][]
  setProductForms?: (productBundleId: string, selectData: SelectData[]) => void
  initProductForms?: (products: SelectData[]) => void
  setFreeProductForms?: (productBundleId: string, selectData: SelectData[]) => void
  initFreeProductForms?: (products: SelectData[]) => void
  addProductForms?: (products: SelectData[]) => void
  addFreeProductForms?: (products: SelectData[]) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  setUpdateProductForms?: (indexField: number, index: number, value: any) => void
  setUpdateFreeProductForms?: (indexField: number, index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
  products?: any
  setProductDetails?: (productIndex: number, productId: number, quantity: number) => void
  changeRequired?: (index: number, required: boolean) => void
}

export const useTrxStocksInStore = create<TrxStocksFormState>((set, get) => ({
  field: undefined,
  products: [],
  setField: (item: TrxStockDetail) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  setProductDetails: (productIndex, productId, quantity) => {
    const products = [...get().products]
    products[productIndex] = {productId, quantity}
    set({products})
  },
  freeProductForms: [],
  initFreeProductForms: (products: SelectData[]) => {
    const freeProductForm: FormInput[] = [
      {
        id: `free_product_code_0`,
        title: 'Free Product',
        placeholder: 'Free Product',
        type: 'select-with-text',
        value: {},
        selectData: products,
        name: `free_product_code_0`,
        size: 6,
      },
      {
        id: `free_qty_0`,
        title: 'Qty',
        placeholder: '0',
        type: 'number',
        value: '',
        name: `free_qty_0`,
        size: 6,
      },
    ]

    const freeProductForms = [...get().freeProductForms]
    freeProductForms.push(freeProductForm)
    set({freeProductForms})
  },

  setUpdateFreeProductForms: (indexField, index, value) => {
    const freeProductForms = [...get().freeProductForms]
    freeProductForms[indexField][index] = {
      ...freeProductForms[indexField][index],
      value: value,
    }
    set({freeProductForms})
  },
  productForms: [],
  addProductForms: (products: SelectData[]) => {
    const productFormFromBundle: FormInput[] = []
    productFormFromBundle.push({
      id: `product_code_0`,
      title: 'Product',
      placeholder: 'Product',
      type: 'select-with-text',
      value: {},
      selectData: products,
      name: `product_code_0`,
      size: 6,
    })
    productFormFromBundle.push({
      id: `qty_0`,
      title: 'Qty',
      placeholder: '0',
      type: 'number',
      value: '',
      name: `qty_0`,
      size: 6,
    })
    const productFormsReal = [...get().productForms]
    productFormsReal.push(productFormFromBundle)
    set({productForms: productFormsReal})
  },
  initProductForms: (products: SelectData[]) => {
    set({productForms: []})
    const productFormFromBundle: FormInput[] = []
    productFormFromBundle.push({
      id: `product_code_0`,
      title: 'Product',
      placeholder: 'Product',
      type: 'select-with-text',
      value: {},
      selectData: products,
      name: `product_code_0`,
      size: 6,
    })
    productFormFromBundle.push({
      id: `qty_0`,
      title: 'Qty',
      placeholder: '0',
      type: 'number',
      value: '',
      name: `qty_0`,
      size: 6,
    })
    const productFormsReal = [...get().productForms]
    productFormsReal.push(productFormFromBundle)
    set({productForms: productFormsReal})
  },
  setUpdateProductForms: (indexField, index, value) => {
    const productForms = [...get().productForms]
    productForms[indexField][index] = {
      ...productForms[indexField][index],
      value: value,
    }
    set({productForms: productForms})
  },
  setProductForms: async (productBundleId: string, productCodes: SelectData[]) => {
    if (!productBundleId || productBundleId === undefined) {
      set({productForms: []})
      return
    }

    const productbundle = await GetProductBundle(productBundleId)
    // const productForms = [...get().productForms]
    const productForms = []

    if (productbundle.data?.product_codes?.length > 0) {
      for (const product of productbundle.data?.product_codes) {
        const productFormFromBundle: FormInput[] = []
        productFormFromBundle.push({
          id: `product_code_${product.id}`,
          title: 'Product',
          placeholder: 'Product',
          type: 'select-with-text',
          value: {
            label: product.product_name,
            value: product.id,
          },
          selectData: productCodes,
          name: `product_code_${product.id}`,
          size: 6,
        })
        productFormFromBundle.push({
          id: `qty_${product.id}`,
          title: 'Qty',
          placeholder: '0',
          type: 'number',
          value: '',
          name: `qty_${product.id}`,
          size: 6,
        })
        productForms.unshift(productFormFromBundle)
      }
    }
    set({productForms: productForms})
  },
  changeRequired: (index, required) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      required: required,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    const field = get().field as TrxStocksData
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'vendor_id',
        title: 'Nama Vendor',
        placeholder: 'Nama Vendor...',
        type: 'select-with-text',
        name: 'vendor_id',
        value: {
          label: field?.vendor?.vendor_name ?? 'Nama Vendor...',
          value: field?.vendor_id ?? '',
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      // {
      //   id: 'product_code_id',
      //   title: 'Nama Produk',
      //   placeholder: 'Nama Produk...',
      //   type: 'select-with-text',
      //   name: 'product_code_id',
      //   // value: +get().field?.product_code_id ?? '',
      //   value: {
      //     label: field?.trx_stock_details[0]?.product_code?.product_name ?? 'Nama Produk...',
      //     value: field?.trx_stock_details[0]?.product_code?.id ?? '',
      //   },
      //   disabled: formParam.action === FormAction.VIEW ? true : false,
      //   required: true,
      // },
      // {
      //   id: 'qty',
      //   title: 'Quantity',
      //   placeholder: 'Quantity...',
      //   type: 'number',
      //   name: 'qty',
      //   // value: get().field?.qty ?? '',
      //   value: field?.trx_stock_details[0]?.quantity ?? '',
      //   disabled: formParam.action === FormAction.VIEW ? true : false,
      //   required: true,
      // },
      {
        id: 'action_date',
        title: 'Tanggal',
        placeholder: 'Tanggal...',
        type: 'datetime',
        name: 'action_date',
        value: field?.action_date ?? new Date(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'acknowledge_by_id',
        title: 'Penerima',
        placeholder: 'Penerima...',
        type: 'select-with-text',
        name: 'acknowledge_by_id',
        // value: get().field?.acknowledge_by_id ?? '',
        value: {
          label: field?.acknowledge_user ?? 'Penerima...',
          value: field?.acknowledge_by_id ?? '',
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'delivery_order_asset',
        title: 'Upload Surat Jalan',
        placeholder: 'Upload Surat Jalan...',
        type: 'file-upload-v2',
        name: 'delivery_order_asset',
        value: field?.delivery_order_asset,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]

    console.log('zxczxc', field)
    if (field?.trx_stock_details?.length > 0) {
      const productForms = []
      const freeProductForms = []
      // get non free products
      for (const detail of field?.trx_stock_details) {
        const productFormDef: FormInput[] = []
        productFormDef.push({
          id: `product_code_${detail?.product_code_id}`,
          title: 'Product',
          placeholder: 'Product',
          type: 'select-with-text',
          value: {
            label: detail?.product_code?.product_name,
            value: detail?.product_code_id,
          },
          selectData: [],
          name: `product_code_${detail?.product_code_id}`,
          size: 6,
        })
        productFormDef.push({
          id: `qty_${detail?.product_code_id}`,
          title: 'Qty',
          placeholder: '0',
          type: 'number',
          value: detail?.quantity ?? 0,
          name: `qty_${detail?.product_code_id}`,
          size: 6,
        })
        if (!detail?.is_free) {
          productForms.unshift(productFormDef)
        } else {
          freeProductForms.unshift(productFormDef)
        }
      }
      console.log(productForms)
      set({productForms: productForms, freeProductForms: freeProductForms})
    }

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    const {forms, productForms, freeProductForms} = get()
    const actionDate = forms.find((item) => item.name === 'action_date')?.value ?? ''
    const acknowledgeById = forms.find((item) => item.name === 'acknowledge_by_id')?.value ?? ''
    const vendorId = forms.find((item) => item.name === 'vendor_id')?.value ?? ''
    const deliveryOrderAsset =
      forms.find((item) => item.name === 'delivery_order_asset')?.value ?? ''

    console.log(productForms)
    const products: any[] = []
    for (const productForm of productForms) {
      // console.log(productForm)
      if (productForm[0]?.value?.value !== undefined && +productForm[1]?.value === 0) {
        toast.error(`Mohon isi kuantiti ${productForm[0]?.value?.label}`)
        return
      }
      if (productForm[0]?.value?.value !== undefined && +productForm[1]?.value > 0) {
        products.push({
          product_code_id: productForm[0]?.value?.value,
          qty: +productForm[1]?.value,
          is_free: false,
        })
      }
    }

    for (const freeProductForm of freeProductForms) {
      if (freeProductForm[0]?.value?.value !== undefined && +freeProductForm[1]?.value === 0) {
        toast.error(`Mohon isi kuantiti ${freeProductForm[0]?.value?.label}`)
        return
      }
      if (freeProductForm[0]?.value?.value !== undefined && +freeProductForm[1]?.value > 0) {
        products.push({
          product_code_id: freeProductForm[0]?.value?.value,
          qty: +freeProductForm[1]?.value,
          is_free: true,
        })
      }
    }

    const form = {
      products: products,
      action_date: moment(actionDate).format('YYYY-MM-DD HH:mm:ss'),
      acknowledge_by_id: acknowledgeById?.value,
      vendor_id: vendorId?.value,
      delivery_order_asset: deliveryOrderAsset,
      trx_stock_type: 'in',
    }

    let res: TrxStocksResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitTrxStocksIn(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateTrxStocksIn(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))

export const useTrxStocksOutStore = create<TrxStocksFormState>((set, get) => ({
  field: undefined,
  products: [],
  setField: (item: TrxStockDetail) => {
    set({field: item, products: [], productForms: [], freeProductForms: []})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  setProductDetails: (productIndex, productId, quantity) => {
    const products = [...get().products]
    products[productIndex] = {productId, quantity}
    set({products})
  },
  freeProductForms: [],
  // initFreeProductForms: (products: SelectData[]) => {
  //   set({freeProductForms: []})
  //   const freeProductFormBundle: FormInput[] = [
  //     {
  //       id: `free_product_code_0`,
  //       title: 'Free Product',
  //       placeholder: 'Free Product',
  //       type: 'select-with-text',
  //       value: {},
  //       selectData: products,
  //       name: `free_product_code_0`,
  //       size: 6,
  //     },
  //     {
  //       id: `free_qty_0`,
  //       title: 'Qty',
  //       placeholder: '0',
  //       type: 'number',
  //       value: '',
  //       name: `free_qty_0`,
  //       size: 6,
  //     },
  //   ]

  //   const freeProductFormsReal = [...get().freeProductForms]
  //   freeProductFormsReal.push(freeProductFormBundle)
  //   set({freeProductForms: freeProductFormsReal})
  // },
  initFreeProductForms: (products: SelectData[]) => {
    set({freeProductForms: []})
    const freeProductFormFromBundle: FormInput[] = []
    freeProductFormFromBundle.push({
      id: `product_code_0`,
      title: 'Free Product',
      placeholder: 'Free Product',
      type: 'select-with-text',
      value: {},
      selectData: products,
      name: `product_code_0`,
      size: 6,
    })
    freeProductFormFromBundle.push({
      id: `qty_0`,
      title: 'Qty',
      placeholder: '0',
      type: 'number',
      value: '',
      name: `qty_0`,
      size: 6,
    })
    const freeProductFormsReal = [...get().freeProductForms]
    freeProductFormsReal.push(freeProductFormFromBundle)
    set({freeProductForms: freeProductFormsReal})
  },
  setUpdateFreeProductForms: (indexField, index, value) => {
    const freeProductForms = [...get().freeProductForms]
    freeProductForms[indexField][index] = {
      ...freeProductForms[indexField][index],
      value: value,
    }
    set({freeProductForms})
  },
  productForms: [],
  initProductForms: (products: SelectData[]) => {
    set({productForms: []})
    const productFormFromBundle: FormInput[] = []
    productFormFromBundle.push({
      id: `product_code_0`,
      title: 'Product',
      placeholder: 'Product',
      type: 'select-with-text',
      value: {},
      selectData: products,
      name: `product_code_0`,
      size: 6,
    })
    productFormFromBundle.push({
      id: `qty_0`,
      title: 'Qty',
      placeholder: '0',
      type: 'number',
      value: '',
      name: `qty_0`,
      size: 6,
    })
    const productFormsReal = [...get().productForms]
    productFormsReal.push(productFormFromBundle)
    set({productForms: productFormsReal})
  },
  setUpdateProductForms: (indexField, index, value) => {
    const productForms = [...get().productForms]
    productForms[indexField][index] = {
      ...productForms[indexField][index],
      value: value,
    }
    set({productForms: productForms})
  },
  setProductForms: async (productBundleId: string, productCodes: SelectData[]) => {
    if (!productBundleId || productBundleId === undefined) {
      set({productForms: []})
      return
    }

    const productbundle = await GetProductBundle(productBundleId)
    // const productForms = [...get().productForms]
    const productForms = []

    if (productbundle.data?.product_codes?.length > 0) {
      for (const product of productbundle.data?.product_codes) {
        const productFormFromBundle: FormInput[] = []
        productFormFromBundle.push({
          id: `product_code_${product.id}`,
          title: 'Product',
          placeholder: 'Product',
          type: 'select-with-text',
          value: {
            label: product.product_name,
            value: product.id,
          },
          selectData: productCodes,
          name: `product_code_${product.id}`,
          size: 6,
        })
        productFormFromBundle.push({
          id: `qty_${product.id}`,
          title: 'Qty',
          placeholder: '0',
          type: 'number',
          value: '',
          name: `qty_${product.id}`,
          size: 6,
        })
        productForms.unshift(productFormFromBundle)
      }
    }
    set({productForms: productForms})
  },
  addProductForms: (products: SelectData[]) => {
    const productFormFromBundle: FormInput[] = []
    productFormFromBundle.push({
      id: `product_code_0`,
      title: 'Product',
      placeholder: 'Product',
      type: 'select-with-text',
      value: {},
      selectData: products,
      name: `product_code_0`,
      size: 6,
    })
    productFormFromBundle.push({
      id: `qty_0`,
      title: 'Qty',
      placeholder: '0',
      type: 'number',
      value: '',
      name: `qty_0`,
      size: 6,
    })
    const productFormsReal = [...get().productForms]
    productFormsReal.push(productFormFromBundle)
    set({productForms: productFormsReal})
  },
  addFreeProductForms: (products: SelectData[]) => {
    const addFreeProductForms: FormInput[] = []
    addFreeProductForms.push({
      id: `product_code_0`,
      title: 'Product',
      placeholder: 'Product',
      type: 'select-with-text',
      value: {},
      selectData: products,
      name: `product_code_0`,
      size: 6,
    })
    addFreeProductForms.push({
      id: `qty_0`,
      title: 'Qty',
      placeholder: '0',
      type: 'number',
      value: '',
      name: `qty_0`,
      size: 6,
    })
    const freeProductFormsReal = [...get().freeProductForms]
    freeProductFormsReal.push(addFreeProductForms)
    set({freeProductForms: freeProductFormsReal})
  },
  changeRequired: (index, required) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      required: required,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    const field = get().field as TrxStocksData
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'product_bundle',
        title: 'Product Bundle',
        placeholder: 'Product Bundle...',
        type: 'select-with-text',
        name: 'product_bundle',
        value: get().field?.product_bundle ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'action_date',
        title: 'Tanggal',
        placeholder: 'Tanggal...',
        type: 'datetime',
        name: 'action_date',
        value: field?.action_date ?? new Date(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'acknowledge_by_id',
        title: 'Staff Gudang',
        placeholder: 'Staff Gudang...',
        type: 'select-with-text',
        name: 'acknowledge_by_id',
        value: {
          label: field?.acknowledge_user ?? 'Staff Gudang...',
          value: field?.acknowledge_by_id ?? '',
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'category',
        title: 'Kategori',
        placeholder: 'Kategori...',
        type: 'radio',
        name: 'category',
        value: field?.category ?? '',
        selectData: [
          {label: 'Tiktok', value: 'Tiktok'},
          {label: 'Shopee', value: 'Shopee'},
          {label: 'Endorse', value: 'Endorse'},
          {label: 'Others', value: 'Others'},
          {label: 'Distributor', value: 'Distributor'},
        ],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'total_resi',
        title: 'Jumlah Resi',
        placeholder: 'Jumlah Resi...',
        type: 'number',
        name: 'total_resi',
        value: field?.total_resi ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'notes',
        title: 'Notes',
        placeholder: 'Notes...',
        type: 'text',
        name: 'notes',
        value: field?.notes ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]

    if (field?.trx_stock_details?.length > 0) {
      const productForms = []
      const freeProductForms = []
      // get non free products
      for (const detail of field?.trx_stock_details) {
        const productFormDef: FormInput[] = []
        productFormDef.push({
          id: `product_code_${detail?.product_code_id}`,
          title: 'Product',
          placeholder: 'Product',
          type: 'select-with-text',
          value: {
            label: detail?.product_code?.product_name,
            value: detail?.product_code_id,
          },
          selectData: [],
          name: `product_code_${detail?.product_code_id}`,
          size: 6,
        })
        productFormDef.push({
          id: `qty_${detail?.product_code_id}`,
          title: 'Qty',
          placeholder: '0',
          type: 'number',
          value: detail?.quantity ?? 0,
          name: `qty_${detail?.product_code_id}`,
          size: 6,
        })
        if (!detail?.is_free) {
          productForms.unshift(productFormDef)
        } else {
          freeProductForms.unshift(productFormDef)
        }
      }
      set({productForms: productForms, freeProductForms: freeProductForms})
    }

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    const {forms, productForms, freeProductForms} = get()
    const actionDate = forms.find((item) => item.name === 'action_date')?.value ?? ''
    const acknowledgeById = forms.find((item) => item.name === 'acknowledge_by_id')?.value ?? ''
    const category = forms.find((item) => item.name === 'category')?.value ?? ''
    const notes = forms.find((item) => item.name === 'notes')?.value ?? ''
    const totalResi = forms.find((item) => item.name === 'total_resi')?.value ?? ''
    const products: any[] = []
    for (const productForm of productForms) {
      // console.log(productForm)
      if (productForm[0]?.value?.value !== undefined && +productForm[1]?.value === 0) {
        toast.error(`Mohon isi kuantiti ${productForm[0]?.value?.label}`)
        return
      }
      if (productForm[0]?.value?.value !== undefined && +productForm[1]?.value > 0) {
        products.push({
          product_code_id: productForm[0]?.value?.value,
          qty: +productForm[1]?.value,
          is_free: false,
        })
      }
    }

    for (const freeProductForm of freeProductForms) {
      if (freeProductForm[0]?.value?.value !== undefined && +freeProductForm[1]?.value === 0) {
        toast.error(`Mohon isi kuantiti ${freeProductForm[0]?.value?.label}`)
        return
      }
      if (freeProductForm[0]?.value?.value !== undefined && +freeProductForm[1]?.value > 0) {
        products.push({
          product_code_id: freeProductForm[0]?.value?.value,
          qty: +freeProductForm[1]?.value,
          is_free: true,
        })
      }
    }

    const form = {
      products: products,
      action_date: moment(actionDate).format('YYYY-MM-DD HH:mm:ss'),
      acknowledge_by_id: acknowledgeById.value,
      category: category,
      notes: notes,
      trx_stock_type: 'out',
      total_resi: +totalResi,
    }

    let res: TrxStocksResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitTrxStocksOut(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateTrxStocksIn(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
