import {useEffect} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import FormsComponent from '../../../../helpers/forms.component'
import {useProductStore} from '../../products/states/product.state'
import {useProductBundleFormStore} from '../states/product-bundle.state'

const ProductBundleModalComponent = () => {
  const {getProductCodes, productCodeSelectDatas} = useProductStore()
  const {setFormSelectData, forms} = useProductBundleFormStore()

  useEffect(() => {
    document.body.classList.add('modal-open')
    getProductCodes({
      page: 0,
      size: 1000,
      is_stock: true,
    })
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    if (productCodeSelectDatas?.length > 0) {
      forms.forEach((form, index) => {
        if (form.type === 'select-with-text' && index !== 0) {
          setFormSelectData(index, productCodeSelectDatas)
        }
      })
    }
  }, [productCodeSelectDatas])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_properties'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <ProductBundleModalHeader />

            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <ProductBundleModalFormWrapper />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

const ProductBundleModalHeader = () => {
  const {setField, formParam, setAdditionalFields} = useProductBundleFormStore()

  return (
    <div className='modal-header'>
      <h2 className='fw-bold'>{formParam?.title}</h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => {
          setField(undefined)
          setAdditionalFields(0)
        }}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

const ProductBundleModalFormWrapper = () => {
  const {forms, setForms, setField, onSubmit, addField, setAdditionalFields} =
    useProductBundleFormStore()

  return (
    <>
      <form>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <FormsComponent forms={forms} setForms={setForms} uploadFileCb={(e) => console.log(e)} />
        </div>
        <div className='text-center pt--15'>
          <button type='button' onClick={addField} className='btn btn-success me-3'>
            Add Product Code
          </button>
        </div>

        <div className='text-center pt--15 mt-6'>
          <button
            type='reset'
            onClick={() => {
              setField(undefined)
              setAdditionalFields(0)
            }}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            onClick={(e) => {
              e.preventDefault()
              onSubmit()
            }}
          >
            <span className='indicator-label'>Submit</span>
          </button>
        </div>
      </form>
    </>
  )
}

export default ProductBundleModalComponent
