import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {DashboardStockTransactionResult} from '../interfaces/dashboard-stock-transactions-result.interface'

export interface StockTransactionParam {
  page?: number
  size?: number
  filters?: string
  sort?: string
  start_time?: number
  end_time?: number
}

export const DashboardStockTransaction = async (
  params: StockTransactionParam
): Promise<DashboardStockTransactionResult> => {
  try {
    const response = await Api.get('/dashboard-stock-transactions', {params: params})
    return response.data as DashboardStockTransactionResult
  } catch (e) {
    const a = e as AxiosError
    const response: DashboardStockTransactionResult = a.response?.data
    return response
  }
}
