import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {TrxResiResultInterface} from '../interfaces/trx-resi-result.interface'
import {GeneralResponse} from '../../../../helpers/general-response'

export interface GetAllTrxResiParam {
  page?: number
  size?: number
  filters?: string
  sort?: string
}

export const GetAllTrxResi = async (
  params: GetAllTrxResiParam
): Promise<TrxResiResultInterface> => {
  try {
    const response = await Api.get('/trx-resis', {params: params})

    return response.data as TrxResiResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TrxResiResultInterface = a.response.data
    return response
  }
}

export const GetTrxResi = async (id: string): Promise<TrxResiResultInterface> => {
  try {
    const response = await Api.get('/trx-resis/' + id)
    return response.data as TrxResiResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TrxResiResultInterface = a.response?.data
    return response
  }
}

export const DeleteTrxResi = async (id: string): Promise<GeneralResponse> => {
  try {
    const response = await Api.delete('/trx-resis/' + id)
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}

export const SubmitTrxResi = async (form: any): Promise<TrxResiResultInterface> => {
  try {
    const response = await Api.post('/trx-resis', form)
    return response.data as TrxResiResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TrxResiResultInterface = a.response?.data
    return response
  }
}

export const UpdateTrxResi = async (id: string, form: any): Promise<TrxResiResultInterface> => {
  try {
    const response = await Api.put(`/trx-resis/${id}`, form)
    return response.data as TrxResiResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TrxResiResultInterface = a.response?.data
    return response
  }
}
