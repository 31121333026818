import {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useTransactionDetailStore} from '../states/transaction-detail.state'
import {KTCard} from '../../../../../_metronic/helpers'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import moment from 'moment'
import {generateStatus, generateType} from '../../../../helpers/generate-status'
import ReactPaginate from 'react-paginate'
import {generateCurrencyFormat} from '../../../../helpers/generate-number-format'
import Flatpickr from 'react-flatpickr'

const TransactionDetailComponent = () => {
  const params = useParams()

  const {
    getTransactionDetails,
    transactionDetailLoading,
    transactionDetailItems,
    transactionDetailModel,
  } = useTransactionDetailStore()
  const flatpickrRef = useRef<Flatpickr>(null)
  // const [filters, setFilters] = useState<any[]>([])
  // const [statementId, setStatementId] = useState<string>()
  const [search, setSearch] = useState<string>()
  const [statementId, setStatementId] = useState<string>()
  const [statusLabel, setStatusLabel] = useState<string>()
  const [typeLabel, setTypeLabel] = useState<string>()
  const [startTime, setStartTime] = useState<Date>(null)
  const [endTime, setEndTime] = useState<Date>(null)
  const [sort, setSort] = useState<string>('order_create_time')
  const [order, setOrder] = useState<string>('-')

  const resetData = () => {
    setStartTime(null)
    setEndTime(null)
    setSearch('')
    setStatusLabel('')
    setTypeLabel('')
    setOrder('order_create_time')
    setSort('-')
  }

  useEffect(() => {
    if (params.id) {
      setStatementId(params.id)
      getTransactionDetails({
        statement_id: params.id,
        sort: `${order}${sort}`,
        size: 10,
        page: 0,
      })
    }
  }, [params])

  const filterData = () => {
    startTime?.setHours(0, 0, 0, 0)
    endTime?.setHours(23, 59, 59, 0)
    const sDate = Math.floor(startTime?.getTime() / 1000)
    const eDate = Math.floor(endTime?.getTime() / 1000)

    getTransactionDetails({
      statement_id: statementId,
      type: typeLabel ?? undefined,
      sort: `${order}${sort}`,
      start_date: isNaN(sDate) ? undefined : sDate,
      end_date: isNaN(eDate) ? undefined : eDate,
      order_id: search ?? undefined,
      status: statusLabel ?? undefined,
      size: 10,
      page: 0,
    })
  }

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getTransactionDetails({
      page: selectedPage,
      size: 10,
      statement_id: statementId,
      sort: `${order}${sort}`,
    })
  }

  return (
    <>
      <KTCard className='p-3 mb-5'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-4'>
              <label className='form-label'>Order ID</label>
              <input
                onChange={(e) => setSearch(e.target.value)}
                placeholder='ID Pesanan'
                value={search}
                className={'form-control'}
                type='text'
              />
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Status Label:</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setStatusLabel(e.target.value)}
                value={statusLabel}
              >
                <option value=''></option>
                <option value='AWAITING_COLLECTION'>{generateStatus('AWAITING_COLLECTION')}</option>
                <option value='AWAITING_SHIPMENT'>{generateStatus('AWAITING_SHIPMENT')}</option>
                <option value='CANCELLED'>{generateStatus('CANCELLED')}</option>
                <option value='UNPAID'>{generateStatus('UNPAID')}</option>
                <option value='IN_TRANSIT'>{generateStatus('IN_TRANSIT')}</option>
                <option value='COMPLETED'>{generateStatus('COMPLETED')}</option>
                <option value='DELIVERED'>{generateStatus('DELIVERED')}</option>
              </select>
            </div>
            <div className='col-sm-4'>
              <label className={`form-label`}>Date Range</label>
              <Flatpickr
                ref={flatpickrRef}
                value={[startTime, endTime]}
                onChange={([date1, date2]) => {
                  setStartTime(date1)
                  setEndTime(date2)
                }}
                options={{
                  mode: 'range',
                }}
                className='form-control'
                placeholder='Pick date'
              />
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Type Label:</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setTypeLabel(e.target.value)}
                value={typeLabel}
              >
                <option value=''></option>
                <option value='ORDER'>{generateType('ORDER')}</option>
                <option value='PLATFORM_REIMBURSEMENT'>
                  {generateType('PLATFORM_REIMBURSEMENT')}
                </option>
                <option value='LOGISTICS_REIMBURSEMENT'>
                  {generateType('LOGISTICS_REIMBURSEMENT')}
                </option>
                <option value='DEDUCTIONS_INCURRED_BY_SELLER'>
                  {generateType('DEDUCTIONS_INCURRED_BY_SELLER')}
                </option>
              </select>
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Sort By:</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setSort(e.target.value)}
                value={sort}
              >
                <option value='order_create_time'>{'Waktu Order'}</option>
                <option value='settlement_amount'>{'Settlement Amount'}</option>
              </select>
            </div>
            <div className='col-sm-4'>
              <label className='form-label'>Order:</label>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setOrder(e.target.value)}
                value={order}
              >
                <option value='-'>{'DESC'}</option>
                <option value=''>{'ASC'}</option>
              </select>
            </div>
          </div>
        </div>
        <div className='card-footer'>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={transactionDetailLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={transactionDetailLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
        </div>
      </KTCard>
      <KTCard>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {transactionDetailLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Order ID</th>
                  <th>Status Pesanan</th>
                  <th>Pembeli</th>
                  <th>Waktu Pesanan</th>
                  <th>Produk</th>
                  <th>Order Amount</th>
                  <th>Admin Fee</th>
                  <th>Settlement Amount</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {transactionDetailItems?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex flex-column'>
                            <b>{item.order_id?.length > 0 ? item.order_id : item.type}</b>
                          </div>
                        </div>
                      </td>
                      <td>
                        <b>{generateStatus(item.order_status ?? 'UNPAID')}</b>
                      </td>
                      <td>
                        <span>
                          {item?.recipient_address?.name ?? item?.recipient_address?.phone_number}
                        </span>
                      </td>
                      <td>{moment(item.order_create_time * 1000).format('DD/MM/YYYY HH:mm:ss')}</td>
                      <td>
                        <div className='d-flex flex-column'>
                          <span>
                            <b>{item.line_items?.length}</b>{' '}
                            {item.line_items?.length > 0 ? 'produk' : ''}
                          </span>
                          {item.line_items?.length > 0 ? (
                            <div className='symbol symbol-35px'>
                              <img
                                src={item.line_items[0]?.sku_image}
                                alt={item.line_items[0]?.product_id}
                              />
                            </div>
                          ) : null}
                        </div>
                      </td>
                      <td>{generateCurrencyFormat(item.revenue_amount ?? 0)}</td>
                      <td>{generateCurrencyFormat(item.fee_amount ?? 0)}</td>
                      <td>{generateCurrencyFormat(item.settlement_amount)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={transactionDetailModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
    </>
  )
}

export default TransactionDetailComponent
