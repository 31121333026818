import {create} from 'zustand'
import {GeneralResponse} from '../../../../helpers/general-response'
import {
  DeleteMasterStockReport,
  GenerateMasterStockReportExcel,
  GenerateMasterStockReportPdf,
  GetAllMasterStockReports,
  GetAllMasterStockReportsParam,
} from '../providers/master-stock-report.provider'
import {toast} from 'react-toastify'
import {
  MasterStockReportItem,
  MasterStockReportResultInterface,
} from '../interfaces/master-stock-report-result.interface'

interface MasterStockReportState {
  getGenerateReportExcel: (params: GetAllMasterStockReportsParam) => Promise<void>
  getGenerateReportPdf: (params: GetAllMasterStockReportsParam) => Promise<void>
  generateExportLoading: boolean
  generateExportResult: GeneralResponse

  masterStockReportItems: MasterStockReportItem[]
  masterStockReportModel: MasterStockReportResultInterface
  masterStockReportLoading: boolean
  getMasterStockReports: (params: GetAllMasterStockReportsParam) => Promise<void>
  onDelete: (id: string, params: GetAllMasterStockReportsParam) => void
}

export const useMasterStockReportStore = create<MasterStockReportState>((set, get) => ({
  generateExportLoading: false,
  generateExportResult: undefined,
  getGenerateReportExcel: async (params: GetAllMasterStockReportsParam) => {
    set({
      generateExportLoading: true,
      generateExportResult: undefined,
    })

    const response = await GenerateMasterStockReportExcel(params)
    set({generateExportResult: response})

    toast.success(response.message ?? 'Success')

    set({
      generateExportLoading: false,
    })
  },
  getGenerateReportPdf: async (params: GetAllMasterStockReportsParam) => {
    set({
      generateExportLoading: true,
      generateExportResult: undefined,
    })

    const response = await GenerateMasterStockReportPdf(params)
    set({generateExportResult: response})

    toast.success(response.message ?? 'Success')

    set({
      generateExportLoading: false,
    })
  },

  masterStockReportItems: [],
  masterStockReportModel: undefined,
  masterStockReportLoading: false,
  getMasterStockReports: async (params: GetAllMasterStockReportsParam) => {
    set({
      masterStockReportLoading: true,
      masterStockReportModel: null,
      masterStockReportItems: [],
    })

    const response = await GetAllMasterStockReports(params)
    set({masterStockReportModel: response})

    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        set({masterStockReportItems: response.data?.items})
      }
    }

    set({
      masterStockReportLoading: false,
    })
  },
  onDelete: async (id: string, params: GetAllMasterStockReportsParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({masterStockReportLoading: true})
      const response = await DeleteMasterStockReport(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getMasterStockReports(params)
      set({masterStockReportLoading: false})
    }
  },
}))
