import {AxiosError, AxiosProgressEvent} from 'axios'
import {MultipleFileUploadResult} from '../interfaces/multiple-file-upload-result'
import {SingleFileUploadResult} from '../interfaces/single-file-upload-result'
import {ApiDetailFile, ApiUploadFile} from '../../../../api/api'

export const UploadSingle = async (
  file: File,
  progress: (e: AxiosProgressEvent) => void
): Promise<SingleFileUploadResult> => {
  try {
    const formData = new FormData()
    formData.append('file', file)

    let res = await ApiUploadFile().post('/storage/single', formData, {
      onUploadProgress: (pg: AxiosProgressEvent) => {
        progress(pg)
      },
    })

    return res.data as SingleFileUploadResult
  } catch (e) {
    let a = e as AxiosError
    let response: SingleFileUploadResult = a.response?.data
    return response
  }
}

export const UploadSingleVideo = async (
  file: File,
  progress: (e: AxiosProgressEvent) => void
): Promise<SingleFileUploadResult> => {
  try {
    const formData = new FormData()
    formData.append('file', file)

    let res = await ApiUploadFile().post('/storage/single-video', formData, {
      onUploadProgress: (pg: AxiosProgressEvent) => {
        progress(pg)
      },
    })

    return res.data as SingleFileUploadResult
  } catch (e) {
    let a = e as AxiosError
    let response: SingleFileUploadResult = a.response?.data
    return response
  }
}

export const UploadMultiple = async (
  file: File[],
  progress: (e: AxiosProgressEvent) => void
): Promise<MultipleFileUploadResult> => {
  try {
    const formData = new FormData()
    if (file.length > 0) {
      file.forEach((item, index) => {
        formData.append('files', item)
      })
    }

    const res = await ApiUploadFile().post('/storage/multiple', formData, {
      onUploadProgress: (pg: AxiosProgressEvent) => {
        progress(pg)
      },
    })

    return res.data as MultipleFileUploadResult
  } catch (e) {
    let a = e as AxiosError
    let response: MultipleFileUploadResult = a.response?.data
    return response
  }
}

export const DetailAsset = async (assetId: string): Promise<SingleFileUploadResult> => {
  try {
    let res = await ApiDetailFile().get(`/detail-assets/${assetId}`)
    return res.data as SingleFileUploadResult
  } catch (e) {
    let a = e as AxiosError
    let response: SingleFileUploadResult = a.response?.data
    return response
  }
}
