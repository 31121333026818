import {lazy} from 'react'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import {SuspensedView} from '../../../../routing/PrivateRoutes'
import UserListWrapper from './user.screen'

const userBreadcrumbs: Array<PageLink> = [
  {
    title: 'User',
    path: '/apps/user-management/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UserPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='users'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={userBreadcrumbs}>Users list</PageTitle>
              <UserListWrapper />
            </SuspensedView>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/user-management/users' />} />
    </Routes>
  )
}

export default UserPage
