import {create} from 'zustand'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../helpers/form-action-interfaces'
import {UserData} from '../../../auth'
import {UserResultInterface} from '../interfaces/user-result.interface'
import {
  DeleteUser,
  GetAllUser,
  GetAllUserParam,
  SubmitUser,
  UpdateUser,
} from '../providers/users.provider'
import {toast} from 'react-toastify'
import {generateFormLogic} from '../../../../helpers/generate-form-logic'
import {UserSingleResultInterface} from '../interfaces/user-single-result.interface'

interface UserState {
  userLoading: boolean
  userItems: UserData[]
  userSelectDatas: SelectData[]
  userModel?: UserResultInterface
  getUsers: (params: GetAllUserParam) => void
  onDelete: (id: string, params: GetAllUserParam) => void
  reset: () => void
}

export const useUserStore = create<UserState>((set, get) => ({
  reset: () => {
    set({
      userSelectDatas: [],
      userModel: undefined,
      userItems: [],
    })
  },
  userLoading: false,
  userItems: [],
  userSelectDatas: [],
  userModel: undefined,
  getUsers: async (params: GetAllUserParam) => {
    set({userLoading: true})
    const response = await GetAllUser(params)
    set({userModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.full_name,
            value: item.id,
          })
        }
        set({
          userItems: response.data.items,
          userSelectDatas: transformDatas,
        })
      } else {
        set({
          userItems: [],
          userSelectDatas: [],
        })
      }
    } else {
      set({
        userItems: [],
        userSelectDatas: [],
      })
    }
    set({userLoading: false})
  },
  onDelete: async (id: string, params: GetAllUserParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({userLoading: true})
      const response = await DeleteUser(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getUsers(params)
      set({userLoading: false})
    }
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface UserFormState {
  field?: UserData
  setField?: (item: UserData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const useUserFormStore = create<UserFormState>((set, get) => ({
  field: undefined,
  setField: (item: UserData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'avatar',
        title: 'Avatar',
        placeholder: 'Upload Media (Max file size 2Mb)',
        type: 'file-upload-v2',
        name: 'avatar',
        value: get().field?.avatar,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        id: 'role_id',
        title: 'Role',
        placeholder: 'Role...',
        type: 'select-with-text',
        name: 'role_id',
        value: {
          label: get().field?.role?.name,
          value: get().field?.role?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'full_name',
        title: 'Full Name',
        placeholder: 'Full Name...',
        type: 'text',
        name: 'full_name',
        value: get().field?.full_name,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'email',
        title: 'Email',
        placeholder: 'Email...',
        type: 'email',
        name: 'email',
        value: get().field?.email ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'phone_number',
        title: 'Phone Number',
        placeholder: 'Phone Number...',
        type: 'text',
        name: 'phone_number',
        value: get().field?.phone_number ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'password',
        title: 'Password',
        placeholder: 'Password...',
        type: 'password',
        name: 'password',
        value: '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: UserSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitUser(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateUser(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
