import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {TransactionStatementDetailResultInterface} from '../interfaces/transaction-detail-result.interface'

export interface TransactionDetailParam {
  page?: number
  size?: number
  filters?: string
  sort?: string
  start_date?: number
  end_date?: number
  statement_id?: string
  type?: string
  order_id?: string
  status?: string
}

export const GetTransactionDetails = async (
  params: TransactionDetailParam
): Promise<TransactionStatementDetailResultInterface> => {
  try {
    const response = await Api.get('/trx-statement-details', {params: params})
    return response.data as TransactionStatementDetailResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TransactionStatementDetailResultInterface = a.response?.data
    return response
  }
}
