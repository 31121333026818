import {create} from 'zustand'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../helpers/form-action-interfaces'
import {RoleItem, RoleResultInterface} from '../interfaces/roles-result.interface'
import {
  DeleteRole,
  GetAllRole,
  GetAllRoleParam,
  SubmitRole,
  UpdateRole,
} from '../providers/roles.provider'
import {toast} from 'react-toastify'
import {generateFormLogic} from '../../../../helpers/generate-form-logic'
import {RoleSingleInterface} from '../interfaces/roles-single.interface'

interface RoleState {
  roleLoading: boolean
  roleItems: RoleItem[]
  roleSelectDatas: SelectData[]
  roleModel?: RoleResultInterface
  getRoles: (params: GetAllRoleParam) => void
  onDelete: (id: string, params: GetAllRoleParam) => void
  reset: () => void
}

export const useRoleStore = create<RoleState>((set, get) => ({
  reset: () => {
    set({
      roleSelectDatas: [],
      roleModel: undefined,
      roleItems: [],
    })
  },
  roleLoading: false,
  roleItems: [],
  roleSelectDatas: [],
  roleModel: undefined,
  getRoles: async (params: GetAllRoleParam) => {
    set({roleLoading: true})
    const response = await GetAllRole(params)
    set({roleModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.name,
            value: item.id,
          })
        }
        set({
          roleItems: response.data.items,
          roleSelectDatas: transformDatas,
        })
      } else {
        set({
          roleItems: [],
          roleSelectDatas: [],
        })
      }
    } else {
      set({
        roleItems: [],
        roleSelectDatas: [],
      })
    }
    set({roleLoading: false})
  },
  onDelete: async (id: string, params: GetAllRoleParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({roleLoading: true})
      const response = await DeleteRole(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getRoles(params)
      set({roleLoading: false})
    }
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface RoleFormState {
  field?: RoleItem
  setField?: (item: RoleItem) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const useRoleFormStore = create<RoleFormState>((set, get) => ({
  field: undefined,
  setField: (item: RoleItem) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'name',
        title: 'Name',
        placeholder: 'Name...',
        type: 'text',
        name: 'name',
        value: get().field?.name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: RoleSingleInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitRole(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateRole(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
