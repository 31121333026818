import {useContext, useState, useEffect, useRef} from 'react'
import {toast} from 'react-toastify'
import {SingleFileUploadResult} from '../modules/apps/uploads/interfaces/single-file-upload-result'
import {DetailAsset} from '../modules/apps/uploads/providers/upload-provider'
import {
  UploadContextProvider,
  UploadContext,
  UploadContextType,
} from '../modules/apps/uploads/states/upload-context'
import {generateImageUrl} from './generate-image-url'
import {IoCloseCircleSharp} from 'react-icons/io5'

interface FileUploadProps {
  title: string
  name?: string
  description?: string
  multiple: boolean
  value?: string
  disabled: boolean
  onUploadDone?: (file: SingleFileUploadResult) => void
  onChangeFile?: (e: React.ChangeEvent<HTMLInputElement>) => void
  isImage?: boolean
  isVideo?: boolean
  isFile?: boolean
  required?: boolean
  accept?: string
  limitFileSize?: number // in kb
}

export const FileUploadVideoV2: React.FC<FileUploadProps> = ({
  title,
  name,
  description,
  multiple,
  value,
  disabled = false,
  onUploadDone,
  onChangeFile,
  isImage = true,
  isVideo = true,
  isFile = false,
  required = true,
  accept = '*',
  limitFileSize = 30720,
}) => {
  return (
    <UploadContextProvider>
      <FileUploadVideoState
        title={title}
        name={name}
        description={description}
        multiple={multiple}
        value={value}
        disabled={disabled}
        onUploadDone={onUploadDone}
        onChangeFile={onChangeFile}
        isImage={isImage}
        isVideo={isVideo}
        required={required}
        isFile={isFile}
        accept={accept}
        limitFileSize={limitFileSize}
      />
    </UploadContextProvider>
  )
}

const FileUploadVideoState: React.FC<FileUploadProps> = ({
  title,
  name,
  description,
  multiple,
  value,
  disabled = false,
  onUploadDone,
  onChangeFile,
  isImage = true,
  isVideo = true,
  isFile = false,
  required,
  accept = '*',
}) => {
  const {setSingleFileVideo, resultSingleFile, total, loaded} = useContext(
    UploadContext
  ) as UploadContextType
  const [width, setWidth] = useState<string>('0%')

  useEffect(() => {
    let t = Math.floor((loaded / total) * 100)
    let s = t.toString() + '%'
    setWidth(s)
  }, [total, loaded])

  useEffect(() => {
    if (resultSingleFile !== undefined) {
      onUploadDone(resultSingleFile)
    }
  }, [resultSingleFile])

  return (
    <div className='fv-row mb-7'>
      <label className={`d-block fw-semibold fs-6 mb-5 ${required ? 'required' : ''}`}>
        {title}
      </label>

      {/* Image File */}
      {value !== undefined && value !== '' ? (
        <>
          <br />
          {isVideo ? (
            <video width='320' height='240' controls>
              <source src={generateImageUrl(value)} type='video/mp4' />
            </video>
          ) : (
            <>
              <a
                href={`${process.env.REACT_APP_DOWNLOADS_URL}/${value}`}
                target='_blank'
                rel='noreferrer'
                className='btn btn-primary'
              >
                Download File
              </a>
              {/* <input  
                id="exampleFile" 
                type="text" 
                className="form-control"
                disabled
                value={`${process.env.REACT_APP_DOWNLOADS_URL}/${value}`}
              /> */}
            </>
          )}
        </>
      ) : (
        <div></div>
      )}

      <input
        name={name}
        id='exampleFile'
        type='file'
        className={'form-control mb-3 mb-lg-0'}
        disabled={disabled}
        multiple={multiple}
        onChange={(e) => {
          const file = e.target.files[0]
          setSingleFileVideo(file)
        }}
        accept={accept}
      />
      <small className='form-text text-muted'>{description}</small>
      <div className='mb-3 progress'>
        <div
          className='progress-bar progress-bar-animated bg-success progress-bar-striped'
          role='progressbar'
          aria-valuenow={loaded}
          aria-valuemin={0}
          aria-valuemax={total}
          style={{
            width: width,
          }}
        >
          {loaded === 0 && total === 0
            ? ''
            : loaded === total
            ? 'Upload done'
            : `${width} Uploading...`}
        </div>
      </div>
    </div>
  )
}

export const FileUploadV2: React.FC<FileUploadProps> = ({
  title,
  name,
  description,
  multiple,
  value,
  disabled = false,
  onUploadDone,
  onChangeFile,
  isImage = true,
  accept = '*',
  required,
}) => {
  return (
    <UploadContextProvider>
      <FileUploadState
        title={title}
        name={name}
        description={description}
        multiple={multiple}
        value={value}
        disabled={disabled}
        onUploadDone={onUploadDone}
        onChangeFile={onChangeFile}
        isImage={isImage}
        accept={accept}
        required={required}
      />
    </UploadContextProvider>
  )
}

const FileUploadState: React.FC<FileUploadProps> = ({
  title,
  name,
  description,
  multiple,
  value,
  disabled = false,
  onUploadDone,
  onChangeFile,
  isImage = true,
  accept = '*',
  required = true,
  limitFileSize = 30720,
}) => {
  const {setSingleFile, resultSingleFile, total, loaded} = useContext(
    UploadContext
  ) as UploadContextType
  const [width, setWidth] = useState<string>('0%')
  const [fileExisting, setFileExisting] = useState<SingleFileUploadResult>(undefined)
  const inputFile = useRef<HTMLInputElement>()

  const getFileExisting = async (assetId: string) => {
    const response = await DetailAsset(assetId)
    setFileExisting(response)
  }

  useEffect(() => {
    let t = Math.floor((loaded / total) * 100)
    let s = t.toString() + '%'
    setWidth(s)
  }, [total, loaded])

  useEffect(() => {
    if (resultSingleFile !== undefined && resultSingleFile !== null) {
      onUploadDone(resultSingleFile)
    }
  }, [resultSingleFile])

  useEffect(() => {
    console.log('isImage', isImage)
  }, [isImage])

  useEffect(() => {
    if (value !== undefined && value !== '' && resultSingleFile === undefined) {
      getFileExisting(value)
    }
  }, [value])

  useEffect(() => {
    console.log(fileExisting)
  }, [fileExisting])

  return (
    <div className='col-sm-12 col-md-12'>
      <label className={`d-block fw-semibold fs-6 mb-5 ${required ? 'required' : ''}`}>
        {title}
      </label>

      {/* Image File */}
      {value !== undefined && value !== '' && resultSingleFile !== undefined ? (
        <>
          <br />
          {resultSingleFile?.data?.mimetype?.includes('image') && (
            <div
              style={{
                position: 'relative',
              }}
            >
              <img
                src={generateImageUrl(value)}
                alt={'my_avatar'}
                style={{
                  width: '100%',
                  height: 250,
                  marginBottom: 10,
                  objectFit: 'contain',
                  boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                  borderRadius: 10,
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  margin: 5,
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.preventDefault()
                  setSingleFile(null)
                }}
              >
                <IoCloseCircleSharp size={30} />
              </div>
            </div>
          )}
          {resultSingleFile?.data?.mimetype?.includes('video') && (
            <video width='320' height='240' controls>
              <source src={generateImageUrl(value)} type='video/mp4' />
            </video>
          )}
          {!resultSingleFile?.data?.mimetype?.includes('image') &&
            !resultSingleFile?.data?.mimetype?.includes('video') && (
              <a
                href={`${process.env.REACT_APP_DOWNLOADS_URL}/${value}`}
                target='_blank'
                rel='noreferrer'
                className='btn btn-primary'
                style={{marginBottom: 10}}
              >
                View File
              </a>
            )}
        </>
      ) : (
        <div>
          <>
            {value !== undefined && value !== '' && fileExisting !== undefined ? (
              <>
                {fileExisting?.data?.mimetype?.includes('image') && (
                  <img
                    src={generateImageUrl(value)}
                    alt={'my_avatar'}
                    style={{
                      width: '100%',
                      height: 250,
                      borderRadius: 10,
                      marginBottom: 10,
                      objectFit: 'contain',
                      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                    }}
                  />
                )}
                {fileExisting?.data?.mimetype?.includes('video') && (
                  <video width='320' height='240' controls>
                    <source src={generateImageUrl(value)} type='video/mp4' />
                  </video>
                )}
                {!fileExisting?.data?.mimetype?.includes('image') &&
                  !fileExisting?.data?.mimetype?.includes('video') && (
                    <a
                      href={`${process.env.REACT_APP_DOWNLOADS_URL}/${value}`}
                      target='_blank'
                      rel='noreferrer'
                      className='btn btn-primary'
                      style={{marginBottom: 10}}
                    >
                      View File
                    </a>
                  )}
              </>
            ) : (
              <></>
            )}
          </>
        </div>
      )}

      <input
        ref={inputFile}
        name={name}
        id='exampleFile'
        type='file'
        className='form-control'
        disabled={disabled}
        multiple={multiple}
        onChange={(e) => {
          const file = e.target.files[0]
          // check file size
          const fileSize = file?.size / 1024
          if (fileSize > limitFileSize) {
            toast.error(`Ukuran file lebih dari ${(limitFileSize / 1024).toFixed()}MB`)
            return
          } else {
            onChangeFile(e)
            setSingleFile(file)
          }
        }}
        accept={accept}
        required={required}
      />
      <small className='form-text text-muted'>{description}</small>
      {loaded === total ? (
        <></>
      ) : (
        <div className='mb-3 progress'>
          <div
            className='progress-bar progress-bar-animated bg-success progress-bar-striped'
            role='progressbar'
            aria-valuenow={loaded}
            aria-valuemin={0}
            aria-valuemax={total}
            style={{
              width: width,
            }}
          >
            {loaded === 0 && total === 0
              ? ''
              : loaded === total
              ? 'Upload done'
              : `${width} Uploading...`}
          </div>
        </div>
      )}
    </div>
  )
}
