import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {GeneralResponse} from '../../../../helpers/general-response'
import {UserResultInterface} from '../interfaces/user-result.interface'
import {UserSingleResultInterface} from '../interfaces/user-single-result.interface'

export interface GetAllUserParam {
  page?: number
  size?: number
  filters?: string
  sort?: string
}

export const GetAllUser = async (params: GetAllUserParam): Promise<UserResultInterface> => {
  try {
    let response = await Api.get('/users', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: UserResultInterface = a.response?.data
    return response
  }
}

export const ShowUser = async (id: string): Promise<UserSingleResultInterface> => {
  try {
    let response = await Api.get('/users/' + id)
    return response.data as UserSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: UserSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitUser = async (form: any): Promise<UserSingleResultInterface> => {
  try {
    let response = await Api.post('/users', form)
    return response.data as UserSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: UserSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateUser = async (id: string, form: any): Promise<UserSingleResultInterface> => {
  try {
    let response = await Api.put('/users/' + id, form)
    return response.data as UserSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: UserSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteUser = async (id: string): Promise<GeneralResponse> => {
  try {
    let response = await Api.delete('/users/' + id)
    return response.data as GeneralResponse
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralResponse = a.response?.data
    return response
  }
}
