import {useEffect} from 'react'
import {KTCard, KTSVG} from '../../../../../_metronic/helpers'
import {useProductBundleStore} from '../states/product-bundle.state'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'

interface ModalDetailProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

const ProductBundleDetailModalComponent: React.FC<ModalDetailProps> = ({isOpen, onClose, id}) => {
  const {getProductBundleDetail, productBundle, productBundleOneLoading} = useProductBundleStore()
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open')
      getProductBundleDetail(id)
    } else {
      document.body.classList.remove('modal-open')
    }
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_properties'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bold'>Detail</h2>

              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={onClose}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              {productBundleOneLoading && <UsersListLoading />}
              <div className='card-body py-3'>
                <div className='card-header border-0 pt-6'>
                  <h4>{productBundle?.bundle_name}</h4>
                </div>
                <div className='table-responsive'>
                  <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                    <thead>
                      <tr>
                        <th>
                          <div className='card-header border-0 pt-6'>
                            <h4>Nama Produk</h4>
                          </div>
                        </th>
                        <th>
                          <div className='card-header border-0 pt-6'>
                            <h4>Kode Produk</h4>
                          </div>
                        </th>
                        <th>
                          <div className='card-header border-0 pt-6'>
                            <h4>Marketplace</h4>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {productBundle?.product_codes.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item?.product_name}</td>
                            <td>{item?.product_code}</td>
                            <td>{item?.marketplace === 1 ? 'TikTok' : 'Shopee'}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show' onClick={onClose}></div>
    </>
  )
}

export default ProductBundleDetailModalComponent
