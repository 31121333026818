import {create} from 'zustand'
import {
  ProductBundleData,
  ProductBundleResultInterface,
} from '../interfaces/product-bundle-result.interface'
import {
  DeleteProductBundle,
  GetAllProductBundle,
  GetAllProductBundleParam,
  GetProductBundle,
  SubmitProductBundle,
  UpdateProductBundle,
} from '../providers/product-bundle.provider'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../helpers/form-action-interfaces'
import {toast} from 'react-toastify'

interface ProductBundleState {
  productBundleLoading: boolean
  productBundles: ProductBundleData[]
  productBundleSelectDatas: SelectData[]
  productBundleModel: ProductBundleResultInterface
  getProductBundles: (params: GetAllProductBundleParam) => void
  productBundleOneLoading: boolean
  productBundle: ProductBundleData
  getProductBundleDetail: (id: string) => void
  onDelete: (id: string, params: GetAllProductBundleParam) => void
}

export const useProductBundleStore = create<ProductBundleState>((set, get) => ({
  productBundleLoading: false,
  productBundleOneLoading: false,
  productBundles: [],
  productBundleSelectDatas: [],
  productBundle: undefined,
  productBundleModel: null,
  getProductBundles: async (params: GetAllProductBundleParam) => {
    set({productBundleLoading: true, productBundles: []})
    const response = await GetAllProductBundle(params)
    set({productBundleModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.bundle_name,
            value: item.id,
          })
        }
        set({productBundles: response.data.items, productBundleSelectDatas: transformDatas})
      }
    }
    set({productBundleLoading: false})
  },
  getProductBundleDetail: async (id: string) => {
    set({
      productBundleOneLoading: true,
      productBundle: null,
    })
    const response = await GetProductBundle(id)
    if (response.status === true) {
      set({productBundle: response.data})
    }
    set({
      productBundleOneLoading: false,
    })
  },
  onDelete: async (id: string, params: GetAllProductBundleParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({productBundleLoading: true})
      const response = await DeleteProductBundle(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getProductBundles(params)
      set({productBundleLoading: false})
    }
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface ProductBundleFormState {
  field?: any
  setField?: (item: ProductBundleData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps, additionalFields?: number) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
  additionalFields: number
  addField: () => void
  removeField: (index: number) => void
  getProductBundleDetail: (id: string) => void
  productBundleOneLoading: boolean
  productBundle: ProductBundleData
  setAdditionalFields: (value: number) => void
}

export const useProductBundleFormStore = create<ProductBundleFormState>((set, get) => ({
  field: undefined,
  productBundleOneLoading: false,
  productBundle: undefined,
  setField: (item: ProductBundleData) => {
    set({field: item})
  },
  getProductBundleDetail: async (id: string) => {
    set({
      productBundleOneLoading: true,
    })
    const response = await GetProductBundle(id)
    if (response.status === true) {
      set({productBundle: response.data})
    }
    set({
      productBundleOneLoading: false,
    })
  },
  additionalFields: 0,
  setAdditionalFields: (value: number) => {
    set({additionalFields: value})
  },
  formParam: undefined,
  forms: [],
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps, additionalFields: number = 0) => {
    set({formParam: formParam})

    let forms: FormInput[] = [
      {
        id: 'bundle_name',
        title: 'Bundle Name',
        placeholder: 'Bundle Name...',
        type: 'text',
        name: 'bundle_name',
        value: get().field?.bundle_name ?? '',
        disabled: formParam.action === FormAction.VIEW,
        required: true,
      },
    ]

    if (formParam.action === FormAction.UPDATE && get().productBundle) {
      const productCodes = get().productBundle.product_codes
      productCodes.forEach((product, index) => {
        forms.push({
          id: `product_code_id_${index}`,
          title: `Product ${index + 1}`,
          placeholder: `Product...`,
          type: 'select-with-text',
          name: `product_code_id_${index}`,
          value: {
            label: product.product_name,
            value: +product.id,
          },
          disabled: formParam.action === FormAction.VIEW,
          required: true,
          selectData:
            get().forms?.find((form) => form.name === `product_code_id_${index}`)?.selectData ?? [],
        })
      })
    } else {
      forms.push({
        id: 'product_code_id_0',
        title: 'Product 1',
        placeholder: 'Product...',
        type: 'select-with-text',
        name: 'product_code_id_0',
        value: '',
        disabled: formParam.action === FormAction.VIEW,
        required: true,
        selectData:
          get().forms?.find((form) => form.name === 'product_code_id_0')?.selectData ?? [],
      })
    }

    set({forms: forms})
  },
  addField: () => {
    const additionalFields = get().additionalFields + 1
    const productCodesLength =
      get().formParam.action === FormAction.UPDATE ? get().productBundle?.product_codes?.length : 1

    set({additionalFields})

    const forms = [...get().forms]
    const formParam = get().formParam
    const selectData = forms[1]?.selectData ?? []

    forms.push({
      id: `product_code_id_${productCodesLength + additionalFields - 1}`,
      title: `Product ${productCodesLength + additionalFields}`,
      placeholder: `Product...`,
      type: 'select-with-text',
      name: `product_code_id_${productCodesLength + additionalFields - 1}`,
      value: '',
      disabled: formParam?.action === FormAction.VIEW,
      required: true,
      selectData: selectData,
    })

    set({forms})
  },
  removeField: (index: number) => {
    const forms = [...get().forms]
    forms.splice(index, 1)

    forms.forEach((form, idx) => {
      if (form.name.startsWith('product_code_id_')) {
        form.name = `product_code_id_${idx}`
        form.title = `Product ${idx + 1}`
      }
    })

    set({forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    const form = get().forms.reduce((acc, item) => {
      if (item?.name === 'bundle_name') {
        acc['bundle_name'] = item?.value
      } else if (typeof item?.name === 'string' && item?.name?.startsWith('product_code_id_')) {
        const value = +item?.value?.value
        if (!isNaN(value)) {
          if (!acc['product_code_ids']) {
            acc['product_code_ids'] = []
          }
          acc['product_code_ids'].push(value)
        }
      }
      return acc
    }, {})

    let res: ProductBundleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitProductBundle(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateProductBundle(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
