import {create} from 'zustand'
import {OrderData, OrderResultInterface} from '../interfaces/order-result.interface'
import {
  CountTotalDataOrder,
  GetAllOrder,
  GetAllOrderExport,
  GetAllOrderParam,
  GetGenerateOrderExport,
  GetShippingDocument,
  RefreshOrder,
  ShowOrder,
} from '../providers/order.provider'
import {OrderSingleResultInterface} from '../interfaces/order-single-result.interface'
import {ShippingDocumentResult} from '../interfaces/shipping-doc-result.interface'
import {GeneralResponse} from '../../../../helpers/general-response'
import {toast} from 'react-toastify'
import {
  StatementExportItem,
  StatementExportResult,
} from '../../settlements/interfaces/settlement-export-result.interface'

interface OrderState {
  orderLoading: boolean
  orders: OrderData[]
  orderModel: OrderResultInterface
  getOrders: (params: GetAllOrderParam) => void
  refreshOrders: (params: GetAllOrderParam) => void

  orderOneLoading: boolean
  order: OrderData
  orderOneModel: OrderSingleResultInterface
  getOrder: (id: string) => void
  filters: string
  setFilters: (filter: string) => void

  shippingDocLoading: boolean
  shippingDoc: ShippingDocumentResult
  getShippingDocument: (packageId: string) => void

  countOrderLoading: boolean
  countOrders: number
  getCountOrders: (params: GetAllOrderParam) => void

  generateExportLoading: boolean
  generateExportResult: GeneralResponse
  getGenerateExport: (params: GetAllOrderParam) => void

  orderExportItems: StatementExportItem[]
  orderExportModel: StatementExportResult
  orderExportLoading: boolean
  getOrderExport: (params: GetAllOrderParam) => void

  orderDownload: (id: string) => void

  resetOrder: () => void
}

export const useOrderStore = create<OrderState>((set, get) => ({
  resetOrder: () => {
    set({
      shippingDocLoading: false,
      shippingDoc: null,
      orderOneLoading: false,
      order: null,
      orderOneModel: null,
    })
  },
  filters: '',
  setFilters: (value: string) => {
    set({filters: value})
  },
  orderLoading: false,
  orders: [],
  orderModel: null,
  getOrders: async (params: GetAllOrderParam) => {
    set({
      orderLoading: true,
      orders: [],
    })
    const response = await GetAllOrder(params)
    set({orderModel: response})
    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        set({orders: response.data?.items})
      }
    }
    set({
      orderLoading: false,
    })
  },
  refreshOrders: async (params: GetAllOrderParam) => {
    set({
      orderLoading: true,
      orders: [],
    })
    await RefreshOrder(params)
    get().getOrders({page: 0, size: 25, sort: '-create_time'})
  },

  orderOneLoading: false,
  order: null,
  orderOneModel: null,
  getOrder: async (id: string) => {
    set({
      orderOneLoading: false,
      order: null,
      orderOneModel: null,
    })
    const response = await ShowOrder(id)
    if (response.status === true) {
      set({order: response.data})
    }
    set({
      orderOneLoading: true,
      orderOneModel: response,
    })
  },

  shippingDocLoading: false,
  shippingDoc: null,
  getShippingDocument: async (packageId: string) => {
    set({
      shippingDocLoading: true,
      shippingDoc: null,
    })
    const response = await GetShippingDocument(packageId)
    set({
      shippingDocLoading: false,
      shippingDoc: response,
    })
  },

  countOrderLoading: false,
  countOrders: 0,
  getCountOrders: async (params: GetAllOrderParam) => {
    set({countOrderLoading: true, countOrders: 0})
    const response = await CountTotalDataOrder(params)
    if (response) {
      if (response.status) {
        set({countOrders: response.data})
      }
    }
    set({countOrderLoading: false})
  },

  generateExportLoading: false,
  generateExportResult: undefined,
  getGenerateExport: async (params: GetAllOrderParam) => {
    set({
      generateExportLoading: true,
      generateExportResult: undefined,
    })

    const response = await GetGenerateOrderExport(params)
    set({generateExportResult: response})

    toast.success(response.message ?? 'Success')

    set({
      generateExportLoading: false,
    })
  },

  orderExportItems: [],
  orderExportModel: undefined,
  orderExportLoading: false,
  getOrderExport: async (params: GetAllOrderParam) => {
    set({
      orderExportLoading: true,
      orderExportModel: null,
      orderExportItems: [],
    })

    const response = await GetAllOrderExport(params)
    set({orderExportModel: response})

    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        set({orderExportItems: response.data?.items})
      }
    }

    set({
      orderExportLoading: false,
    })
  },

  orderDownload: async (id: string) => {},
}))
