import {create} from 'zustand'
import {SettlementData, SettlementResultInterface} from '../interfaces/settlement-result.interface'
import {
  GetAllSettlement,
  GetAllSettlementExport,
  GetAllSettlementParam,
  GetGenerateSettlementExport,
  GetTotalSettlement,
} from '../providers/settlement.provider'
import {GeneralResponse} from '../../../../helpers/general-response'
import {toast} from 'react-toastify'
import {
  StatementExportItem,
  StatementExportResult,
} from '../interfaces/settlement-export-result.interface'

interface SettlementState {
  settlementLoading: boolean
  settlements: SettlementData[]
  settlementModel: SettlementResultInterface
  getSettlements: (params: GetAllSettlementParam) => void

  totalSettlementLoading: boolean
  totalSettlements: number
  getTotalSettlement: (params: GetAllSettlementParam) => void

  generateExportLoading: boolean
  generateExportResult: GeneralResponse
  getGenerateExport: (params: GetAllSettlementParam) => void

  settlementExportItems: StatementExportItem[]
  settlementExportModel: StatementExportResult
  settlementExportLoading: boolean
  getSettlementExport: (params: GetAllSettlementParam) => void

  settlementDownload: (id: string) => void
}

export const useSettlementStore = create<SettlementState>((set, get) => ({
  settlementLoading: false,
  settlements: [],
  settlementModel: null,
  getSettlements: async (params: GetAllSettlementParam) => {
    set({settlementLoading: true, settlements: []})
    const response = await GetAllSettlement(params)
    set({settlementModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        set({settlements: response.data.items})
      }
    }
    set({settlementLoading: false})
  },

  totalSettlementLoading: false,
  totalSettlements: 0,
  getTotalSettlement: async (params: GetAllSettlementParam) => {
    set({totalSettlementLoading: true, totalSettlements: 0})
    const response = await GetTotalSettlement(params)
    if (response.status) {
      set({totalSettlements: response.data})
    }
    set({totalSettlementLoading: false})
  },

  generateExportLoading: false,
  generateExportResult: undefined,
  getGenerateExport: async (params: GetAllSettlementParam) => {
    set({
      generateExportLoading: true,
      generateExportResult: undefined,
    })

    const response = await GetGenerateSettlementExport(params)
    set({generateExportResult: response})

    toast.success(response.message ?? 'Success')

    set({
      generateExportLoading: false,
    })
  },
  settlementExportItems: [],
  settlementExportModel: undefined,
  settlementExportLoading: false,
  getSettlementExport: async (params: GetAllSettlementParam) => {
    set({
      settlementExportLoading: true,
      settlementExportModel: null,
      settlementExportItems: [],
    })

    const response = await GetAllSettlementExport(params)
    set({settlementExportModel: response})

    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        set({settlementExportItems: response.data?.items})
      }
    }

    set({
      settlementExportLoading: false,
    })
  },

  settlementDownload: async (id: string) => {},
}))
