import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {lazy} from 'react'
import {SuspensedView} from '../../../../routing/PrivateRoutes'
import ProductCodeListWrapper from './product-code.screen'

const productCodeBreadcrumbs: Array<PageLink> = [
  {
    title: 'Product Codes',
    path: '/apps/product-code-management/product-codes',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProductCodePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='product-codes'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={productCodeBreadcrumbs}>Product code list</PageTitle>
              <ProductCodeListWrapper />
            </SuspensedView>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/product-code-management/product-codes' />} />
    </Routes>
  )
}

export default ProductCodePage
