import {lazy} from 'react'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {SuspensedView} from '../../../../routing/PrivateRoutes'
import StockTransactionSummaryWrapper from './stock-transaction-summary.screen'

const stockTransactionBreadcrumbs: Array<PageLink> = [
  {
    title: 'Stock Transaction',
    path: '/apps/stock-transactions-management/dashboards',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const StockTransactionPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='dashboards'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={stockTransactionBreadcrumbs}>Stock Transaction</PageTitle>
              <StockTransactionSummaryWrapper />
            </SuspensedView>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/stock-transactions-management/dashboards' />} />
    </Routes>
  )
}

export default StockTransactionPage
