import {create} from 'zustand'
import {
  MasterStockData,
  MasterStockResultInterface,
} from '../interfaces/master-stock-result.interface'
import {
  DeleteMasterStock,
  GetAllMasterStock,
  GetAllMasterStockParam,
  GetMasterStock,
  SubmitMasterStock,
  UpdateMasterStock,
} from '../providers/master-stock.provider'
import {MasterStockSingleData} from '../interfaces/master-stock-single-result.interface'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../helpers/form-action-interfaces'
import {generateFormLogic} from '../../../../helpers/generate-form-logic'
import {toast} from 'react-toastify'

interface MasterStockState {
  masterStockLoading: boolean
  masterStocks: MasterStockData[]
  masterStockModel: MasterStockResultInterface
  getMasterStocks: (params: GetAllMasterStockParam) => void
  masterStockOneLoading: boolean
  masterStock: MasterStockSingleData
  getMasterStockDetail: (id: string) => void
  onDelete: (id: string, params: GetAllMasterStockParam) => void
}

export const useMasterStockStore = create<MasterStockState>((set, get) => ({
  masterStockLoading: false,
  masterStockOneLoading: false,
  masterStocks: [],
  masterStock: undefined,
  masterStockModel: null,
  getMasterStocks: async (params: GetAllMasterStockParam) => {
    set({masterStockLoading: true, masterStocks: []})
    const response = await GetAllMasterStock(params)
    set({masterStockModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        set({masterStocks: response.data.items})
      }
    }
    set({masterStockLoading: false})
  },
  getMasterStockDetail: async (id: string) => {
    set({
      masterStockOneLoading: true,
      masterStock: null,
    })
    const response = await GetMasterStock(id)
    if (response.status === true) {
      set({masterStock: response.data})
    }
    set({
      masterStockOneLoading: false,
    })
  },
  onDelete: async (id: string, params: GetAllMasterStockParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({masterStockLoading: true})
      const response = await DeleteMasterStock(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getMasterStocks(params)
      set({masterStockLoading: false})
    }
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface MasterStockFormState {
  field?: any
  setField?: (item: MasterStockData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const useMasterStockFormStore = create<MasterStockFormState>((set, get) => ({
  field: undefined,
  setField: (item: MasterStockData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'product_code_id',
        title: 'Nama Produk',
        placeholder: 'Nama Produk...',
        type: 'select-with-text',
        name: 'product_code_id',
        value: {
          label: get().field?.product_name,
          value: +get().field?.product_code_id,
        },
        disabled:
          formParam.action === FormAction.VIEW || formParam.action === FormAction.UPDATE
            ? true
            : false,
        required: true,
        selectData: get().forms?.find((form) => form.name === 'product_code_id')?.selectData ?? [],
      },
      {
        id: 'box_code',
        title: 'Kode Box',
        placeholder: 'Kode Box...',
        type: 'text',
        name: 'box_code',
        value: get().field?.box_code ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'qty_per_box',
        title: 'Quantity Per Box',
        placeholder: 'Quantity Per Box...',
        type: 'number',
        name: 'qty_per_box',
        value: get().field?.qty_per_box ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }
    console.log(form)
    let res: MasterStockResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitMasterStock(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateMasterStock(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
