import {AxiosError} from 'axios'
import {Api} from '../../../../api/api'
import {TrxStocksResultInterface} from '../interfaces/trx-stocks-result.interface'
import {TrxStocksSingleResultInterface} from '../interfaces/trx-stocks-single-result.interface'
import {GeneralResponse} from '../../../../helpers/general-response'

export interface GetAllTrxStocksParam {
  page?: number
  size?: number
  filters?: string
  sort?: string
}

export const GetAllTrxStocks = async (
  params: GetAllTrxStocksParam
): Promise<TrxStocksResultInterface> => {
  try {
    const response = await Api.get('/trx-stocks', {params: params})

    return response.data as TrxStocksResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TrxStocksResultInterface = a.response.data
    return response
  }
}

export const GetTrxStock = async (id: string): Promise<TrxStocksSingleResultInterface> => {
  try {
    const response = await Api.get('/trx-stocks/' + id)
    return response.data as TrxStocksSingleResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TrxStocksSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteTrxStock = async (id: string): Promise<GeneralResponse> => {
  try {
    const response = await Api.delete('/trx-stocks/' + id)
    return response.data as GeneralResponse
  } catch (e) {
    const a = e as AxiosError
    const response: GeneralResponse = a.response?.data
    return response
  }
}

export const SubmitTrxStocksIn = async (form: any): Promise<TrxStocksResultInterface> => {
  try {
    const response = await Api.post('/trx-stocks', form)
    return response.data as TrxStocksResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TrxStocksResultInterface = a.response?.data
    return response
  }
}

export const UpdateTrxStocksIn = async (
  id: string,
  form: any
): Promise<TrxStocksResultInterface> => {
  try {
    const response = await Api.post(`/trx-stocks/${id}`, form)
    return response.data as TrxStocksResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TrxStocksResultInterface = a.response?.data
    return response
  }
}

export const SubmitTrxStocksOut = async (form: any): Promise<TrxStocksResultInterface> => {
  try {
    const response = await Api.post('/trx-stocks', form)
    return response.data as TrxStocksResultInterface
  } catch (e) {
    const a = e as AxiosError
    const response: TrxStocksResultInterface = a.response?.data
    return response
  }
}
