import {AxiosError} from 'axios'
import {RoleResultInterface} from '../interfaces/roles-result.interface'
import {Api} from '../../../../api/api'
import {RoleSingleInterface} from '../interfaces/roles-single.interface'
import {GeneralResponse} from '../../../../helpers/general-response'

export interface GetAllRoleParam {
  page?: number
  size?: number
  filters?: string
  sort?: string
}

export const GetAllRole = async (params: GetAllRoleParam): Promise<RoleResultInterface> => {
  try {
    let response = await Api.get('/roles', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: RoleResultInterface = a.response?.data
    return response
  }
}

export const ShowRole = async (id: string): Promise<RoleSingleInterface> => {
  try {
    let response = await Api.get('/roles/' + id)
    return response.data as RoleSingleInterface
  } catch (e) {
    let a = e as AxiosError
    let response: RoleSingleInterface = a.response?.data
    return response
  }
}

export const SubmitRole = async (form: any): Promise<RoleSingleInterface> => {
  try {
    let response = await Api.post('/roles', form)
    return response.data as RoleSingleInterface
  } catch (e) {
    let a = e as AxiosError
    let response: RoleSingleInterface = a.response?.data
    return response
  }
}

export const UpdateRole = async (id: string, form: any): Promise<RoleSingleInterface> => {
  try {
    let response = await Api.put('/roles/' + id, form)
    return response.data as RoleSingleInterface
  } catch (e) {
    let a = e as AxiosError
    let response: RoleSingleInterface = a.response?.data
    return response
  }
}

export const DeleteRole = async (id: string): Promise<GeneralResponse> => {
  try {
    let response = await Api.delete('/roles/' + id)
    return response.data as GeneralResponse
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralResponse = a.response?.data
    return response
  }
}
