export const generateStatus = (value: string) => {
  switch (value) {
    case 'AWAITING_SHIPMENT':
      // return 'Perlu dikirim'
      return 'Menunggu diproses'
    case 'CANCELLED':
      return 'Dibatalkan'
    case 'UNPAID':
      return 'Belum dibayar'
    case 'IN_TRANSIT':
      return 'Dalam perjalanan ke alamat tujuan'
    case 'COMPLETED':
      return 'Selesai'
    case 'AWAITING_COLLECTION':
      return 'Menunggu Dipickup'
    case 'DELIVERED':
      return 'Terkirim'
    case 'CANCEL':
      return 'Sedang Dibatalkan'
    case 'IN_CANCEL':
      return 'Sedang Dibatalkan'
    case 'READY_TO_SHIP':
      return 'Menunggu diproses'
    case 'PROCESSED':
      return 'Menunggu Dipickup'
    case 'SHIPPED':
      return 'Dalam perjalanan ke alamat tujuan'
    case 'INVOICE_PENDING':
      return 'Invoice Pending'
  }
}

export const generateType = (value: string) => {
  switch (value) {
    case 'ORDER':
      return 'Order'
    case 'PLATFORM_REIMBURSEMENT':
      return 'Penggantian biaya platform'
    case 'LOGISTICS_REIMBURSEMENT':
      return 'Penggantian logistik'
    case 'DEDUCTIONS_INCURRED_BY_SELLER':
      return 'Potongan yang ditanggung oleh penjual'
  }
}

export const generateStatusFinance = (value: string) => {
  switch (value) {
    case 'WITHDRAW':
      return 'Penarikan dana'
    case 'SETTLE':
      return 'Penyelesaian pembayaran pesanan'
    case 'WITHDRAWAL_CREATED':
      return 'Penarikan dana'
    case 'ESCROW_VERIFIED_ADD':
      return 'Penyelesaian pembayaran pesanan'
    default:
      return ''
  }
}

export const statusLabel = (value: string) => {
  switch (value) {
    case 'UNPRINTED':
      return 'Belum dicetak'
    case 'PRINTED':
      return 'Sudah dicetak'
    default:
      return ''
  }
}
