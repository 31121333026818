import {create} from 'zustand'
import {GeneralResponse} from '../../../../helpers/general-response'
import {
  DeleteTrxResiReport,
  GenerateTrxResiReportExcel,
  GetAllTrxResiReports,
  GetAllTrxResiReportsParam,
} from '../providers/trx-resi-report.provider'
import {
  MasterStockReportItem,
  MasterStockReportResultInterface,
} from '../interfaces/master-stock-report-result.interface'
import {toast} from 'react-toastify'

interface TrxResiReportState {
  getGenerateReportExcel: (params: GetAllTrxResiReportsParam) => Promise<void>
  generateExportLoading: boolean
  generateExportResult: GeneralResponse

  trxResiReportItems: MasterStockReportItem[]
  trxResiReportModel: MasterStockReportResultInterface
  trxResiReportLoading: boolean
  getTrxResiReports: (params: GetAllTrxResiReportsParam) => Promise<void>
  onDelete: (id: string, params: GetAllTrxResiReportsParam) => void
}

export const useTrxResiReportStore = create<TrxResiReportState>((set, get) => ({
  generateExportLoading: false,
  generateExportResult: undefined,
  getGenerateReportExcel: async (params: GetAllTrxResiReportsParam) => {
    set({
      generateExportLoading: true,
      generateExportResult: undefined,
    })

    const response = await GenerateTrxResiReportExcel(params)
    set({generateExportResult: response})

    toast.success(response.message ?? 'Success')

    set({
      generateExportLoading: false,
    })
  },

  trxResiReportItems: [],
  trxResiReportModel: undefined,
  trxResiReportLoading: false,
  getTrxResiReports: async (params: GetAllTrxResiReportsParam) => {
    set({
      trxResiReportLoading: true,
      trxResiReportModel: null,
      trxResiReportItems: [],
    })

    const response = await GetAllTrxResiReports(params)
    set({trxResiReportModel: response})

    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        set({trxResiReportItems: response.data?.items})
      }
    }

    set({
      trxResiReportLoading: false,
    })
  },
  onDelete: async (id: string, params: GetAllTrxResiReportsParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({trxResiReportLoading: true})
      const response = await DeleteTrxResiReport(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getTrxResiReports(params)
      set({trxResiReportLoading: false})
    }
  },
}))
