import {useEffect, useState} from 'react'
import {useTransactionStore} from '../states/transaction.state'
import {KTCard} from '../../../../../_metronic/helpers'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import moment from 'moment'
import {generateCurrencyFormat} from '../../../../helpers/generate-number-format'
import ReactPaginate from 'react-paginate'
import {generateStatusFinance} from '../../../../helpers/generate-status'
import {Link, useLocation} from 'react-router-dom'
import {useTransactionShopeeStore} from '../states/transaction-shopee.state'

const TransactionShopeeScreen = () => {
  const [activeTab, setActiveTab] = useState<number>(1)
  const [filters, setFilters] = useState<string>()
  const [transactionType, setTransactionType] = useState<string>()
  const [label, setLabel] = useState<string>('Semua')

  const {
    getTransactions,
    transactionItems,
    transactionLoading,
    transactionModel,
    transactionWithdraw,
    getTransactionWithdraw,
  } = useTransactionShopeeStore()

  useEffect(() => {
    getTransactions({
      page: 0,
      sort: '-create_time',
    })
    getTransactionWithdraw()
  }, [])

  useEffect(() => {
    getTransactions({
      page: 0,
      sort: '-create_time',
      transaction_type: transactionType,
    })
  }, [transactionType])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getTransactions({
      page: selectedPage,
      sort: '-create_time',
      transaction_type: transactionType,
    })
  }

  return (
    <>
      <div className='row g-5 g-xl-4 mb-2'>
        <div className='col-sm-6'>
          <KTCard>
            <div className='card-header border-0 pt-6'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Nominal yang Bisa ditarik</span>
              </h3>
            </div>
            <div className='card-body py-3'>
              <h1>{generateCurrencyFormat(transactionWithdraw)}</h1>
            </div>
          </KTCard>
        </div>
      </div>

      <div className='d-flex overflow-auto h-55px mb-5'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item'>
            <div
              className={`nav-link text-active-primary me-6 ` + (activeTab === 1 ? 'active' : '')}
              onClick={(e) => {
                setActiveTab(1)
                setTransactionType(undefined)
                setLabel('Semua')
              }}
            >
              Semua
            </div>
          </li>
          <li className='nav-item'>
            <div
              className={`nav-link text-active-primary me-6 ` + (activeTab === 2 ? 'active' : '')}
              onClick={(e) => {
                setActiveTab(2)
                setTransactionType('WITHDRAWAL_CREATED')
                setLabel('Penarikan Dana')
              }}
            >
              Penarikan Dana
            </div>
          </li>
          <li className='nav-item'>
            <div
              className={`nav-link text-active-primary me-6 ` + (activeTab === 3 ? 'active' : '')}
              onClick={(e) => {
                setActiveTab(3)
                setTransactionType('ESCROW_VERIFIED_ADD')
                setLabel('Penghasilan')
              }}
            >
              Penghasilan
            </div>
          </li>
        </ul>
      </div>

      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{label}</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>User List</span> */}
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {transactionLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Tanggal</th>
                  <th>Jenis</th>
                  <th>Jumlah</th>
                  <th>Status</th>
                  {/* <th className='text-end'>Action</th> */}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {transactionItems?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex flex-column'>
                            <span
                              style={{
                                fontSize: 10,
                              }}
                            >
                              {moment(item?.create_time * 1000).format('DD/MM/YYYY')}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span>{generateStatusFinance(item?.transaction_type ?? '')}</span>
                      </td>
                      <td>
                        <span>{generateCurrencyFormat(item?.amount ?? 0)}</span>
                      </td>
                      <td>
                        <span>{item?.status}</span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='card-footer'>
          <ReactPaginate
            previousLabel={'«'}
            nextLabel={'»'}
            breakLabel={'...'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            pageCount={transactionModel?.data?.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={'pagination pagination-sm m-0 float-right'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </div>
      </KTCard>
    </>
  )
}

const TransactionShopeeScreenWrapper = () => {
  return (
    <>
      <TransactionShopeeScreen />
    </>
  )
}

export default TransactionShopeeScreenWrapper
