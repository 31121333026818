import {useEffect, useRef, useState} from 'react'
import {useMasterStockFormStore, useMasterStockStore} from '../states/master-stock.state'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import ReactPaginate from 'react-paginate'
import {KTCard, KTIcon, KTSVG} from '../../../../../_metronic/helpers'
import moment from 'moment'
import MasterStockDetailModalComponent from '../components/master-stock-detail-modal.component'
import MasterStockModalComponent from '../components/master-stock-modal.component'
import {FormAction} from '../../../../helpers/form-action-interfaces'
import {generateNumberFormat} from '../../../../helpers/generate-number-format'
import {MasterStockData} from '../interfaces/master-stock-result.interface'
import Flatpickr from 'react-flatpickr'
import {useMasterStockReportStore} from '../states/master-stock-report.state'
import {MdDeleteForever} from 'react-icons/md'

const MasterStockComponent = () => {
  const {getMasterStocks, masterStockLoading, masterStocks, masterStockModel, onDelete} =
    useMasterStockStore()
  const {setField, field, generateForms, submitDone, formLoading, reset} = useMasterStockFormStore()
  const [startTime, setStartTime] = useState<Date>(new Date(new Date().setHours(23, 59, 59, 0)))
  const flatpickrRef = useRef<Flatpickr>(null)
  const {getGenerateReportExcel, getGenerateReportPdf, generateExportLoading} =
    useMasterStockReportStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(0)

  const filterData = (e: any) => {
    setCurrentPage(0)
    getMasterStocks({
      page: 0,
      size: 100,
      time_query: Date.parse(startTime.toString()) / 1000,
      sort: 'product_name',
    })
  }

  useEffect(() => {
    getMasterStocks({
      page: 0,
      size: 100,
      sort: 'product_name',
    })
  }, [])

  useEffect(() => {
    if (submitDone === true && formLoading === false) {
      getMasterStocks({page: 0, size: 100, sort: 'product_name'})
      reset()
    }
  }, [submitDone, formLoading])

  const handlePageClick = (e: any) => {
    setCurrentPage(e.selected)
    const selectedPage = e.selected
    getMasterStocks({
      page: selectedPage,
      size: 100,
      time_query: Date.parse(startTime.toString()) / 1000,
      sort: 'product_name',
    })
  }

  const resetData = () => {
    setStartTime(new Date(new Date().setHours(23, 59, 59, 0)))
    setCurrentPage(0)
    getMasterStocks({
      page: 0,
      size: 100,
      time_query: Date.parse(startTime.toString()) / 1000,
      sort: 'product_name',
    })
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [detailId, setDetailId] = useState<string>('')

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openEditModal = (item: MasterStockData) => {
    setField(item)
    generateForms({
      title: 'Master Stock',
      subtitle: `Update Master Stock ${item.product_name}`,
      action: FormAction.UPDATE,
      data: item,
      id: item.id.toString(),
    })
  }

  return (
    <>
      <KTCard className='p-3 mb-5'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-4'>
              <label className={`form-label`}>Date</label>
              <Flatpickr
                ref={flatpickrRef}
                value={[startTime]}
                onChange={([date1]) => {
                  setStartTime(new Date(date1.setHours(23, 59, 59, 0)))
                }}
                options={{
                  mode: 'single',
                }}
                className='form-control'
                placeholder='Pick date'
              />
            </div>
          </div>
        </div>
        <div className='card-footer'>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={generateExportLoading}
              onClick={(e) => {
                e.preventDefault()
                getGenerateReportExcel({
                  time_query: Date.parse(startTime.toString()) / 1000,
                })
              }}
              className='btn btn-success btn-active-success-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Export Excel
            </button>
            <button
              type='button'
              disabled={generateExportLoading}
              onClick={(e) => {
                e.preventDefault()
                getGenerateReportPdf({
                  time_query: Date.parse(startTime.toString()) / 1000,
                })
              }}
              className='btn btn-danger btn-active-danger-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Export PDF
            </button>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
        </div>
      </KTCard>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Warehouse</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Master Stock</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                setField(null)
                generateForms({
                  title: 'Add New Product',
                  subtitle: 'Add New Product',
                  action: FormAction.CREATE,
                })
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Add New Product
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {masterStockLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Nama Produk</th>
                  <th>Qty/Box</th>
                  <th>Box</th>
                  <th>Non Box</th>
                  <th>Total Quantity</th>
                  <th className='text-end min-w-100px'>Action</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {masterStocks?.map((item, index) => {
                  const boxCount = Math.floor(item.current_stock / item.qty_per_box)
                  const nonBoxCount = item.current_stock - item.qty_per_box * boxCount
                  return (
                    <tr key={index}>
                      <td>{item.product_name}</td>
                      <td>{generateNumberFormat(item.qty_per_box)}</td>
                      <td>{isNaN(boxCount) ? 0 : generateNumberFormat(boxCount)}</td>
                      <td>{isNaN(nonBoxCount) ? 0 : generateNumberFormat(nonBoxCount)}</td>
                      <td>
                        <span className='text-black fw-bold'>{item.current_stock}</span>
                      </td>
                      <td className='text-end'>
                        <button
                          className='btn btn-warning ms-1'
                          onClick={(e) => {
                            e.preventDefault()
                            openEditModal(item)
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className='btn btn-danger ms-1'
                          onClick={(e) => {
                            e.preventDefault()
                            onDelete(item.id, {
                              page: 0,
                              size: 100,
                            })
                          }}
                        >
                          <MdDeleteForever size={18} />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={masterStockModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
      <MasterStockDetailModalComponent isOpen={isModalOpen} onClose={closeModal} id={detailId} />
      {field !== undefined && <MasterStockModalComponent />}
    </>
  )
}

const MasterStockWrapper = () => {
  return (
    <>
      <MasterStockComponent />
    </>
  )
}

export default MasterStockWrapper
