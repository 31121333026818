import {create} from 'zustand'
import {
  OrderShopeeItem,
  OrderShopeeResultInterface,
} from '../interfaces/order-shopee-result.interface'
import {
  CountTotalDataOrderShopee,
  GetAllOrderExport,
  GetAllOrderExportShopee,
  GetAllOrderParam,
  GetAllOrderShopee,
  GetGenerateOrderExportShopee,
} from '../providers/order.provider'
import {GeneralResponse} from '../../../../helpers/general-response'
import {
  StatementExportItem,
  StatementExportResult,
} from '../../settlements/interfaces/settlement-export-result.interface'
import {toast} from 'react-toastify'

interface OrderShopeeState {
  orderLoading: boolean
  orders: OrderShopeeItem[]
  orderModel: OrderShopeeResultInterface
  getOrders: (params: GetAllOrderParam) => void

  countOrderLoading: boolean
  countOrders: number
  getCountOrders: (params: GetAllOrderParam) => void

  filters: string
  setFilters: (filter: string) => void

  generateExportLoading: boolean
  generateExportResult: GeneralResponse
  getGenerateExport: (params: GetAllOrderParam) => void

  orderExportItems: StatementExportItem[]
  orderExportModel: StatementExportResult
  orderExportLoading: boolean
  getOrderExport: (params: GetAllOrderParam) => void

  orderDownload: (id: string) => void
}

export const useOrderShopeeStore = create<OrderShopeeState>((set, get) => ({
  orderLoading: true,
  orders: [],
  orderModel: null,
  getOrders: async (params: GetAllOrderParam) => {
    set({
      orderLoading: true,
      orders: [],
    })
    const response = await GetAllOrderShopee(params)
    set({orderModel: response})
    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        set({orders: response.data?.items})
      }
    }
    set({
      orderLoading: false,
    })
  },

  countOrderLoading: true,
  countOrders: 0,
  getCountOrders: async (params: GetAllOrderParam) => {
    set({countOrderLoading: true, countOrders: 0})
    const response = await CountTotalDataOrderShopee(params)
    if (response) {
      if (response.status) {
        set({countOrders: response.data})
      }
    }
    set({countOrderLoading: false})
  },

  filters: '',
  setFilters: (value: string) => {
    set({filters: value})
  },

  generateExportLoading: false,
  generateExportResult: undefined,
  getGenerateExport: async (params: GetAllOrderParam) => {
    set({
      generateExportLoading: true,
      generateExportResult: undefined,
    })

    const response = await GetGenerateOrderExportShopee(params)
    set({generateExportResult: response})

    toast.success(response.message ?? 'Success')

    set({
      generateExportLoading: false,
    })
  },

  orderExportItems: [],
  orderExportModel: undefined,
  orderExportLoading: false,
  getOrderExport: async (params: GetAllOrderParam) => {
    set({
      orderExportLoading: true,
      orderExportModel: null,
      orderExportItems: [],
    })

    const response = await GetAllOrderExportShopee(params)
    set({orderExportModel: response})

    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        set({orderExportItems: response.data?.items})
      }
    }

    set({
      orderExportLoading: false,
    })
  },

  orderDownload: async (id: string) => {},
}))
