import {create} from 'zustand'
import {ProductData, ProductResultInterface} from '../interfaces/product-result.interface'
import {
  DeleteProductCode,
  GetAllProduct,
  GetAllProductParam,
  GetProductCodes,
  ProductCodeFormProps,
  RefreshProduct,
  SubmitProductCode,
  UpdateProductCode,
} from '../providers/product.provider'
import {ProductCode, ProductCodesSingleResult} from '../interfaces/product-codes-result.interface'
import {toast} from 'react-toastify'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../helpers/form-action-interfaces'
import {generateFormLogic} from '../../../../helpers/generate-form-logic'
import {RoleSingleInterface} from '../../roles/interfaces/roles-single.interface'
import {SubmitRole, UpdateRole} from '../../roles/providers/roles.provider'

interface ProductState {
  productLoading: boolean
  productCodeLoading: boolean
  freeProductCodeLoading: boolean
  products: ProductData[]
  productCodes: ProductCode[]
  productCodeSelectDatas: SelectData[]
  freeProductCodes: ProductCode[]
  freeProductCodeSelectDatas: SelectData[]
  productModel: ProductResultInterface
  getProducts: (params: GetAllProductParam) => void
  getProductCodes: (params: GetAllProductParam) => void
  getFreeProductCodes: (params: GetAllProductParam) => void
  refreshProducts: (params: GetAllProductParam) => void
  onDelete: (id: number, params: GetAllProductParam) => void
}

export const useProductStore = create<ProductState>((set, get) => ({
  productLoading: false,
  productCodeLoading: false,
  freeProductCodeLoading: false,
  products: [],
  productCodes: [],
  productCodeSelectDatas: [],
  freeProductCodes: [],
  freeProductCodeSelectDatas: [],
  productModel: null,
  getProducts: async (params: GetAllProductParam) => {
    set({
      productLoading: true,
      products: [],
    })
    const response = await GetAllProduct(params)
    set({productModel: response})
    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        set({products: response.data?.items})
      }
    }
    set({
      productLoading: false,
    })
  },
  getProductCodes: async (params: GetAllProductParam) => {
    set({productCodeLoading: true, productCodes: []})
    const response = await GetProductCodes(params)
    if (response.status) {
      if (response.data?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data) {
          transformDatas.push({
            label: item.product_name,
            value: item.id,
          })
        }
        set({productCodes: response?.data, productCodeSelectDatas: transformDatas})
      }
    }
    set({productCodeLoading: false})
  },
  getFreeProductCodes: async (params: GetAllProductParam) => {
    set({freeProductCodeLoading: true, freeProductCodes: []})
    const response = await GetProductCodes(params)
    if (response.status) {
      if (response.data?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data) {
          transformDatas.push({
            label: item.product_name,
            value: item.id,
          })
        }
        set({freeProductCodes: response?.data, freeProductCodeSelectDatas: transformDatas})
      }
    }
    set({freeProductCodeLoading: false})
  },
  refreshProducts: async (params: GetAllProductParam) => {
    set({
      productLoading: true,
      products: [],
    })
    await RefreshProduct(params)
    get().getProducts(params)
  },
  onDelete: async (id: number, params: GetAllProductParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({productCodeLoading: true})
      const response = await DeleteProductCode(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getProductCodes(params)
      set({productCodeLoading: false})
    }
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface ProductCodeFormState {
  field?: ProductCode
  setField?: (item: ProductCode) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const useProductCodeFormStore = create<ProductCodeFormState>((set, get) => ({
  field: undefined,
  setField: (item: ProductCode) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'product_name',
        title: 'Product Name',
        placeholder: 'Product Name...',
        type: 'text',
        name: 'product_name',
        value: get().field?.product_name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'product_code',
        title: 'Product Code',
        placeholder: 'Product Code...',
        type: 'text',
        name: 'product_code',
        value: get().field?.product_code ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'marketplace',
        title: 'Marketplace',
        placeholder: 'Marketplace...',
        type: 'select-number',
        name: 'marketplace',
        value: get().field?.marketplace ?? '',
        selectData: [
          {value: 1, label: 'TikTok'},
          {value: 2, label: 'Shopee'},
        ],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'is_stock',
        title: 'Is Stock?',
        placeholder: 'Is Stock?...',
        type: 'checkbox',
        name: 'is_stock',
        value: get().field?.is_stock ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: ProductCodesSingleResult = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitProductCode(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateProductCode(+get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
