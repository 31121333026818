/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon, userAllowed} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()

  const {currentUser} = useAuth()

  return (
    <>
      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/dashboard'
          icon='element-11'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      ) : null}

      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/product-management/products'
          icon='abstract-28'
          title='Product'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/product-code-management/product-codes'
          icon='abstract-28'
          title='Product Code'
          fontIcon='bi-layers'
        />
      ) : null}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Tiktok</span>
        </div>
      </div>

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/order-management/orders'
          icon='abstract-28'
          title='Order'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/order-management/orders-export'
          icon='abstract-28'
          title='Order Export'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin', 'user-resi'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/shipping-docs-management/shipping-doc'
          icon='abstract-28'
          title='Generate Resi'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin', 'user-resi'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/shipping-docs-management/shipping-doc-export'
          icon='abstract-28'
          title='Cetak Resi'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/transactions-management/transaction'
          icon='abstract-28'
          title='Penarikan Dana'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/settlements-management/settlement'
          icon='abstract-28'
          title='Settlement'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/settlements-management/settlement-export'
          icon='abstract-28'
          title='Settlement Export'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Shopee</span>
          </div>
        </div>
      ) : null}

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/order-management-shopee/orders-shopee'
          icon='abstract-28'
          title='Order Shopee'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/order-management-shopee/orders-export-shopee'
          icon='abstract-28'
          title='Order Export'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin', 'user-resi'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/shipping-docs-management-shopee/shipping-doc-shopee'
          icon='abstract-28'
          title='Generate Resi'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin', 'user-resi'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/shipping-docs-management-shopee/shipping-doc-export-shopee'
          icon='abstract-28'
          title='Cetak Resi'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/transactions-management-shopee/transaction-shopee'
          icon='abstract-28'
          title='Penarikan Dana'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/settlements-management-shopee/settlement-shopee'
          icon='abstract-28'
          title='Settlement'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/settlements-management-shopee/settlement-shopee-export'
          icon='abstract-28'
          title='Settlement Export'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin', 'user-warehouse'], currentUser?.data?.role?.slug) ? (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Warehouse</span>
          </div>
        </div>
      ) : null}

      {userAllowed(['superadmin', 'user-warehouse'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/warehouse-management/vendors'
          icon='abstract-28'
          title='Vendor'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin', 'user-warehouse', 'user-resi'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/warehouse-management/master-stock'
          icon='abstract-28'
          title='Master Stock'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin', 'user-warehouse'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/warehouse-management/barang-keluar'
          icon='abstract-28'
          title='Barang Keluar'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin', 'user-warehouse'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/warehouse-management/barang-masuk'
          icon='abstract-28'
          title='Barang Masuk'
          fontIcon='bi-layers'
        />
      ) : null}

      {/* {currentUser?.data?.role?.slug === 'user-warehouse' ||
      currentUser?.data?.role?.slug === 'superadmin' ? (
        <SidebarMenuItem
          to='/apps/warehouse-management/kardus-barang'
          icon='abstract-28'
          title='Kardus Barang'
          fontIcon='bi-layers'
        />
      ) : null} */}

      {userAllowed(['superadmin', 'user-warehouse'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/warehouse-management/product-bundle'
          icon='abstract-28'
          title='Product Bundle'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin', 'user-warehouse'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/warehouse-management/report-stock-in-out'
          icon='abstract-28'
          title='Report Stock In/Out'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin', 'user-warehouse'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/warehouse-management/report-master-stock'
          icon='abstract-28'
          title='Report Master Stock'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin', 'user-warehouse', 'user-resi'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/warehouse-management/trx-resi'
          icon='abstract-28'
          title='Resi'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin', 'user-warehouse', 'user-resi'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/warehouse-management/trx-resi-report'
          icon='abstract-28'
          title='Resi Report'
          fontIcon='bi-layers'
        />
      ) : null}

      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>User & Roles</span>
          </div>
        </div>
      ) : null}

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/role-management/roles'
          icon='abstract-28'
          title='Roles'
          fontIcon='bi-layers'
        />
      ) : null}

      {userAllowed(['superadmin'], currentUser?.data?.role?.slug) ? (
        <SidebarMenuItem
          to='/apps/user-management/users'
          icon='abstract-28'
          title='User management'
          fontIcon='bi-layers'
        />
      ) : null}
    </>
  )
}

export {SidebarMenuMain}
