import {create} from 'zustand'
import {GeneralResponse} from '../../../../helpers/general-response'
import {
  DeleteTrxStockReport,
  GenerateTrxStockReportExcel,
  GenerateTrxStockReportPdf,
  GetAllTrxStockReports,
  GetAllTrxStockReportsParam,
} from '../providers/trx-stock-report.provider'
import {toast} from 'react-toastify'
import {
  TrxStockReportItem,
  TrxStockReportResultInterface,
} from '../interfaces/trx-stock-report-result.interface'

interface TrxStockReportState {
  getGenerateReportExcel: (params: GetAllTrxStockReportsParam) => Promise<void>
  getGenerateReportPdf: (params: GetAllTrxStockReportsParam) => Promise<void>
  generateExportLoading: boolean
  generateExportResult: GeneralResponse

  trxStockReportItems: TrxStockReportItem[]
  trxStockReportModel: TrxStockReportResultInterface
  trxStockReportLoading: boolean
  getTrxStockReports: (params: GetAllTrxStockReportsParam) => Promise<void>
  onDelete: (id: string, params: GetAllTrxStockReportsParam) => void
}

export const useTrxStockReportStore = create<TrxStockReportState>((set, get) => ({
  generateExportLoading: false,
  generateExportResult: undefined,
  getGenerateReportExcel: async (params: GetAllTrxStockReportsParam) => {
    set({
      generateExportLoading: true,
      generateExportResult: undefined,
    })

    const response = await GenerateTrxStockReportExcel(params)
    set({generateExportResult: response})

    toast.success(response.message ?? 'Success')

    set({
      generateExportLoading: false,
    })
  },
  getGenerateReportPdf: async (params: GetAllTrxStockReportsParam) => {
    set({
      generateExportLoading: true,
      generateExportResult: undefined,
    })

    const response = await GenerateTrxStockReportPdf(params)
    set({generateExportResult: response})

    toast.success(response.message ?? 'Success')

    set({
      generateExportLoading: false,
    })
  },

  trxStockReportItems: [],
  trxStockReportModel: undefined,
  trxStockReportLoading: false,
  getTrxStockReports: async (params: GetAllTrxStockReportsParam) => {
    set({
      trxStockReportLoading: true,
      trxStockReportModel: null,
      trxStockReportItems: [],
    })

    const response = await GetAllTrxStockReports(params)
    set({trxStockReportModel: response})

    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        set({trxStockReportItems: response.data?.items})
      }
    }

    set({
      trxStockReportLoading: false,
    })
  },
  onDelete: async (id: string, params: GetAllTrxStockReportsParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({trxStockReportLoading: true})
      const response = await DeleteTrxStockReport(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getTrxStockReports(params)
      set({trxStockReportLoading: false})
    }
  },
}))
