import {lazy} from 'react'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import {SuspensedView} from '../../../../routing/PrivateRoutes'
import OrderListWrapper from './order.screen'
import OrderDetailComponent from './order.detail'
import OrderExportScreenWrapper from './order-export.screen'
import OrderShopeeListWrapper from './order-shopee.screen'
import OrderExportShopeeScreenWrapper from './order-export-shopee.screen'

const orderBreadcrumbs: Array<PageLink> = [
  {
    title: 'Order',
    path: '/apps/order-management/orders',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OrderPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='orders'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={orderBreadcrumbs}>Orders list</PageTitle>
              <OrderListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='orders-shopee'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={orderBreadcrumbs}>Orders shopee list</PageTitle>
              <OrderShopeeListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='orders/:id'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={orderBreadcrumbs}>Order list</PageTitle>
              <OrderDetailComponent />
            </SuspensedView>
          }
        />
        <Route
          path='orders-export'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={orderBreadcrumbs}>Order export</PageTitle>
              <OrderExportScreenWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='orders-export-shopee'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={orderBreadcrumbs}>Order export</PageTitle>
              <OrderExportShopeeScreenWrapper />
            </SuspensedView>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/order-management/orders' />} />
    </Routes>
  )
}

export default OrderPage
